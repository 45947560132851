import React, { useMemo } from "react";
import overlaySVG from "assets/images/canvas/canvasInvisible.svg";
import { INVISIBLE_OPACITY } from "legacy/constants/DefaultTheme";
import {
  getChildrenIds,
  isWidgetOrChildSelected,
} from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { styleAsClass } from "styles/styleAsClass";

const VizContainerClass = styleAsClass`
  &[data-visible="false"] {
    opacity: ${String(INVISIBLE_OPACITY)};
  }
  height: 100%;
  width: 100%;
  position: relative;


  .overlay {
    position: absolute;
    pointer-events: none;
    background: url(${overlaySVG});
    z-index: 10;
    height: 100%;
    width: 100%;
  }
`;

const VisibilityContainer: React.FC<{
  isVisible: boolean;
  widgetId: string;
  children: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}> = ({ isVisible, widgetId, children, style }) => {
  const isSelected = useAppSelector((state) =>
    isWidgetOrChildSelected(state, widgetId),
  );
  const childWidgetIds = useAppSelector((state) =>
    getChildrenIds(state, widgetId),
  );
  const isDragging = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.isDragging,
  );

  const currentDropTarget = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.currentDropTarget,
  );

  const isChildDragTarget = useMemo(() => {
    return currentDropTarget
      ? childWidgetIds.includes(currentDropTarget)
      : false;
  }, [childWidgetIds, currentDropTarget]);

  // We want to hide the overlay when:
  // 1. widget or its children are selected
  // 2. we are dragging over the widget or its children
  const hideOverlay =
    isVisible ||
    (isSelected && !isDragging) ||
    (isDragging && (widgetId === currentDropTarget || isChildDragTarget));

  return (
    <div data-visible={isVisible} className={VizContainerClass} style={style}>
      {!hideOverlay ? <div className="overlay" /> : null}
      {children}
    </div>
  );
};

export default VisibilityContainer;
