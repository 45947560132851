import {
  createContext,
  useCallback,
  useContext,
  useSyncExternalStore,
} from "react";

export class TableManager {
  focusedCellIndices: boolean[][] = [];

  originCell: { rowIndex: number; columnId: string } | null = null;

  private focusedCellIndicesChangeListeners: (() => void)[] = [];

  private originCellListeners = new Map<string, Set<() => void>>();
  private focusedCellListeners = new Map<string, Set<() => void>>();

  setFocusedCellIndices(focusedCellIndices: boolean[][]) {
    this.focusedCellIndices = focusedCellIndices;
    this.focusedCellIndicesChangeListeners.forEach((l) => l());
  }

  changeCellFocus(row: number, column: number, focus: boolean) {
    if (!this.focusedCellIndices[row]) {
      this.focusedCellIndices[row] = [];
    }
    this.focusedCellIndices[row][column] = focus;

    const key = `${row},${column}`;
    this.focusedCellListeners.get(key)?.forEach((l) => l());
  }

  setOriginCell(rowIndex: number, columnId: string) {
    if (
      this.originCell?.rowIndex === rowIndex &&
      this.originCell?.columnId === columnId
    ) {
      return;
    }

    const oldOriginCell = this.originCell;
    this.originCell = { rowIndex, columnId };

    if (oldOriginCell) {
      const oldKey = this.getRowColumnKey(
        oldOriginCell.rowIndex,
        oldOriginCell.columnId,
      );

      this.originCellListeners.get(oldKey)?.forEach((l) => l());
    }

    const newKey = this.getRowColumnKey(rowIndex, columnId);
    this.originCellListeners.get(newKey)?.forEach((l) => l());
  }

  clearOriginCell() {
    if (this.originCell) {
      const key = this.getRowColumnKey(
        this.originCell.rowIndex,
        this.originCell.columnId,
      );
      const listeners = this.originCellListeners.get(key);
      if (listeners) {
        listeners.forEach((l) => l());
      }
    }
    this.originCell = null;
  }

  clearFocusedCells(clearOrigin?: boolean) {
    if (clearOrigin) {
      this.clearOriginCell();
    }

    const currentFocusedCells = this.focusedCellIndices;
    this.focusedCellIndices = [];

    // there is a semi-optimization here, where we only call the listeners for the cells that are were focused
    // we probably could optimize further by keeping a lookup table of which cells were focused before
    for (const [key, listeners] of this.focusedCellListeners.entries()) {
      const [row, column] = key.split(",").map(Number);
      if (currentFocusedCells[row]?.[column]) {
        listeners.forEach((l) => l());
      }
    }
  }

  // MARK: Event listeners
  onFocusedCellIndicesChange = (listener: () => void) => {
    this.focusedCellIndicesChangeListeners.push(listener);
    return () => {
      this.focusedCellIndicesChangeListeners =
        this.focusedCellIndicesChangeListeners.filter((l) => l !== listener);
    };
  };

  onFocusedCellChange = (row: number, column: number, listener: () => void) => {
    const key = `${row},${column}`;
    if (!this.focusedCellListeners.has(key)) {
      this.focusedCellListeners.set(key, new Set());
    }
    this.focusedCellListeners.get(key)!.add(listener);

    return () => {
      const listeners = this.focusedCellListeners.get(key);
      if (!listeners) {
        return;
      }

      listeners.delete(listener);
      if (listeners.size === 0) {
        this.focusedCellListeners.delete(key);
      }
    };
  };

  onOriginCellChange = (
    rowIndex: number,
    columnId: string,
    listener: () => void,
  ) => {
    const key = `${rowIndex},${columnId}`;
    if (!this.originCellListeners.has(key)) {
      this.originCellListeners.set(key, new Set());
    }
    this.originCellListeners.get(key)!.add(listener);

    return () => {
      const listeners = this.originCellListeners.get(key);
      if (!listeners) {
        return;
      }

      listeners.delete(listener);
      if (listeners.size === 0) {
        this.originCellListeners.delete(key);
      }
    };
  };

  private getRowColumnKey(rowIndex: number, columnId: string) {
    return `${rowIndex},${columnId}`;
  }
}

export const TableManagerContext = createContext(new TableManager());
export function useTableManager() {
  return useContext(TableManagerContext);
}

export function useIsOriginCell(rowIndex: number, columnId: string) {
  const manager = useTableManager();

  const handler = useCallback(
    (cb: () => void) => {
      return manager.onOriginCellChange(rowIndex, columnId, cb);
    },
    [manager, rowIndex, columnId],
  );

  const getValue = useCallback(() => {
    return (
      manager.originCell?.rowIndex === rowIndex &&
      manager.originCell?.columnId === columnId
    );
  }, [manager, rowIndex, columnId]);

  return useSyncExternalStore(handler, getValue, getValue);
}

export function useIsCellFocused(row: number, column: number) {
  const manager = useTableManager();

  const handler = useCallback(
    (cb: () => void) => {
      return manager.onFocusedCellChange(row, column, cb);
    },
    [manager, row, column],
  );

  const getValue = useCallback(() => {
    return manager.focusedCellIndices[row]?.[column] ?? false;
  }, [manager, row, column]);

  return useSyncExternalStore(handler, getValue, getValue);
}
