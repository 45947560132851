import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import type { MapWidgetProps } from "./MapWidget";

const config: PropertyPaneCategoryConfig<MapWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "mapCenter",
      label: "Initial location",
      isJSConvertible: true,
      controlType: "LOCATION_SEARCH",
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "defaultMarkers",
      label: "Default markers",
      controlType: "INPUT_TEXT",
      inputType: "ARRAY",
      helpText: "Sets the default markers on the map",
      placeholderText: '[{ "lat": "val1", "long": "val2" }]',
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "zoomLevel",
      label: "Zoom level",
      controlType: "STEP",
      helpText: "Changes the default zoom of the map",
      stepType: "ZOOM_PERCENTAGE",
      isBindProperty: false,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection(),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig("onMarkerClick", ""),
  ]),
];

export default config;
