import { Tooltip } from "antd";
import React from "react";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { PrimaryButton } from "./Button";

const AddButton = ({
  onAdd,
  text,
  disabled,
  disabledText,
  dataTest,
}: {
  onAdd?: (e?: React.MouseEvent) => any;
  text: string;
  disabled?: boolean;
  disabledText?: string;
  dataTest?: string;
}) => {
  return (
    <Tooltip title={disabled ? disabledText : ""}>
      <PrimaryButton
        type="primary"
        onClick={onAdd}
        data-test={dataTest}
        disabled={disabled}
      >
        <PlusIcon
          height={16}
          width={16}
          style={{
            marginRight: "4px",
            marginLeft: "-2px",
          }}
        />{" "}
        {text}
      </PrimaryButton>
    </Tooltip>
  );
};

export default AddButton;
