// Scope Blueprint components to avoid any confusion with our own types
import {
  MenuDivider as BlueprintMenuDivider,
  MenuItem as BlueprintMenuItem,
  MenuItemProps as BlueprintMenuItemProps,
  Spinner,
} from "@blueprintjs/core";
import { ApplicationScope } from "@superblocksteam/shared/src/types/application";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import invisibleOverlayAffordanceSvg from "assets/images/canvas/canvasInvisible.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import { EventType } from "legacy/constants/ActionConstants";
import { APP_MODE } from "legacy/reducers/types";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { useAppSelector } from "store/helpers";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { NavigationEvent } from "utils/polyfills/interceptNavigation";
import { TypographyProps } from "../typographyHooks";
import {
  getFontSizeInPxFromTextStyle,
  getIconSizeBasedOnFontSize,
} from "../typographyUtils";
import {
  type ComponentMenuItem,
  MenuItemType,
  ComponentButtonMenuItem,
  ComponentLinkMenuItem,
} from "./types";
import { DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT } from "./utils";
import type { RunWidgetEventHandlers } from "../BaseWidget";

type PropType = {
  item: ComponentMenuItem;
  index: number;
  appMode: APP_MODE | undefined;
  textProps?: TypographyProps;
  handleMissingRouteParamsOnEditMode: () => void;
  runEventHandlers: (payload: RunWidgetEventHandlers) => void;
};

const ChevronRightIcon = styled(ChevronDown)`
  transform: rotate(-90deg);
  path {
    stroke: ${colors.GREY_300} !important; // overrides Blueprint's default color for label icon
  }
`;

const IconClassName = styleAsClass`
  display: flex;
  align-self: center;
`;

const MENU_ITEMS_POPOVER_PROPS: BlueprintMenuItemProps["popoverProps"] = {
  hoverCloseDelay: 400, // avoids closing the menu when hovering over to submenus
  modifiers: {
    offset: {
      enabled: true,
      options: {
        offset: [0, 12], // leaves space between menu and submenus
      },
    },
  },
};

const MenuItemClassName = styleAsClass`
  &[data-edit-mode-is-visible="false"] {
    opacity: 0.4;

    background-repeat: repeat;
    background: url(${invisibleOverlayAffordanceSvg});
  }
  &[aria-disabled="true"] {
    opacity: 0.4;
  }
`;

export const MenuItem = ({
  item,
  appMode,
  textProps,
  handleMissingRouteParamsOnEditMode,
  runEventHandlers,
}: PropType) => {
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);

  if (item.type === MenuItemType.Divider) {
    return <BlueprintMenuDivider />;
  }

  if (!item.isVisible && appMode !== APP_MODE.EDIT) {
    return null;
  }

  const showChildren =
    item.children &&
    (appMode === APP_MODE.EDIT ||
      item.children.some((child) => child.isVisible));

  const children =
    item.children && showChildren
      ? item.children.map((child, i) => (
          <MenuItem
            item={child}
            index={i}
            key={i}
            appMode={appMode}
            textProps={textProps}
            runEventHandlers={runEventHandlers}
            handleMissingRouteParamsOnEditMode={
              handleMissingRouteParamsOnEditMode
            }
          />
        ))
      : undefined;

  const typographyClassName =
    textProps?.textStyleVariant !== undefined
      ? getTextCssClassFromVariant(textProps.textStyleVariant)
      : "";

  const iconSize = textProps?.style
    ? getIconSizeBasedOnFontSize(
        getFontSizeInPxFromTextStyle({
          nestedProps: textProps.style,
          typographies: themeTypographies,
          textStyleVariant: textProps.textStyleVariant,
          defaultTextStyleVariant: DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT,
        }),
      )
    : 18;

  const itemPosition = item.iconPosition ?? "LEFT";
  const text = item.icon ? (
    <div
      className={`menu-item-text ${typographyClassName}`}
      style={textProps?.style}
    >
      {itemPosition === "LEFT" && (
        <DynamicSVG size={iconSize} iconName={item.icon} />
      )}
      <span>{item.label}</span>
      {itemPosition === "RIGHT" && (
        <DynamicSVG size={iconSize} iconName={item.icon} />
      )}
    </div>
  ) : (
    <span className={typographyClassName} style={textProps?.style}>
      {item.label}
    </span>
  );

  const menuItemProps: BlueprintMenuItemProps = {
    text,
    style: {
      color: textProps?.style?.color,
    },
    labelElement: children ? <ChevronRightIcon /> : undefined,
    labelClassName: IconClassName,
    popoverProps: MENU_ITEMS_POPOVER_PROPS,
    className: MenuItemClassName,
    disabled: item.isDisabled,
  };

  if (item.type === MenuItemType.Link) {
    return (
      <BlueprintMenuItemLink
        {...menuItemProps}
        data-test={`item-${item.label}`}
        item={item}
        appMode={appMode}
        handleMissingRouteParamsOnEditMode={handleMissingRouteParamsOnEditMode}
      >
        {children}
      </BlueprintMenuItemLink>
    );
  } else if (item.type === MenuItemType.Button) {
    return (
      <BlueprintMenuItemButton
        {...menuItemProps}
        data-test={`item-${item.label}`}
        item={item}
        runEventHandlers={runEventHandlers}
      >
        {children}
      </BlueprintMenuItemButton>
    );
  }

  throw new Error("Unsupported menu item type");
};

const BlueprintMenuItemButton = (
  props: BlueprintMenuItemProps & {
    item: ComponentButtonMenuItem;
    children: React.ReactNode;
    runEventHandlers: (payload: RunWidgetEventHandlers) => void;
  },
) => {
  const { item, children, runEventHandlers, ...menuItemProps } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleActionComplete = () => {
    return setIsLoading(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    // If the button is inside a grid or other container, this prevents
    // the container's onClick from firing
    e.stopPropagation();

    if (props.item.onClick && !isLoading) {
      setIsLoading(true);
      const callbackId = addNewPromise(handleActionComplete);

      props.runEventHandlers({
        steps: props.item.onClick,
        type: EventType.ON_CLICK,
        callbackId,
        currentScope: ApplicationScope.PAGE,
      });
    }
  };

  const text = isLoading ? (
    <div>
      <Spinner size={20} />
    </div>
  ) : (
    props.text
  );

  return (
    <BlueprintMenuItem
      {...menuItemProps}
      onClick={handleClick}
      text={text}
      data-edit-mode-is-visible={item.isVisible}
      disabled={item.isDisabled}
      shouldDismissPopover={true}
    >
      {props.children}
    </BlueprintMenuItem>
  );
};

const BlueprintMenuItemLink = (
  props: BlueprintMenuItemProps & {
    item: ComponentLinkMenuItem;
    children: React.ReactNode;
    appMode: APP_MODE | undefined;
    handleMissingRouteParamsOnEditMode: () => void;
  },
) => {
  const {
    item,
    children,
    appMode,
    handleMissingRouteParamsOnEditMode,
    ...rest
  } = props;
  const menuItemProps = { ...rest };

  const hasHref = "href" in item && item.href !== "";
  const hasMissingRouteParams =
    "hasMissingRouteParams" in item && item.hasMissingRouteParams;

  if (hasMissingRouteParams && appMode === APP_MODE.EDIT) {
    menuItemProps.onClick = handleMissingRouteParamsOnEditMode;
  } else if (hasHref && !hasMissingRouteParams) {
    menuItemProps.href = item.href;
    if (item.urlForInternalNavigation && !item.targetBlank) {
      menuItemProps.onClick = (e: React.MouseEvent<HTMLElement>) => {
        const forceNewTab = e.ctrlKey || e.metaKey;

        if (
          !forceNewTab &&
          item.urlForInternalNavigation !== undefined &&
          "navigation" in window
        ) {
          e.preventDefault();
          (window.navigation as any).dispatchEvent(
            new NavigationEvent(
              true,
              item.urlForInternalNavigation,
              undefined,
              false,
              false,
            ),
          );
        }
      };
    }

    if (item.targetBlank) {
      menuItemProps.target = "_blank";
    }
  }

  return (
    <BlueprintMenuItem
      {...menuItemProps}
      data-edit-mode-is-visible={item.isVisible}
      disabled={item.isDisabled}
      shouldDismissPopover={true}
    >
      {children}
    </BlueprintMenuItem>
  );
};
