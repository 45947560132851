import memoizeOne from "memoize-one";
import { useEffect, useMemo, useRef } from "react";
import { getSelectedWidgets } from "legacy/selectors/sagaSelectors";

// This function needs to be well-optimized as it is called potentially hundreds of time per second when the user is dragging
export const useWidgetAllowedDropTypeFn = (
  _allowedChildTypes: string[],
  _selectedWidgets: ReturnType<typeof getSelectedWidgets>,
) => {
  const allowedChildTypesRef = useRef<Set<string> | undefined>(undefined);
  const selectedWidgetsRef = useRef(_selectedWidgets);

  useEffect(() => {
    allowedChildTypesRef.current = new Set(_allowedChildTypes);
    selectedWidgetsRef.current = _selectedWidgets;
  }, [_allowedChildTypes, _selectedWidgets]);

  return useMemo(() => {
    const fn = memoizeOne(
      (
        monitorType: string,
        selectedWidgets: ReturnType<typeof getSelectedWidgets>,
        allowedChildTypes: undefined | Set<string>,
      ) => {
        if (!allowedChildTypes) {
          return false;
        }
        if (selectedWidgets.length <= 1) {
          return allowedChildTypes.has(monitorType);
        }
        return (
          allowedChildTypes.has(monitorType) &&
          selectedWidgets.every((widget) => {
            return allowedChildTypes.has(widget.type);
          })
        );
      },
    );
    return (monitorType: string) =>
      fn(monitorType, selectedWidgetsRef.current, allowedChildTypesRef.current);
  }, []);
};
