import { Dimension } from "@superblocksteam/shared";
import { useCallback, useRef, useState } from "react";
import {
  GridDefaults,
  WidgetWidthConstraintType,
} from "legacy/constants/WidgetConstants";
import type { WidgetProps } from "legacy/widgets/BaseWidget";
import type { StaticWidgetProps } from "legacy/widgets/shared";

const toPx = (
  dimension: Dimension<"gridUnit" | "fitContent" | "fillParent" | "px">,
) => {
  return Dimension.toPx(dimension, GridDefaults.DEFAULT_GRID_COLUMN_WIDTH)
    .value;
};

const ZERO_WIDTH = Dimension.gridUnit(0);

const getResizingConstraintWidthPx = (
  constraintType: WidgetWidthConstraintType,
  width: WidgetProps["width"],
  minWidth: WidgetProps["minWidth"],
  maxWidth: WidgetProps["maxWidth"],
): number => {
  let resizingWidth = toPx(width);

  if (constraintType === "minWidth") {
    resizingWidth = toPx(minWidth ?? Dimension.px(0));
  } else if (constraintType === "maxWidth") {
    resizingWidth = toPx(maxWidth ?? Dimension.px(0));
  }

  return resizingWidth;
};

const getMinWidthResizerWidth = (params: {
  useStateWidth: WidgetWidthConstraintType | false;
  resizingConstraintWidthPx: number;
  minWidth: WidgetProps["minWidth"];
}): Dimension<"px" | "gridUnit"> => {
  const { useStateWidth, resizingConstraintWidthPx, minWidth } = params;
  if (useStateWidth === "minWidth") {
    return Dimension.px(resizingConstraintWidthPx);
  } else if (useStateWidth === "maxWidth" && minWidth) {
    return Dimension.px(Math.min(resizingConstraintWidthPx, toPx(minWidth)));
  }

  return minWidth || ZERO_WIDTH;
};

const getMaxWidthResizerWidth = (params: {
  useStateWidth: WidgetWidthConstraintType | false;
  resizingConstraintWidthPx: number;
  maxWidth: WidgetProps["maxWidth"];
}): Dimension<"px" | "gridUnit"> => {
  const { useStateWidth, resizingConstraintWidthPx, maxWidth } = params;
  if (useStateWidth === "maxWidth") {
    return Dimension.px(resizingConstraintWidthPx);
  } else if (useStateWidth === "minWidth" && maxWidth) {
    return Dimension.px(Math.max(resizingConstraintWidthPx, toPx(maxWidth)));
  }

  return maxWidth || ZERO_WIDTH;
};

export const useWidgetWidthConstraintResizers = (
  widget: StaticWidgetProps<unknown>,
) => {
  const [isResizingConstraintWidth, setIsResizingConstraintWidthState] =
    useState(false);
  const [useStateWidth, setUseStateWidth] = useState<
    WidgetWidthConstraintType | false
  >(false);

  const [resizingConstraintWidthPx, setResizingConstraintWidthPx] =
    useState<number>(0);

  const resizerMaxWidth = getMaxWidthResizerWidth({
    useStateWidth,
    resizingConstraintWidthPx,
    maxWidth: widget.maxWidth,
  });

  const resizerMinWidth = getMinWidthResizerWidth({
    useStateWidth,
    resizingConstraintWidthPx,
    minWidth: widget.minWidth,
  });

  // TODO layouts: This override exists so that the section doesn't snap back to its original position
  // briefly before redux catches up. TODO the proper way to do this is to create a special saga for just this component that we can await on
  const postResizeTimeout = useRef<number | undefined>(undefined);

  const setIsResizingConstraintWidth = useCallback(
    (newValue: boolean, constraintType: WidgetWidthConstraintType) => {
      if (newValue === true) {
        postResizeTimeout.current && clearTimeout(postResizeTimeout.current);
        setUseStateWidth(constraintType);
        setResizingConstraintWidthPx(
          getResizingConstraintWidthPx(
            constraintType,
            widget.width,
            widget.minWidth,
            widget.maxWidth,
          ),
        );
      } else {
        postResizeTimeout.current = window.setTimeout(() => {
          setUseStateWidth(false);
        }, 150);
      }
      setIsResizingConstraintWidthState(newValue);
    },
    [widget.width, widget.minWidth, widget.maxWidth],
  );

  return {
    isResizingConstraintWidth,
    setIsResizingConstraintWidth,
    resizerMaxWidth,
    resizerMinWidth,
    resizingConstraintWidthPx,
    useStateWidth,
    setResizingConstraintWidthPx,
  };
};
