import { Classes, InputGroup } from "@blueprintjs/core";
import {
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared/src/types/application";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDebounce } from "hooks/ui";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DEFAULT_INPUT_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { styleAsClass } from "styles/styleAsClass";
import {
  createCssVariablesForBorderWidthAndColorWithHover,
  generateCssForBorderWidthAndColorWithHover,
} from "../base/generateBorderCss";
import { generateBorderRadiusStyleObject } from "../base/generateBorderStyle";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { generateInputBorderOnHoverColor } from "./InputUtils";

interface StyledInputGroupProps {
  $compact?: boolean;
  $noMargin?: boolean;
  $width?: string;
  $flexGrow?: string;
}

const StyledInputGroup = styled(InputGroup)<StyledInputGroupProps>`
  &.bp5-input-group {
    line-height: unset !important;
  }
  &&& input {
    height: 100%;
    padding-left: ${({ $compact }) => ($compact ? "28px" : "38px")};
  }

  &&& span {
    margin: ${({ $compact }) => ($compact ? "3px" : "9px")};
  }

  &&& svg {
    opacity: 1;
  }
  margin: ${({ $noMargin }) => ($noMargin ? "0" : "12px 16px")};
  width: ${({ $width }) => $width ?? "204px"};
  min-width: 150px;
  height: ${({ $compact }) => ($compact ? "24px" : "36px")};
  ${({ $flexGrow }) => $flexGrow && `flex-grow: ${$flexGrow}`};
`;

interface SearchComponentProps {
  onSearch: (value: any) => void;
  placeholder: string;
  value: string;
  compact?: boolean;
  noMargin?: boolean;
  width?: string;
  flexGrow?: string;
  dataTest?: string;
  textStyle?: TextStyleWithVariant;
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
  backgroundColor?: string;
}

const WithStyledBorders = styleAsClass`${generateCssForBorderWidthAndColorWithHover(
  {
    selectors: `&.${CLASS_NAMES.INPUT} > input.${Classes.INPUT}[type="search"]`,
  },
)}`;

const SearchComponent = (props: SearchComponentProps) => {
  const debouncedSearch = useDebounce(props.onSearch, 400);
  const [localValue, setLocalValue] = React.useState(props.value);

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const handleSearch = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      const search = event.target.value;
      setLocalValue(search);
      debouncedSearch?.(search);
    },
    [debouncedSearch],
  );

  const { border, borderRadius, backgroundColor } = props;

  const theme = useSelector(selectGeneratedTheme);

  const inputBaseStyle = useMemo(() => {
    const vars = createCssVariablesForBorderWidthAndColorWithHover({
      border,
      fallbackBorderColor: theme.colors.neutral100,
      defaultBorderWidth: theme.defaultBorder.borderWidth,
      borderColorOnHover: generateInputBorderOnHoverColor(
        border,
        theme.colors.primary500,
        theme.colors,
      ),
    });

    const borderRadiusStyle = generateBorderRadiusStyleObject({
      borderRadius,
    });
    return backgroundColor
      ? { backgroundColor, ...vars, ...borderRadiusStyle }
      : { ...vars, ...borderRadiusStyle };
  }, [
    backgroundColor,
    border,
    borderRadius,
    theme.colors,
    theme.defaultBorder.borderWidth,
  ]);

  const inputProps = useTypographyStyling({
    textStyle: props.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_TEXT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
    baseStyle: inputBaseStyle,
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps.textStyleVariant,
    type: "input",
    isLoading: false,
    isDisabled: false,
  });

  const classNames = `${inputClass ?? ""} ${
    CLASS_NAMES.INPUT
  } ${WithStyledBorders}`;

  return (
    <StyledInputGroup
      leftIcon="search"
      type="search"
      role="search"
      onChange={handleSearch}
      placeholder={props.placeholder}
      value={localValue ?? ""}
      $compact={props.compact}
      $noMargin={props.noMargin}
      $width={props.width}
      $flexGrow={props.flexGrow}
      data-test={props.dataTest}
      className={classNames}
      style={inputProps.style}
      autoFocus={false}
    />
  );
};

export default SearchComponent;
