import { get } from "lodash";
import { Flags } from "store/slices/featureFlags/models/Flags";
import { extractDynamicSegments } from "utils/routing";
import type { Hidden } from "legacy/constants/PropertyControlConstants";

export const createIsRouteParamsHidden =
  (setup: {
    isHiddenForType: Hidden;
    getPath: (path: string) => string | undefined;
  }) =>
  (
    props: any,
    propPath: string,
    flags: Partial<Flags>,
    additionalHiddenData?: Record<string, unknown>,
  ) => {
    const isHiddenForType = setup.isHiddenForType(
      props,
      propPath,
      flags,
      additionalHiddenData,
    );
    const path = setup.getPath ? setup.getPath(propPath) : undefined;
    const value = path ? get(props, path, {}) : props;

    if (!value?.routeId) {
      return true;
    }

    if (
      value?.routeId &&
      additionalHiddenData?.routes &&
      Array.isArray(additionalHiddenData?.routes)
    ) {
      const route = additionalHiddenData?.routes.find(
        (r) => r.id === value.routeId,
      );
      if (route) {
        return (
          isHiddenForType || extractDynamicSegments(route.path).length === 0
        );
      }
    }

    return isHiddenForType;
  };
