import { type CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";

/**
 * Find first parent in the tree that matches the given condition.
 *
 * @param initialWidgetId - The id of the widget to start with.
 * @param widgets - The state of all widgets.
 * @param matchFn - The function to match the widget against.
 * @returns widget that matches the condition or undefined if no match is found.
 */
export const findFirstParent = (
  initialWidgetId: string,
  widgets: CanvasWidgetsReduxState,
  matchFn: (widget: CanvasWidgetsReduxState[string]) => boolean,
) => {
  let parent: CanvasWidgetsReduxState[string] | undefined =
    widgets[initialWidgetId];
  while (parent) {
    if (matchFn(parent)) {
      return parent;
    }
    if (parent.parentId) {
      parent = widgets[parent.parentId];
    } else {
      parent = undefined;
    }
  }
};
