function transpose(array: unknown[][], largestRow = 0): unknown[][] {
  if (array.length === 0) {
    return [];
  }

  return array[largestRow].map((_, i) => (array ?? []).map((row) => row[i]));
}

function fillArray(array: unknown[]): unknown[] {
  return Array.from(array, (_, i) => (i in array ? array[i] : null));
}

export function collapseTableData(
  data: unknown[][],
  largestRow?: number,
): unknown[][] {
  // The data can be sparsely populated, so we need to fill in the gaps in order to
  // properly collapse the columns
  const filledData = data.map((row) => fillArray(row));

  const transposed = transpose(filledData, largestRow);
  const emptyColumns = transposed.map((col) =>
    col.every((cell) => cell == null),
  );

  return filledData
    .filter((row) => row.length > 0)
    .map((row) => row.filter((_, colIdx) => !emptyColumns[colIdx]));
}
