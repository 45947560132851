import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { NUMBER_FORMATTING_OPTIONS } from "legacy/utils/FormatUtils";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import {
  iconPositionProperty,
  iconProperty,
  maximumFractionDigitsProperty,
  minimumFractionDigitsProperty,
} from "../appearanceProperties";
import {
  labelPositionProperty,
  paddingProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import { styleProperties, typographyProperties } from "../styleProperties";
import { hasIconSupport, isNumericInput } from "./InputComponent";
import {
  DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
  INPUT_TYPES_WITH_MINMAX,
  INPUT_TYPE_OPTIONS,
  InputTypes,
  DEFAULT_INPUT_BORDER_OBJECT,
} from "./InputWidgetConstants";
import type { InputWidgetProps } from "./types";

const config: PropertyPaneConfig[] = [
  {
    sectionName: "General",
    sectionCategory: PropsPanelCategory.Content,
    children: [
      {
        helpText: "Sets a label text for the input",
        propertyName: "label",
        label: "Label",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter label text",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "Label",
        updateHook: updateHeightOnAddOrRemove,
      },
      {
        helpText: "Changes the type of data captured in the input",
        propertyName: "inputType",
        label: "Data type",
        controlType: "DROP_DOWN",
        options: INPUT_TYPE_OPTIONS,
        isBindProperty: false,
        isTriggerProperty: false,
      },
      paddingProperty({ propertyName: "inputProps.padding" }),
    ],
  },
  sizeSection(),
  {
    sectionName: "Advanced",
    children: [
      {
        propertyName: "numberFormatting",
        label: "Number format",
        controlType: "DROP_DOWN",
        hidden: (props: InputWidgetProps) =>
          props.inputType !== InputTypes.NUMBER &&
          props.inputType !== InputTypes.CURRENCY,
        defaultValue: undefined,
        options: NUMBER_FORMATTING_OPTIONS,
        isBindProperty: false,
        isTriggerProperty: false,
        helpText: "The display format of the number",
        propertyCategory: PropsPanelCategory.Content,
      },
      {
        propertyName: "currency",
        label: "Currency code",
        helpText: "The three letter ISO 4217 currency code of the input",
        controlType: "DROP_DOWN",
        defaultValue: "USD",
        options: CurrencyList.map((currency) => ({
          label: currency,
          value: currency,
        })),
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.CURRENCY,
        isBindProperty: true,
        isJSConvertible: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Content,
      },
      {
        helpText:
          "Sets the default text of the component. The text is updated if the default text changes",
        propertyName: "defaultText",
        label: "Default value",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter default text",
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Content,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "",
      },
      {
        propertyName: "currencyCodeDisplay",
        label: "Currency code display",
        helpText:
          "The display for currency codes. If a symbol is unavailable, the display will fall back to the code",
        controlType: "RADIO_BUTTON_GROUP",
        defaultValue: "symbol",
        options: [
          {
            label: "Symbol",
            value: "symbol",
          },
          {
            label: "ISO Code",
            value: "iso_code",
          },
        ],
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.CURRENCY,
        isBindProperty: false,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      labelPositionProperty({ orientation: "legacy" }),
      ...typographyProperties({
        textStyleParentDottedPath: "labelProps",
        propertyNameForHumans: "Label",
        defaultVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
        hiddenIfPropertyNameIsNullOrFalse: "label",
      }),
      {
        helpText: "Sets a placeholder text for the input",
        propertyName: "placeholderText",
        label: "Placeholder",
        controlType: "INPUT_TEXT",
        placeholderText: "Input text",
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      ...typographyProperties({
        textStyleParentDottedPath: "inputProps",
        propertyNameForHumans: "Input",
        defaultVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
      }),
      ...styleProperties({
        propertyNamespaceDottedPath: "inputProps",
        defaultBorderProperty: DEFAULT_INPUT_BORDER_OBJECT,
        borderThemeValue: DEFAULT_INPUT_BORDER_OBJECT,
        backgroundColorDefaultValue: "{{ theme.colors.neutral }}",
        backgroundColorThemeValue: "{{ theme.colors.neutral }}",
        borderRadiusThemeValue: ({ theme }: { theme: any }) => {
          return {
            treatAsNull: false,
            value: createPerCornerBorderRadius(theme.borderRadius),
          };
        },
      }),
      minimumFractionDigitsProperty({
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.NUMBER &&
          inputType !== InputTypes.PERCENTAGE &&
          inputType !== InputTypes.CURRENCY,
        propertyCategory: PropsPanelCategory.Interaction,
      }),
      maximumFractionDigitsProperty({
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.NUMBER &&
          inputType !== InputTypes.PERCENTAGE &&
          inputType !== InputTypes.CURRENCY,
        propertyCategory: PropsPanelCategory.Interaction,
      }),
      {
        propertyName: "stepper",
        label: "Stepper",
        controlType: "SWITCH",
        hidden: ({ inputType }: InputWidgetProps) => !isNumericInput(inputType),
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        helpText:
          "Display a stepper in the input for users to adjust the value via arrows",
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        propertyName: "stepSize",
        label: "Step size",
        controlType: "INPUT_TEXT",
        hidden: ({ inputType }: InputWidgetProps) => !isNumericInput(inputType),
        defaultValue: 1,
        isBindProperty: true,
        isTriggerProperty: false,
        helpText:
          "The increment used when adjusting the value via the UI stepper or keyboard arrow keys",
        propertyCategory: PropsPanelCategory.Interaction,
      },
      iconProperty({
        hidden: ({ inputType }: InputWidgetProps) => !hasIconSupport(inputType),
      }),
      iconPositionProperty({
        hidden: ({ inputType, icon }: InputWidgetProps) =>
          !hasIconSupport(inputType) || icon == null,
        propertyCategory: PropsPanelCategory.Appearance,
      }),
      {
        helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
        propertyName: "animateLoading",
        label: "Loading animation",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      ...visibleProperties({ useJsExpr: false }),
    ],
  },
  {
    sectionName: "Actions",
    sectionCategory: PropsPanelCategory.EventHandlers,
    children: [
      getPopoverConfig(
        "onTextChanged",
        "Triggers an action when the text is changed",
      ),
      getPopoverConfig(
        "onSubmit",
        "Triggers an action on submit (when the enter key is pressed)",
      ),
      getPopoverConfig("onFocus", "Triggers an action on input focus"),
      getPopoverConfig("onFocusOut", "Triggers an action on input blur"),
    ],
  },
  {
    sectionName: "Validation",
    sectionCategory: PropsPanelCategory.Interaction,
    children: [
      {
        propertyName: "minLength",
        label: "Min length",
        controlType: "INPUT_TEXT",
        inputType: "NUMBER",
        hidden: ({ inputType }: InputWidgetProps) =>
          !INPUT_TYPES_WITH_MINMAX.includes(inputType),
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "",
      },
      {
        propertyName: "maxLength",
        label: "Max length",
        controlType: "INPUT_TEXT",
        inputType: "NUMBER",
        hidden: ({ inputType }: InputWidgetProps) =>
          !INPUT_TYPES_WITH_MINMAX.includes(inputType),
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "",
      },
      {
        helpText: "Disables input to this component",
        propertyName: "isDisabled",
        label: "Disabled",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.BOOLEAN,
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        propertyName: "isRequired",
        label: "Required",
        helpText: "Makes input to the component mandatory",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "customValidationRule",
        label: "Validation",
        helpText: "Sets a custom validation rule for the input",
        controlType: "INPUT_TEXT",
        placeholderText: "{{/./.test(Input1.value)}}",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "",
      },
      {
        propertyName: "customErrorMessage",
        label: "Error",
        helpText:
          "Sets a custom message to display in a popover when validation doesn't pass",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter error msg",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "",
      },
    ],
  },
];

export default config;
