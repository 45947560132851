import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { DiffMethod } from "legacy/widgets/DiffWidget/DiffMethod";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { type DiffWidgetProps } from "./DiffWidget";

const config: PropertyPaneCategoryConfig<DiffWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "compareMethod",
      label: "Compare by",
      controlType: "DROP_DOWN",
      helpText: "Method used for diffing strings",
      options: [
        { value: DiffMethod.LINES, label: "Line" },
        { value: DiffMethod.WORDS, label: "Word" },
        { value: DiffMethod.CHARS, label: "Character" },
        { value: DiffMethod.CSS, label: "CSS" },
        { value: DiffMethod.JSON, label: "JSON" },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: "originalLabel",
      label: "Original label",
      helpText: "Label for old value",
      controlType: "INPUT_TEXT",
      placeholderText: "Original",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Original",
    },
    {
      propertyName: "originalText",
      label: "Original text",
      helpText: "Left side text to compare",
      controlType: "INPUT_TEXT",
      placeholderText: "{ 'file': 'text.txt' }",
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "newLabel",
      label: "New label",
      helpText: "Label for new value",
      controlType: "INPUT_TEXT",
      placeholderText: "New",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "New",
    },
    {
      propertyName: "newText",
      label: "New text",
      helpText: "Right Side text to compare",
      controlType: "INPUT_TEXT",
      placeholderText: "{ 'file': 'text.json' }",
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      propertyName: "showDiffOnly",
      label: "Compact view",
      controlType: "SWITCH",
      helpText: "Shows only the diffed lines and folds the unchanged lines",
      validation: VALIDATION_TYPES.BOOLEAN,
      isJSConvertible: false,
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection(),
    ...visibleProperties({ useJsExpr: false }),
  ]),
];

export default config;
