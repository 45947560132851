import {
  Padding,
  Dimension,
  PerSideBorder,
  PerCornerBorderRadius,
} from "@superblocksteam/shared";
import React, { ReactNode, useRef, useEffect, RefObject, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useDeepMemo from "hooks/ui/useDeepMemo";
import { ComponentProps } from "legacy/components/designSystems/default/BaseComponent";
import { innerInvisible } from "legacy/constants/DefaultTheme";
import {
  CanvasLayout,
  CanvasDistribution,
  CanvasAlignment,
  GridDefaults,
  CanvasDefaults,
  WidgetType,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { generateClassName, getCanvasClassName } from "legacy/utils/generators";
import { generatePaddingStyleObject } from "legacy/widgets/base/generatePaddingStyle";
import { isStackLayout } from "../StackLayout/utils";
import {
  generateBorderStyleObject,
  generateBorderRadiusStyleObject,
} from "../base/generateBorderStyle";
import { ComponentBorder } from "./ComponentBorder";
import { ScrollContainer } from "./ScrollContainer";
import type { AppState } from "store/types";

const emptyStyle = Object.freeze({});
const collapsedStyle = Object.freeze({ minHeight: "0", height: "0" });

const verticalAlignmentContentMap: Record<CanvasDistribution, string> = {
  [CanvasDistribution.TOP]: "flex-start",
  [CanvasDistribution.CENTER]: "center",
  [CanvasDistribution.BOTTOM]: "flex-end",
  [CanvasDistribution.STRETCH]: "stretch",
  [CanvasDistribution.SPACE_BETWEEN]: "space-between",
  [CanvasDistribution.SPACE_AROUND]: "space-around",
};

const horizontalAlignmentContentMap: Record<CanvasAlignment, string> = {
  [CanvasAlignment.LEFT]: "flex-start",
  [CanvasAlignment.CENTER]: "center",
  [CanvasAlignment.RIGHT]: "flex-end",
  [CanvasAlignment.STRETCH]: "stretch",
  [CanvasAlignment.SPACE_BETWEEN]: "space-between",
  [CanvasAlignment.SPACE_AROUND]: "space-around",
};

type ContainerDivProps = {
  padding: ContainerComponentProps["padding"];
  backgroundColor: ContainerComponentProps["backgroundColor"];
  shouldScrollContents: ContainerComponentProps["shouldScrollContents"];
  resizeDisabled: ContainerComponentProps["resizeDisabled"];
  isVisible: ContainerComponentProps["isVisible"];
  selected: ContainerComponentProps["selected"];
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickCapture?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const StyledContainerComponent = styled.div<
  ContainerDivProps & {
    ref: RefObject<HTMLDivElement>;
  }
>`
  height: 100%;
  width: 100% ${(props) => (!props.isVisible ? innerInvisible : "")};
  overflow: hidden;
  z-index: ${(props) => (props.resizeDisabled ? 0 : 1)};
`;

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  &[data-hasborder="true"] {
    /* 
      Removes 1px padding from Container elements to support full-width children
      does not remove padding from Canvas elements
      -1 margin means +2 width/height
    */
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    margin: -1px;
  }
`;

const ContainerComponent = (props: ContainerComponentProps) => {
  const containerStyle = props.containerStyle || "card";
  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.shouldScrollContents) {
      const supportsNativeSmoothScroll =
        "scrollBehavior" in document.documentElement.style;
      if (supportsNativeSmoothScroll) {
        containerRef.current?.scrollTo?.({ top: 0, behavior: "smooth" });
      } else if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }
  }, [props.shouldScrollContents]);
  const generatedTheme = useSelector(selectGeneratedTheme);

  const hasBorderByDefault = props.hasDefaultBorder ?? false;

  const propsForContainerDiv: ContainerDivProps = {
    backgroundColor: props.backgroundColor,
    shouldScrollContents: props.shouldScrollContents,
    resizeDisabled: props.resizeDisabled,
    selected: props.selected,
    padding: props.padding,
    isVisible: props.isVisible,
    // We have to use any here as onClick is different from WidgetPropsRuntime vs. what the GridWidget passes in and the conflict is difficult to resolve properly
    onClick: (props as any).onClick,
    onClickCapture: (props as any).onClickCapture,
  };

  const parentWidget: ReturnType<typeof getFlattenedCanvasWidget> | undefined =
    useSelector((state: AppState) =>
      getFlattenedCanvasWidget(state, props.parentId ?? ""),
    );

  // this is the main content of an hstack (not the parent that scrolls it)
  const isInnerHStack =
    props.layout === CanvasLayout.HSTACK && !props.shouldScrollContents;

  const borderStyle = useMemo(() => {
    const fallbackThemeBorderColor = hasBorderByDefault
      ? generatedTheme.container.default.borderColor.default
      : generatedTheme.container.canvas.borderColor.default;

    // border is rendered as a separate overlay component
    let borderStyle = generateBorderStyleObject({
      border: props.border,
      // props.borderColor is for backwards compatibility with existing widgets
      fallbackBorderColor: props.borderColor ?? fallbackThemeBorderColor,
      borderRadius: props.borderRadius,
    });

    if (props.forceCollapse) {
      borderStyle = {
        ...borderStyle,
        ...collapsedStyle,
      };
    }

    return borderStyle;
  }, [
    hasBorderByDefault,
    generatedTheme.container.canvas.borderColor.default,
    generatedTheme.container.default.borderColor.default,
    props.border,
    props.borderColor,
    props.borderRadius,
    props.forceCollapse,
  ]);

  const { innerContainerStyle, outerStyle } = useDeepMemo(() => {
    let outerStyle: React.CSSProperties = {};
    if (props.padding) {
      outerStyle = {
        ...outerStyle,
        ...generatePaddingStyleObject(props.padding),
      };
    }
    if (props.backgroundColor) {
      outerStyle.background = props.backgroundColor;
    }
    // border radius goes on the container itself, as well as the container's border component
    if (props.borderRadius) {
      outerStyle = {
        ...outerStyle,
        ...generateBorderRadiusStyleObject({
          borderRadius: props.borderRadius,
        }),
      };
    }

    if (!isStackLayout(props.layout)) {
      if (props.forceCollapse) {
        outerStyle = { ...outerStyle, ...collapsedStyle };
        return { innerContainerStyle: collapsedStyle, outerStyle };
      } else {
        return { innerContainerStyle: emptyStyle, outerStyle };
      }
    }

    let innerContainerStyle: React.CSSProperties = {};

    const heightPx = props.height
      ? Dimension.toPx(props.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
          ?.value
      : undefined; // this undefined shouldn't actually happen

    if (props.layout === CanvasLayout.HSTACK) {
      outerStyle.width = "auto";

      const outerWidthPx =
        Dimension.toPx(props.width, props.parentColumnSpace)?.value ?? 0;

      if (isInnerHStack && heightPx != null) {
        const widthPx = props.internalWidth?.value ?? outerWidthPx; // this should never happen

        const xPadding = props.padding ? Padding.x(props.padding).value : 0;

        outerStyle.width = `calc(${widthPx}px + ${xPadding}px + ${
          props.outerWidthAdjustmentPx ?? 0
        }px)`;

        innerContainerStyle.width = `${widthPx}px`;
      } else {
        innerContainerStyle.width = `${outerWidthPx}px`;
      }
    } else if (
      props.layout === CanvasLayout.VSTACK &&
      props.width?.mode === "fitContent"
    ) {
      const outerWidthPx = Dimension.toPx(
        props.width,
        props.parentColumnSpace,
      )?.value;
      const widthPx = props.internalWidth?.value ?? outerWidthPx; // this should never happen

      const xPadding = props.padding ? Padding.x(props.padding).value : 0;

      outerStyle.width = `calc(${widthPx}px + ${xPadding}px + ${
        props.outerWidthAdjustmentPx ?? 0
      }px)`;
      innerContainerStyle.width = `${widthPx}px`;
    }

    if (props.forceCollapse) {
      innerContainerStyle = {
        ...innerContainerStyle,
        ...collapsedStyle,
      };
      outerStyle = {
        ...outerStyle,
        ...collapsedStyle,
      };
    } else if (parentWidget?.height?.mode === "fitContent") {
      let minHeightPx = heightPx;

      if (minHeightPx && parentWidget?.maxHeight) {
        minHeightPx = Math.min(
          minHeightPx,
          Dimension.toPx(
            parentWidget.maxHeight,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).value,
        );
      }
      innerContainerStyle.minHeight = minHeightPx ? `${minHeightPx}px` : "auto";
    } else {
      innerContainerStyle.height = heightPx ? `${heightPx}px` : "auto";
    }

    if (!props.forceCollapse) {
      innerContainerStyle.maxHeight = "100%";
    }

    if (props.layout === CanvasLayout.VSTACK) {
      innerContainerStyle.display = "flex";
      innerContainerStyle.flexDirection = "column";
      innerContainerStyle.justifyContent =
        verticalAlignmentContentMap[
          props.distribution || CanvasDistribution.TOP
        ];
      innerContainerStyle.alignItems =
        horizontalAlignmentContentMap[
          props.alignment || CanvasAlignment.STRETCH
        ];
      if (props.appMode !== APP_MODE.EDIT) {
        innerContainerStyle.gap = `${
          (props.spacing ?? CanvasDefaults.SPACING).value
        }px`;
      }
    } else if (props.layout === CanvasLayout.HSTACK) {
      // distribution and alignment are reversed for hstack
      innerContainerStyle.display = "flex";
      innerContainerStyle.flexDirection = "row";
      innerContainerStyle.alignItems =
        verticalAlignmentContentMap[
          props.distribution || CanvasDistribution.TOP
        ];
      innerContainerStyle.justifyContent =
        horizontalAlignmentContentMap[
          props.alignment || CanvasAlignment.STRETCH
        ];

      if (props.appMode !== APP_MODE.EDIT) {
        innerContainerStyle.gap = `${
          (props.spacing ?? CanvasDefaults.SPACING).value
        }px`;
      }

      innerContainerStyle.maxWidth = "100%";
    }

    return { innerContainerStyle, outerStyle };
  }, [
    props.padding,
    props.backgroundColor,
    props.borderRadius,
    props.layout,
    props.height,
    props.width,
    props.parentColumnSpace,
    props.internalWidth?.value,
    props.outerWidthAdjustmentPx,
    props.distribution,
    props.alignment,
    props.appMode,
    props.spacing,
    props.forceCollapse,
    parentWidget?.height?.mode,
    parentWidget?.maxHeight,
    isInnerHStack,
  ]);

  const renderLikeContainer = hasBorderByDefault;
  const showBorder = renderLikeContainer && containerStyle !== "none";
  const componentClasses = useMemo(
    () => [
      "container-component",
      !renderLikeContainer
        ? CLASS_NAMES.CANVAS
        : containerStyle === "card"
          ? CLASS_NAMES.DEFAULT_CONTAINER_STYLE_CARD
          : CLASS_NAMES.DEFAULT_CONTAINER_STYLE_NONE,
      generateClassName(props.widgetId),
      CLASS_NAMES.STYLED_SCROLLBAR,
      ...(props.shouldScrollContents ? [getCanvasClassName()] : []),
      ...(props.selected ? [CLASS_NAMES.ACTIVE_MODIFIER] : []),
    ],
    [
      renderLikeContainer,
      containerStyle,
      props.widgetId,
      props.shouldScrollContents,
      props.selected,
    ],
  );

  return (
    <StyledContainerComponent
      data-test="container-component"
      {...propsForContainerDiv}
      style={outerStyle}
      // Before you remove: generateClassName is used for bounding the resizables within this canvas
      // getCanvasClassName is used to add a scrollable parent
      className={componentClasses.join(" ")}
      ref={containerRef}
    >
      <ComponentBorder
        borderStyle={borderStyle}
        className={
          showBorder ? CLASS_NAMES.DEFAULT_CONTAINER_BORDER : undefined
        }
      />
      <InnerContainer
        data-hasborder={hasBorderByDefault}
        className={CLASS_NAMES.STYLED_SCROLLBAR}
        style={innerContainerStyle}
        id={`container-inner-container-${props.widgetId}`}
      >
        <ScrollContainer
          scroll={props.shouldScrollContents ?? false}
          style={{ width: "100%", height: "100%" }}
        >
          {props.children}
        </ScrollContainer>
      </InnerContainer>
    </StyledContainerComponent>
  );
};

interface ContainerComponentProps extends ComponentProps {
  appMode?: APP_MODE;
  type: WidgetType;
  outerWidthAdjustmentPx?: number;
  hasDefaultBorder: boolean;
  children?: ReactNode;
  className?: string;
  backgroundColor?: string;
  borderColor?: string; // borderColor is legacy, border is the new prop but it will fallback to borderColor if it was set
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
  shouldScrollContents?: boolean;
  resizeDisabled?: boolean;
  selected?: boolean;
  focused?: boolean;
  padding?: Padding;
  height?: Dimension<WidgetHeightModes>;
  internalWidth?: Dimension<"px">;
  width?: Dimension<WidgetWidthModes>;
  spacing?: Dimension<"px" | "gridUnit">;
  layout?: CanvasLayout;
  distribution?: CanvasDistribution;
  alignment?: CanvasAlignment;
  parentColumnSpace: number;
  containerStyle?: "card" | "none";
  parentId?: string;
  forceCollapse?: boolean;
}

export default ContainerComponent;
