import { Dimension } from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import React, { ReactNode, Ref, forwardRef, useMemo } from "react";
import styled from "styled-components";
import { GridDefaults, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import SearchComponent from "../Shared/SearchComponent";
import { useTypographyStyling } from "../typographyHooks";
import type { ComponentProps } from "legacy/components/designSystems/default/BaseComponent";

interface GridComponentProps extends ComponentProps {
  header: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  shouldScrollContents?: boolean;
  backgroundColor?: string;
  hasPagination?: boolean;
  columnCount?: number;
  overrideHeight?: number;
  minHeight?: Dimension<"px" | "gridUnit">;
  maxHeight?: Dimension<"px" | "gridUnit">;
}

const GridContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const GridMultiColumn = styled.div<{ props: GridComponentProps }>`
  display: grid;
  grid-template-columns: ${(props) =>
    new Array(props.props.columnCount ?? 1).fill("1fr").join(" ")};
  align-content: start;
`;

const GridMultiColumnLoading = styled(GridMultiColumn)`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px #e7e7e7;

  & > div {
    margin: 8px;
  }
`;

const normalizeHeightDimension = (
  height: Dimension<"px" | "gridUnit">,
): number => {
  return (
    Dimension.toPx(height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value -
    2 * WIDGET_PADDING
  );
};

const useGridContainerStyle = (props: {
  overrideHeight?: number;
  minHeight?: Dimension<"px" | "gridUnit">;
  maxHeight?: Dimension<"px" | "gridUnit">;
}) => {
  const { overrideHeight, minHeight, maxHeight } = props;
  return useMemo<React.CSSProperties>(() => {
    const style: React.CSSProperties = {};
    if (overrideHeight) {
      style.height = `${overrideHeight}px`;
    }
    if (minHeight) {
      style.minHeight = `${normalizeHeightDimension(minHeight)}px`;
    }
    if (maxHeight) {
      style.maxHeight = `${normalizeHeightDimension(maxHeight)}px`;
    }

    return style;
  }, [overrideHeight, minHeight, maxHeight]);
};

const GridComponent = (props: GridComponentProps) => {
  const style = useGridContainerStyle(props);
  return (
    <GridContainer className={CLASS_NAMES.DEFAULT_CONTAINER} style={style}>
      {props.header}
      <GridMultiColumn props={props}>{props.children}</GridMultiColumn>
      {props.footer}
    </GridContainer>
  );
};

export const GridComponentLoading = (props: GridComponentProps) => {
  const style = useGridContainerStyle(props);
  return (
    <GridContainer className={CLASS_NAMES.DEFAULT_CONTAINER} style={style}>
      {props.header}
      <GridMultiColumnLoading props={props}>
        {props.children}
      </GridMultiColumnLoading>
    </GridContainer>
  );
};

const EmptyWrapper = styled.div<{
  backgroundColor?: string;
}>`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-anchor: middle;
  color: ${(props) => props.theme.palettes.gray[7]};
  box-shadow: ${(props) => `0px 0px 0px 1px ${props.theme.border.width}`};
  padding: 12px;
`;

export const GridComponentEmpty = (props: {
  overrideHeight?: number;
  minHeight?: Dimension<"px" | "gridUnit">;
  maxHeight?: Dimension<"px" | "gridUnit">;
  children: ReactNode | string;
}) => {
  const style = useGridContainerStyle(props);
  return <EmptyWrapper style={style}>{props.children}</EmptyWrapper>;
};

interface GridHeaderProps {
  searchText?: string;
  isSearchable: boolean;
  onSearch: (text: string) => void;
  title?: string;
  headerProps?: {
    textStyle: TextStyleWithVariant;
  };
}

const GridHeaderWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;

const GridHeaderTitle = styled.div`
  margin-left: 20px;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 1) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const GridHeader = forwardRef(
  (
    { isSearchable, onSearch, searchText, title, headerProps }: GridHeaderProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const headerStyleProps = useTypographyStyling({
      textStyle: headerProps?.textStyle,
      defaultTextStyleVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
    });
    const headerClass = getTextCssClassFromVariant(
      headerStyleProps.textStyleVariant,
    );

    if (!isSearchable && isEmpty(title)) {
      return null;
    }
    return (
      <GridHeaderWrapper className={CLASS_NAMES.BORDER_BOTTOM} ref={ref}>
        <GridHeaderTitle className={headerClass} style={headerStyleProps.style}>
          {title}
        </GridHeaderTitle>
        {isSearchable && (
          <SearchComponent
            value={searchText ?? ""}
            placeholder="Search"
            onSearch={onSearch}
          />
        )}
      </GridHeaderWrapper>
    );
  },
);

GridHeader.displayName = "GridHeader";

export default GridComponent;
