import React from "react";
import { useSelector } from "react-redux";
import InputNumberControl, {
  InputNumberControlProps,
} from "legacy/components/propertyControls/InputNumberControl";
import { selectFlags } from "store/slices/featureFlags";

// TODO this injection pattern is not the prettiest
// consider refactoring InputNumberControl to not require all the Property panel props
export const WrappedInputNumberControl = (
  props: Pick<
    InputNumberControlProps,
    | "onPropertyChange"
    | "propertyName"
    | "unitOptions"
    | "defaultUnit"
    | "propertyValue"
    | "isDisabled"
    | "transformValueOnUnitChange"
    | "warning"
    | "dataTest"
    | "minValue"
  >,
) => {
  const featureFlags = useSelector(selectFlags);
  return (
    <InputNumberControl
      featureFlags={featureFlags}
      autoCreateFirstArrayEntry={true}
      isValid={true}
      onBlur={() => undefined}
      docLink=""
      exampleData=""
      expected=""
      widgetProperties={{}}
      label=""
      controlType=""
      isBindProperty={false}
      isTriggerProperty={false}
      onFocus={() => undefined}
      deleteProperties={() => undefined}
      openNextPanel={() => undefined}
      {...props}
    />
  );
};
