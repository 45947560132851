import { isObject } from "lodash";
import { DropdownEntireObjectOption } from "legacy/constants/DropdownConstants";
import type {
  DropdownWidgetOptionValue,
  DropdownOptionsTransformation,
} from "./DropdownWidget";

export const normalizeFunction = (
  val: DropdownWidgetOptionValue,
  transformationValue: DropdownOptionsTransformation["value"] | undefined,
) => {
  if (transformationValue === DropdownEntireObjectOption) {
    return val;
  }
  if (isObject(val)) {
    return JSON.stringify(val);
  }
  return String(val);
};
export const normalizeFunctionStringified = /*javascript*/ `
  const normalizeFunction = (val, transformationValue) => {
    if (transformationValue === "$$ENTIRE_OBJECT") {
      return val;
    }
    if (_.isObject(val)) {
      return JSON.stringify(val);
    }
    return String(val);
  };
`;
