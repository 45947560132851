import { Dimension } from "@superblocksteam/shared";
import React, { useRef } from "react";
import {
  GridDefaults,
  WidgetWidthConstraintType,
  WidgetWidthModes,
} from "legacy/constants/WidgetConstants";
import { useWidgetDragResize } from "legacy/hooks/dragResizeHooks";

const SAME_WIDTH_THRESHOLD = 4;

const useWidgetWidthConstraintResize = (params: {
  width: Dimension<WidgetWidthModes>;
  setIsResizingConstraintWidth: (
    value: boolean,
    constraintType: WidgetWidthConstraintType,
  ) => void;
  setResizingWidth: (
    value: number,
    constraintType: WidgetWidthConstraintType,
  ) => void;
  constraintType: WidgetWidthConstraintType;
  onResizeEnded: (
    constraintType: WidgetWidthConstraintType,
    height: Dimension<"px">,
  ) => void;
}) => {
  const {
    width,
    setIsResizingConstraintWidth,
    setResizingWidth,
    constraintType,
    onResizeEnded,
  } = params;

  const widthValuePx = Dimension.toPx(
    width,
    GridDefaults.DEFAULT_GRID_COLUMN_WIDTH,
  ).value;

  // We want to update the global editor state to indicate that we are resizing
  // a widget so that the editor responds in the same ways as when we're resizing a widget
  // with the normal resize drag handles
  const { setIsResizing: setIsResizingWidget } = useWidgetDragResize();

  const dragInitialValues = useRef<null | {
    dragXStartPosition: number;
    widthPx: number | undefined;
    minSizePx: number;
  }>(null);

  const previousSetWidth = useRef<Dimension<"px"> | null>(null);

  const drag = (e: PointerEvent) => {
    if (!dragInitialValues.current || dragInitialValues.current.widthPx == null)
      return;

    const xDiff = e.clientX - dragInitialValues.current.dragXStartPosition;
    let newWidth = Dimension.px(dragInitialValues.current.widthPx);
    if (Math.abs(xDiff) > SAME_WIDTH_THRESHOLD) {
      const rawPx = dragInitialValues.current.widthPx + xDiff;
      const roundedPx =
        Math.round(rawPx / GridDefaults.DEFAULT_GRID_COLUMN_WIDTH) *
        GridDefaults.DEFAULT_GRID_COLUMN_WIDTH;
      newWidth = Dimension.px(roundedPx);
    }
    if (previousSetWidth.current?.value === newWidth.value) return;

    let newWidthPx = Dimension.toPx(
      newWidth,
      GridDefaults.DEFAULT_GRID_COLUMN_WIDTH,
    ).value;
    // Don't allow resize to smaller than 1 grid row (todo: add largest canvas padding to this)
    if (newWidthPx < dragInitialValues.current.minSizePx) {
      newWidthPx = dragInitialValues.current.minSizePx;
    }
    previousSetWidth.current = Dimension.px(newWidthPx);

    setResizingWidth(newWidthPx, constraintType);
  };

  const endDrag = (e: PointerEvent) => {
    setIsResizingConstraintWidth(false, constraintType);
    setIsResizingWidget(false);
    document.removeEventListener("pointermove", drag);
    document.removeEventListener("pointerup", endDrag);
    if (previousSetWidth.current) {
      onResizeEnded(constraintType, previousSetWidth.current);
    }
  };

  const startDrag = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dragInitialValues.current = {
      dragXStartPosition: e.clientX,
      widthPx: widthValuePx,
      minSizePx: GridDefaults.DEFAULT_GRID_COLUMN_WIDTH,
    };
    previousSetWidth.current = null;
    setIsResizingConstraintWidth(true, constraintType);
    setIsResizingWidget(true);
    document.addEventListener("pointermove", drag);
    document.addEventListener("pointerup", endDrag);
  };

  return {
    startDrag,
  };
};

export default useWidgetWidthConstraintResize;
