import type { Rect } from "./ResizableUtils";

const isWithin = (rect: Rect, parent: Rect) => {
  return !(
    rect.left < parent.left ||
    rect.right > parent.right ||
    rect.top < parent.top ||
    rect.bottom > parent.bottom
  );
};

export default isWithin;
