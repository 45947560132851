import equal from "@superblocksteam/fast-deep-equal/es6";
import {
  ApplicationScope,
  ApplicationSettings,
  CustomComponentConfig,
  Dimension,
} from "@superblocksteam/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { LinkButton } from "components/ui/Button";
import {
  type EditorContextType,
  EditorContext,
} from "legacy/components/editorComponents/EditorContextProvider";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { type WidgetType } from "legacy/constants/WidgetConstants";
import {
  getCurrentBranch,
  getLocalDevServerState,
  isLocalDevModeEnabled,
} from "legacy/selectors/editorSelectors";
import { LocalDevServerStatus } from "legacy/utils/LocalDevServerState";
import { useAppDispatch } from "store/helpers";
import { getApplicationSettings } from "store/slices/application/selectors";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { styleAsClass } from "styles/styleAsClass";
import { makeDeferred } from "utils/test/promise";
import { RunWidgetEventHandlers, WidgetPositionProps } from "../BaseWidget";
import { customComponentId } from "../CustomComponentTypeUtil";
import { validateCCPropertyValue } from "./propertyValueValidation";

interface WidgetSize {
  widthGrid: number;
  heightGrid: number;
  widthPx: number;
  heightPx: number;
}

/** The of the context that is returned to the user via the `useSuperblocksContext` hook */
interface SuperblocksContext<
  Properties extends Record<string, any> = Record<string, any>,
  Events extends Record<string, () => Promise<void>> = Record<
    string,
    () => Promise<void>
  >,
> {
  updateProperties: (props: Partial<Properties>) => void;
  // For backwards compatibility with 0.0.20
  updateStatefulProperties: (props: Partial<Properties>) => void;
  events: Events;
}

// The @superblocksteam/custom-components package exports some hooks that can be used by the user. In order for these hooks
// to work, they need to have access to some information that is specific to the widget that is currently being rendered.
// This information is passed down to the custom component via a React Context. For this to work, we need to do two things:
// 1. The hooks need to run `useContext` internally to get the information from the React Context. For this, they need
//    to be able to access the React library and it has to be the same React as the one used by custom components. So
//    we cannot import React in the CLI and cannot use the React that is used by the UI.
// 2. The hooks need to be able to access the React Context object to use it as the argument to `useContext`.
// The React library and the React Context are the same for all custom components, so we can store them in the global
// object and the hook can access them from there.

/**
 * The information that is passed down to each custom component. This information is specific to the widget that is
 * currently being rendered. This is the information that is used to implement all superblocks hooks for custom components.
 * We pass that information down via a React Context.
 */
interface CCRenderingContext {
  widgetId: string;
  /** This is the value returned by the `useSuperblocksContext` hook */
  superblocksContext: SuperblocksContext;
  /**
   * This is the set of subscribers to the `isLoading` property: each time the value of `isLoading` changes, we need to
   * call these functions to notify all custom components that have expressed interest in being notified of changes to
   * the `isLoading` property. It is used internally by the `useSuperblocksIsLoading` hook
   */
  isLoadingSubscribers: Set<(isLoading: boolean) => void>;
  /** The current value of the `isLoading` property. Is is used internally by the `useSuperblocksIsLoading` hook */
  isLoadingRef: React.MutableRefObject<boolean>;
  /**
   * The set of subscribers to the widget size object: each time the size of the widget changes, we need to
   * call these functions to notify all custom components that have expressed interest in being notified of such
   * changes. It is used internally by the `useSuperblocksWidgetSize` hook
   */
  widgetSizeSubscribers: Set<(widgetSize: WidgetSize) => void>;
  /** The current size of the widget. Is is used internally by the `useSuperblocksWidgetSize` hook */
  widgetSizeRef: React.MutableRefObject<WidgetSize>;
}

/** The type of information that we store in the global object for the `useSuperblocksContext` hook to find */
interface CCReactInfo {
  /** The React namespace object from the React version that is used by custom components */
  React: any;
  /** The React Context that is used to pass the information down to custom components */
  SuperblocksCCReactContext: React.Context<CCRenderingContext | undefined>;
}

// The place where we store the `CCReactInfo` object
const ccReactInfoRegister: {
  current: undefined | CCReactInfo;
} = {
  current: undefined,
};
(globalThis as any).__cc_react_info = ccReactInfoRegister;

interface ReactShim {
  /** The React namespace object from the React version that is used by custom components */
  React: any;
  registerPortal: (
    component: React.ReactNode,
    props: Record<string, unknown>,
    element: Element,
    ccRenderingContext: CCRenderingContext,
    SuperblocksCCReactContext: React.Context<CCRenderingContext | undefined>,
  ) => void;
  removePortal: (widgetId: string) => void;
}

/** Hook that returns the component's base URL and full path. */
function usePaths(
  appSettings: ApplicationSettings,
  componentConfig: CustomComponentConfig,
  isDevMode: boolean,
): [string, string] | [undefined, undefined] {
  return useMemo(() => {
    if (!isDevMode && !appSettings.componentBaseUrl) {
      return [undefined, undefined];
    }
    const componentBaseUrl = appSettings.componentBaseUrl + "dist/";
    const fullComponentPath = isDevMode
      ? new URL(
          componentConfig.componentPath,
          `http://localhost:3002`,
        ).toString()
      : new URL(
          `${componentConfig.name}.js`,
          // ensure trailing slash so that `name.js` is a relative URL
          componentBaseUrl[componentBaseUrl.length - 1] === "/"
            ? componentBaseUrl
            : componentBaseUrl + "/",
        ).toString();
    return [componentBaseUrl, fullComponentPath];
  }, [appSettings, componentConfig, isDevMode]);
}

/** Hook that returns the component's size in the canvas. */
function useWidgetSize(properties: WidgetPositionProps) {
  return useMemo(() => {
    return Object.freeze({
      widthGrid: Dimension.toGridUnit(
        properties.width,
        properties.parentColumnSpace as number,
      ).roundUp().value,
      heightGrid: Dimension.toGridUnit(
        properties.height,
        properties.parentRowSpace as number,
      ).roundUp().value,
      widthPx: Dimension.toPx(
        properties.width,
        properties.parentColumnSpace as number,
      ).value,
      heightPx: Dimension.toPx(
        properties.height,
        properties.parentRowSpace as number,
      ).value,
    });
  }, [
    properties.height,
    properties.parentColumnSpace,
    properties.parentRowSpace,
    properties.width,
  ]);
}

/** Creates an object that maps event names to functions that trigger the event. */
function getEventTriggerFunctions(
  runEventHandlers: (actionPayload: RunWidgetEventHandlers) => void,
  componentConfig: CustomComponentConfig,
  propertiesRef: React.MutableRefObject<Record<string, unknown>>,
) {
  const eventTriggerFunctions: Record<string, () => Promise<void>> = {};
  const eventNames = (componentConfig.events ?? []).map((e) => e.path);
  eventNames.forEach((eventName) => {
    eventTriggerFunctions[eventName] = () => {
      const steps = propertiesRef.current[eventName] as
        | MultiStepDef
        | undefined;
      if (!steps) {
        return Promise.resolve();
      }
      const deferral = makeDeferred<void>();
      const callbackId = addNewPromise(deferral.resolver);
      runEventHandlers({
        steps,
        type: EventType.ON_CUSTOM_COMPONENT_EVENT,
        currentScope: ApplicationScope.PAGE,
        // override the name for the event that we use for the call stack in order to display the user's name for the event
        triggerLabel: eventName,
        callbackId,
        additionalNamedArguments: {},
      });

      return deferral;
    };
  });
  return eventTriggerFunctions;
}

/**
 * Creates a function that can be used to update (user) properties.
 * Important: this hook must be backwards compatible or it will break deployed apps
 */
function getUpdateProperties(
  componentConfig: CustomComponentConfig,
  updateWidgetMetaProperty: EditorContextType["updateWidgetMetaProperty"],
  widgetId: string,
) {
  // Note: this function is executed within a useMemo, so it is will only be executed when the component config changes
  const propertyDefinitionsMap = new Map(
    (componentConfig.properties ?? []).map((p) => [p.path, p]),
  );
  return (obj: any) => {
    try {
      structuredClone(obj);
    } catch (e) {
      console.error(
        "Error while updating properties of custom widget, please ensure that the properties serializable",
        e,
      );
      return;
    }
    const parsedObj: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(obj ?? {})) {
      const propertyDefinition = propertyDefinitionsMap.get(key);
      if (!propertyDefinition) {
        console.error(
          "Error while updating properties of custom widget, please ensure that the properties you are updating, are defined in the config.ts file",
        );
        return;
      }
      try {
        parsedObj[key] = validateCCPropertyValue(
          propertyDefinition.dataType,
          value,
          {} as any,
        );
      } catch (e: any) {
        const msg = `Error while updating property ${key}, type validation failed: ${e.message}}`;
        console.error(msg);
        throw new TypeError(msg);
      }
    }
    Object.entries(parsedObj ?? {}).forEach(([key, value]) => {
      updateWidgetMetaProperty?.(widgetId, key, value);
    });
  };
}

/**
 * Creates an object that contains functions that can be used by the user's code.
 * The object is memoized so that it is updated only if the component config changes.
 * @returns an object that contains functions that can be used by the user's code
 */
function useCreateCCRenderingContext(
  runEventHandlers: (actionPayload: RunWidgetEventHandlers) => void,
  componentConfig: CustomComponentConfig,
  propertiesRef: React.MutableRefObject<Record<string, unknown>>,
  widgetId: string,
): CCRenderingContext {
  const { updateWidgetMetaProperty } = useContext(EditorContext);
  const isLoadingSubscribersRef = useRef(
    new Set<(isLoading: boolean) => void>(),
  );
  const isLoadingRef = useRef(
    (propertiesRef.current.isLoading as boolean) ?? false,
  );
  const widgetSizeSubscribersRef = useRef(
    new Set<(widgetSize: WidgetSize) => void>(),
  );
  const widgetSize = useWidgetSize(
    propertiesRef.current as WidgetPositionProps,
  );
  const widgetSizeRef = useRef(widgetSize);
  widgetSizeRef.current = widgetSize;

  return useMemo(() => {
    const eventTriggerFunctions = getEventTriggerFunctions(
      runEventHandlers,
      componentConfig,
      propertiesRef,
    );
    // Important: this hook must be backwards compatible or it will break deployed apps
    const updateProperties = getUpdateProperties(
      componentConfig,
      updateWidgetMetaProperty,
      widgetId,
    );
    return {
      widgetId,
      superblocksContext: {
        updateProperties,
        updateStatefulProperties: updateProperties,
        events: eventTriggerFunctions,
      },
      isLoadingSubscribers: isLoadingSubscribersRef.current,
      isLoadingRef,
      widgetSizeSubscribers: widgetSizeSubscribersRef.current,
      widgetSizeRef,
    };
  }, [
    runEventHandlers,
    updateWidgetMetaProperty,
    componentConfig,
    propertiesRef,
    widgetId,
  ]);
}

const CContainer = styleAsClass`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const LoadingPlaceholder = <div>loading...</div>;

const NotUploadedMessage = ({ name }: { name: string }) => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <p>{name} component has not been uploaded to Superblocks servers yet.</p>
      <p>
        If you are currently developing,{" "}
        <LinkButton
          onClick={() => {
            dispatch({
              type: ReduxActionTypes.SET_LOCAL_DEV_MODE,
              payload: true,
            });
          }}
        >
          enable Local Dev Mode
        </LinkButton>{" "}
        and run your local dev server with{" "}
        <code>superblocks components watch</code>.
      </p>{" "}
      <p>
        Once you are ready, be sure to upload your component with{" "}
        <code>superblocks components upload</code> to access it outside of Local
        Dev Mode.
      </p>
    </div>
  );
};

function DisconnectedMessage() {
  return (
    <div>
      Local development server not connected. Please ensure you are running the{" "}
      <code>superblocks components watch</code> command, then{" "}
      <LinkButton onClick={() => window.location.reload()}>
        retry connection
      </LinkButton>
      .
    </div>
  );
}

function BranchMismatchMessage({
  uiBranch,
  watchBranch,
}: {
  uiBranch: string;
  watchBranch: string;
}) {
  return (
    <div>
      Component unavailable - mismatched branches. You are viewing the{" "}
      {uiBranch} branch in the Superblocks UI, but your local branch is{" "}
      {watchBranch}. Please resolve this mismatch, then{" "}
      <LinkButton onClick={() => window.location.reload()}>retry</LinkButton>.
    </div>
  );
}

function CustomComponentImpl({
  appSettings,
  componentConfig,
  runEventHandlers,
  widgetId,
  properties,
}: {
  appSettings: ApplicationSettings;
  componentConfig: CustomComponentConfig;
  runEventHandlers: (actionPayload: RunWidgetEventHandlers) => void;
  widgetId: string;
  properties: Record<string, unknown>;
}) {
  const isDevMode = useSelector(isLocalDevModeEnabled);
  const localDevServerState = useSelector(getLocalDevServerState);
  const currentBranch = useSelector(getCurrentBranch);

  const [componentBaseUrl, fullComponentPath] = usePaths(
    appSettings,
    componentConfig,
    isDevMode,
  );

  const wrapperRef = useRef<any>(null);

  const widgetIdRef = useRef(widgetId);
  widgetIdRef.current = widgetId;

  const [ErrorDisplay, setErrorDisplay] =
    useState<React.ReactElement<any> | null>(
      !isDevMode && !componentBaseUrl ? (
        <NotUploadedMessage name={componentConfig.name} />
      ) : (
        LoadingPlaceholder
      ),
    );

  const ComponentRef = useRef<React.ComponentType<any> | null>(null);

  const ReactShimRef = useRef<ReactShim | null>(null);

  useEffect(() => {
    (window as any).__vite_plugin_react_preamble_installed__ = true;

    return () => {
      ReactShimRef.current?.removePortal(widgetIdRef.current);
    };
  }, []);

  const propertiesRef = useRef(properties);
  propertiesRef.current = properties;

  const ccRenderingContext = useCreateCCRenderingContext(
    runEventHandlers,
    componentConfig,
    propertiesRef,
    widgetId,
  );
  const CCRenderingContextRef = useRef(ccRenderingContext);
  CCRenderingContextRef.current = ccRenderingContext;

  // used to store the props object passed to the user's code on the previous render
  const prevProps = useRef<Record<string, unknown>>({});

  // used to store the value of the `isLoading` property on the previous render
  const prevIsLoading = useRef((properties.isLoading as boolean) ?? false);

  // used to store the widget size object on the previous render
  const prevWidgetSize = useRef(ccRenderingContext.widgetSizeRef.current);

  const renderImmediately = useCallback(
    (force: boolean) => {
      if (
        ReactShimRef.current &&
        ComponentRef.current &&
        ccReactInfoRegister.current
      ) {
        const props: Record<string, unknown> = {};
        let hasChanged = false;
        componentConfig.properties?.forEach((property) => {
          let curValue = propertiesRef.current[property.path];
          if (
            ["number", "string"].includes(property.dataType) &&
            curValue === undefined
          ) {
            curValue = null;
          }
          if (equal(curValue, prevProps.current[property.path])) {
            props[property.path] = prevProps.current[property.path];
          } else {
            props[property.path] = curValue;
            hasChanged = true;
          }
        });
        prevProps.current = props;
        if (hasChanged || force) {
          ReactShimRef.current.registerPortal(
            ComponentRef.current as any,
            props,
            wrapperRef.current,
            CCRenderingContextRef.current,
            ccReactInfoRegister.current.SuperblocksCCReactContext,
          );
        }
        const isLoading = (propertiesRef.current.isLoading as boolean) ?? false;
        if (prevIsLoading.current !== isLoading) {
          prevIsLoading.current = isLoading;
          CCRenderingContextRef.current.isLoadingRef.current = isLoading;
          // notify all subscribers that the value of `isLoading` has changed
          CCRenderingContextRef.current.isLoadingSubscribers.forEach((fn) =>
            fn(isLoading),
          );
        }
        if (
          CCRenderingContextRef.current.widgetSizeRef.current !==
          prevWidgetSize.current
        ) {
          prevWidgetSize.current =
            CCRenderingContextRef.current.widgetSizeRef.current;
          // notify all subscribers that the widget size has changed
          CCRenderingContextRef.current.widgetSizeSubscribers.forEach((fn) =>
            fn(CCRenderingContextRef.current.widgetSizeRef.current),
          );
        }
      }
    },
    [componentConfig.properties],
  );

  // Rerender the external React code on props changes
  useEffect(() => {
    if (properties) renderImmediately(false);
  }, [properties, renderImmediately]);

  useEffect(() => {
    let isStillMounted = true;
    let unregisterModuleReloadCallback: undefined | (() => void);

    (async () => {
      let reactShimPath;

      if (isDevMode) {
        reactShimPath = new URL(
          "injectedReactShim.jsx",
          `http://localhost:3002`,
        ).toString();
      } else if (componentBaseUrl) {
        reactShimPath = new URL(
          `injectedReactShim.js`,
          // ensure trailing slash so that `name.js` is a relative URL
          componentBaseUrl[componentBaseUrl.length - 1] === "/"
            ? componentBaseUrl
            : componentBaseUrl + "/",
        ).toString();
      }

      if (!reactShimPath) return;

      try {
        if (
          localDevServerState.status === LocalDevServerStatus.CONNECTED &&
          localDevServerState.git?.active &&
          localDevServerState.git?.branch &&
          currentBranch &&
          localDevServerState.git.branch !== currentBranch?.name
        ) {
          throw new Error("Branch mismatch");
        }

        // Our vite plugins inject this into the user's code. Our app provides the DOM
        // elements, and the user's app provides a separate copy of React
        const reactShimModule = await import(
          /* webpackIgnore: true */ reactShimPath
        );
        ReactShimRef.current = await reactShimModule.loadReact(
          document.getElementById("components-root"),
        );
        if (ReactShimRef.current && !ccReactInfoRegister.current) {
          // this is the first time we are loading a component
          // store the information needed by the `useSuperblocksContext` hook in the global object
          ccReactInfoRegister.current = {
            React: ReactShimRef.current.React,
            SuperblocksCCReactContext:
              // use the React from the shim to create the context
              ReactShimRef.current.React.createContext(undefined),
          };
        }

        if (!fullComponentPath) return;
        const componentModule = await import(
          /* webpackIgnore: true */
          fullComponentPath
        );
        if (!isStillMounted) return;

        ComponentRef.current = componentModule.default;
        renderImmediately(true);
        setErrorDisplay(null);

        // Setup hot reloading
        unregisterModuleReloadCallback =
          componentModule.registerModuleReloadCallback?.(
            fullComponentPath,
            (componentModule?: {
              default: React.ComponentType<React.PropsWithChildren<unknown>>;
            }) => {
              if (isStillMounted) {
                if (componentModule?.default) {
                  ComponentRef.current = componentModule.default;
                  renderImmediately(true);
                  setErrorDisplay(null);
                } else {
                  ComponentRef.current = null;
                  setErrorDisplay(
                    <div>Error updating {componentConfig.componentPath}</div>,
                  );
                }
              }
            },
          );
      } catch (e) {
        if (isStillMounted) {
          console.error(e);
          let errorDisplay: React.ReactNode;
          if (!isDevMode) {
            errorDisplay = (
              <div>
                <NotUploadedMessage name={componentConfig.name} />
                <br />
                <div>Component ID: {componentConfig.id}</div>
                <div>Filepath: {componentConfig.componentPath}</div>
              </div>
            );
          } else if (
            localDevServerState.status !== LocalDevServerStatus.CONNECTED
          ) {
            errorDisplay = DisconnectedMessage();
          } else if (
            localDevServerState.git?.active &&
            localDevServerState.git?.branch &&
            currentBranch &&
            localDevServerState.git.branch !== currentBranch?.name
          ) {
            errorDisplay = BranchMismatchMessage({
              uiBranch: currentBranch?.name,
              watchBranch: localDevServerState.git.branch,
            });
          } else {
            errorDisplay = (
              <div>
                <div>
                  {componentConfig.name} component not found at{" "}
                  {componentConfig.componentPath}. Please make sure the filepath
                  supplied in your config.ts file matches your component
                  filepath and that your component is compiling.
                </div>
                <br />
                <div>Component ID: {componentConfig.id}</div>
                <div>Filepath: {componentConfig.componentPath}</div>
              </div>
            );
          }
          setErrorDisplay(<div>{errorDisplay}</div>);
        }
      }
    })();

    return () => {
      isStillMounted = false;
      unregisterModuleReloadCallback?.();
    };
  }, [
    componentBaseUrl,
    componentConfig,
    fullComponentPath,
    isDevMode,
    localDevServerState,
    currentBranch,
    renderImmediately,
  ]);

  // Remount the error boundary on hot-reload
  // Including handling cases where component is undefined (if vite compilation fails)
  return (
    <div className={CContainer}>
      {ErrorDisplay}
      <div className={CContainer} ref={wrapperRef} />
    </div>
  );
}

export function CustomComponent({
  type,
  runEventHandlers,
  widgetId,
  properties,
}: {
  type: WidgetType;
  runEventHandlers: (actionPayload: RunWidgetEventHandlers) => void;
  widgetId: string;
  properties: Record<string, unknown>;
}) {
  const appSettings = useSelector(getApplicationSettings);
  const matchingComponent = useMemo(() => {
    const componentId = customComponentId(type);
    return Object.values(appSettings?.registeredComponents ?? {}).find(
      (component) => component.id === componentId,
    );
  }, [appSettings, type]);

  if (!appSettings || !matchingComponent) {
    return null;
  }
  return (
    <CustomComponentImpl
      appSettings={appSettings}
      componentConfig={matchingComponent}
      runEventHandlers={runEventHandlers}
      widgetId={widgetId}
      properties={properties}
    />
  );
}
