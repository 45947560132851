import { useContextSelector } from "use-context-selector";
import {
  SectionSizingContextProps,
  SectionSizingContext,
} from "./SectionSizingContext";

export const useSectionSizingContextSelector = <Selected>(
  selector: (context: SectionSizingContextProps) => Selected,
) => {
  return useContextSelector(SectionSizingContext, selector);
};
