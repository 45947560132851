import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import {
  WIDGET_PADDING,
  WIDGETS_WITH_PADDED_FOCUS,
  WIDGET_PADDED_FOCUS_PX,
} from "legacy/constants/WidgetConstants";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getSingleSelectedWidgetId } from "legacy/selectors/sagaSelectors";
import { useWidgetFocusHandlers } from "./useWidgetFocusHandlers";
import type { WidgetProps } from "../BaseWidget";
import type { StaticWidgetProps } from "../shared";

const FocusableWrapper = styled.div`
  display: contents;
  flex-direction: column;
  width: 100%;
  height: 100%;
  user-select: none;
  display: relative;
`;

// Widget Boundaries which is shown to indicate the boundaries of the widget
const WidgetBoundaries = styled.div`
  transform: translate3d(-${WIDGET_PADDING + 1}px, -${WIDGET_PADDING + 1}px, 0);
  z-index: 0;
  width: calc(100% + ${WIDGET_PADDING - 2}px);
  height: calc(100% + ${WIDGET_PADDING - 2}px);
  position: absolute;
  border: 1px dashed
    ${({ theme }) =>
      tinycolor(theme.colors.ACCENT_BLUE_500).setAlpha(0.5).toRgbString()};
  pointer-events: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  &[data-is-dragging-another="true"] {
    opacity: 1;
  }
  &[data-is-dragging-another="false"] {
    opacity: 0;
  }
`;

type FocusableComponentProps = {
  deselectOnSecondClick?: boolean;
  focusDisabled?: boolean;
  disabled?: boolean;
  requireDoubleClick?: boolean;
  children: any;
} & (StaticWidgetProps<unknown> | WidgetProps);

const FocusableComponent = (props: FocusableComponentProps) => {
  const selectedWidget = useSelector(getSingleSelectedWidgetId);
  const isSelected = selectedWidget === props.widgetId;

  // This state tells us whether a `ResizableComponent` is resizing
  const isResizing = useSelector(selectIsResizing);

  // This state tells us whether a `DraggableComponent` is dragging
  const isDragging = useSelector(selectIsDragging);

  const {
    handleMouseOut,
    wrapperRef,
    handleMouseOver,
    handleDoubleClick,
    handleClick,
    handleContextMenuClick,
  } = useWidgetFocusHandlers(props);

  const isActive = !props.disabled;
  const focusIsPadded = WIDGETS_WITH_PADDED_FOCUS.includes(props.type);

  const widgetBoundaries = (
    <WidgetBoundaries
      data-is-dragging-another={(isResizing || isDragging) && !isSelected}
    />
  );

  return (
    <FocusableWrapper
      onMouseOver={isActive ? handleMouseOver : undefined}
      onMouseOut={isActive ? handleMouseOut : undefined}
      onClick={isActive ? handleClick : undefined}
      onContextMenu={isActive ? handleContextMenuClick : undefined}
      onDoubleClick={isActive ? handleDoubleClick : undefined}
      ref={wrapperRef}
      style={{
        inset:
          focusIsPadded && !isSelected
            ? `-${WIDGET_PADDED_FOCUS_PX}px`
            : undefined,
      }}
    >
      {props.children}
      {isActive && widgetBoundaries}
    </FocusableWrapper>
  );
};

export default FocusableComponent;
