import * as React from "react";
import DynamicSVG from "components/ui/DynamicSVG";
import { CLASS_NAMES } from "legacy/themes/classnames";

interface IconComponetProps {
  widgetId: string;
  icon?: string;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  size: number;
}

const IconComponent = (props: IconComponetProps) => {
  const { onClick, color, icon, isLoading, size } = props;
  return (
    <div
      style={{
        cursor: onClick ? "pointer" : "inherit",
        color: color,
        overflow: "hidden",
      }}
      onClick={onClick}
      className={isLoading ? "bp5-skeleton" : CLASS_NAMES.BODY2}
    >
      {icon ? (
        <DynamicSVG
          // if not disabled, the click event for icons in grids isnt propagated correctly
          disablePointerEvents={true}
          iconName={icon ?? ""}
          size={size}
        />
      ) : null}
    </div>
  );
};

export default IconComponent;
