import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { usePointerDownOutside, usePrevious } from "hooks/ui";

export const NotifierTooltipBody = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.WHITE};
  background-color: ${(props) => props.theme.colors.GREY_800};
  padding: 2px 4px;
  border-radius: 2px;
  min-width: 100px;
  text-align: center;
`;

type NotifiterTooltipProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  tooltipStyle?: React.CSSProperties;
  messageHideDelayMs?: number;
};

const NotifierTooltip = ({
  isVisible,
  setIsVisible,
  children,
  containerStyle = {},
  tooltipStyle = {},
  messageHideDelayMs = 1500,
}: NotifiterTooltipProps) => {
  const timerRef = React.useRef<any>();
  const oldIsVisible = usePrevious(isVisible);

  useEffect(() => {
    if (isVisible && !oldIsVisible) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setIsVisible(false);
      }, messageHideDelayMs);
    }
  }, [isVisible, oldIsVisible, messageHideDelayMs, setIsVisible]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(() => {
    if (isVisible) {
      setIsVisible(false);
      clearTimeout(timerRef.current);
    }
  }, [setIsVisible, isVisible]);
  usePointerDownOutside({
    wrapperRefs: [tooltipRef],
    onClickOutside: handleClickOutside,
  });

  return (
    <div
      style={{
        ...containerStyle,
        pointerEvents: isVisible ? "auto" : "none",
      }}
      ref={tooltipRef}
    >
      <div
        style={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.3s, transform 0.3s",
          transform: isVisible ? "scale(1)" : "scale(0.5)",
        }}
      >
        <NotifierTooltipBody style={tooltipStyle}>
          {children}
        </NotifierTooltipBody>
      </div>
    </div>
  );
};

export default NotifierTooltip;
