import { Dimension } from "@superblocksteam/shared";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useCallbackAsRef, usePrevious } from "hooks/ui";
import {
  GridDefaults,
  WIDGET_PADDING,
  WidgetTypes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { gridUnitsToHeightPxWithoutWidgetPadding } from "legacy/utils/WidgetPropsUtils";
import { ScrollContainer } from "legacy/widgets/Shared/ScrollContainer";
import { type TextWidgetProps } from "legacy/widgets/TextWidget/index";
import {
  getApplicableMaxHeight,
  getApplicableMinHeight,
} from "legacy/widgets/base/sizing";
import { isFitContent } from "legacy/widgets/base/sizing";
import { getComponentDimensions } from "utils/size";
import {
  getApplicableMaxWidth,
  getApplicableMinWidth,
} from "../sizing/canvasWidthUtil";
import AutoSizeContainer, { FullHeightContainer } from "./AutoSizeContainer";
import type { WidgetProps } from "legacy/widgets";
import type { WidgetPropsRuntime } from "legacy/widgets/BaseWidget";

const heightInPixels = (height: Dimension<WidgetHeightModes>) => {
  return Dimension.toPx(height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value;
};

// Don't want to just inline .value , make it go through this formality for type safety
const pxToPixels = (width: Dimension<"px">) => {
  return width.value;
};

function componentWidthInPixels(widgetProps: WidgetPropsRuntime) {
  return getComponentDimensions(widgetProps).componentWidth;
}

type AutoHeightWrapperProps = {
  widgetProps: WidgetPropsRuntime;
  children: ReactNode;
  onUpdateDynamicHeight: (height: number) => void;
  onUpdateDynamicWidth: (width: number) => void;
  heightOverride: number | undefined;
  dataTest?: string;
  isSection?: boolean;
  roundHeight?: boolean;
};

const shouldAutoHeightScroll = (
  widgetProps: WidgetProps,
): [shouldScroll: boolean, use2DScroll: boolean] => {
  // don't scroll for fillParent because the child uses 100% height and should scroll itself if needed
  // fitParent may require scrolling because the inner component may need to stretch in order for the auto height container
  // to know how much space it requires, even when that value is greater than the widget's max height.
  if (
    (isFitContent(widgetProps.height.mode) && widgetProps.maxHeight) ||
    (isFitContent(widgetProps.width.mode) && widgetProps.maxWidth)
  ) {
    switch (widgetProps.type) {
      case WidgetTypes.TEXT_WIDGET:
        // TODO - we should unify shouldScroll and shouldScrollContents. No reason why we should have 2 different names
        if ((widgetProps as TextWidgetProps).shouldScroll) {
          return [true, widgetProps.maxWidth != null];
        }
        return [false, false];
      case WidgetTypes.IMAGE_WIDGET:
        return [false, false];
      default:
        if (widgetProps.shouldScrollContents ?? true) {
          return [true, false];
        }
        return [false, false];
    }
  }
  return [false, false];
};

// we don't want to apply this to sections because they control max height themselves
const LeafWidgetSizeWrapper = (props: {
  children: ReactNode;
  widgetProps: WidgetPropsRuntime;
}) => {
  const { children, widgetProps } = props;
  const minHeight = getApplicableMinHeight(widgetProps);
  const maxHeight = getApplicableMaxHeight(widgetProps);
  const minWidth = getApplicableMinWidth(widgetProps);
  const maxWidth = getApplicableMaxWidth(widgetProps);

  const minHeightPx = minHeight ? heightInPixels(minHeight) : undefined;
  const maxHeightPx = maxHeight ? heightInPixels(maxHeight) : undefined;
  const minWidthPx = minWidth ? pxToPixels(minWidth) : undefined;
  const maxWidthPx = maxWidth ? pxToPixels(maxWidth) : undefined;
  const [shouldWrapperScroll, use2dScroll] =
    shouldAutoHeightScroll(widgetProps);

  const style = useMemo(() => {
    if (
      minHeightPx !== undefined ||
      maxHeightPx !== undefined ||
      minWidthPx !== undefined ||
      maxWidthPx !== undefined
    ) {
      return {
        minHeight: minHeightPx,
        maxHeight: maxHeightPx,
        minWidth: minWidthPx,
        maxWidth: maxWidthPx,
        overflowY: shouldWrapperScroll ? "auto" : "hidden",
        overflowX: shouldWrapperScroll ? "auto" : "hidden",
        height: "100%",
        width: "100%",
      } as React.CSSProperties;
    }
    return {
      display: "contents",
    };
  }, [minHeightPx, maxHeightPx, shouldWrapperScroll, minWidthPx, maxWidthPx]);

  if (use2dScroll && shouldWrapperScroll) {
    return <ScrollContainer scroll={true}>{children}</ScrollContainer>;
  }

  return <div style={style}>{children}</div>;
};

function AutoSizeContainerWrapper({
  children,
  widgetProps,
  onUpdateDynamicHeight,
  onUpdateDynamicWidth,
  heightOverride,
  dataTest,
  isSection,
  roundHeight,
}: AutoHeightWrapperProps) {
  const { height, width, widgetLastChange } = widgetProps;
  const isAutoHeight = isFitContent(height.mode);
  const isAutoWidth = isFitContent(width.mode);

  const previousWidgetLastChange = usePrevious(widgetLastChange);

  const useHeightOverride = heightOverride !== undefined;

  const widgetHeightInPixels = heightInPixels(height);
  const minHeight = getApplicableMinHeight(widgetProps);
  const maxHeight = getApplicableMaxHeight(widgetProps);
  const minWidth = getApplicableMinWidth(widgetProps);
  const maxWidth = getApplicableMaxWidth(widgetProps);

  const widgetWidthInPixels = componentWidthInPixels(widgetProps);

  const lastHeightPx = useRef<number | undefined>(undefined);
  const lastWidthPx = useRef<number | undefined>(undefined);

  const onHeightUpdate = useCallback(
    (heightPx: number) => {
      let clampedHeight = heightPx;
      lastHeightPx.current = heightPx;
      if (minHeight) {
        const minHeightPx = gridUnitsToHeightPxWithoutWidgetPadding(
          Dimension.toGridUnit(
            minHeight,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).raw().value,
        );
        clampedHeight = Math.max(clampedHeight, minHeightPx);
      }
      if (maxHeight) {
        const maxHeightPx = gridUnitsToHeightPxWithoutWidgetPadding(
          Dimension.toGridUnit(
            maxHeight,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).raw().value,
        );
        clampedHeight = Math.min(clampedHeight, maxHeightPx);
      }

      if (roundHeight) {
        // Round height to the nearest grid unit
        clampedHeight = gridUnitsToHeightPxWithoutWidgetPadding(
          Dimension.toGridUnit(
            Dimension.px(clampedHeight),
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).roundUp().value,
        );
      }

      if (clampedHeight + WIDGET_PADDING * 2 !== widgetHeightInPixels) {
        onUpdateDynamicHeight(clampedHeight);
      }
    },
    [
      onUpdateDynamicHeight,
      minHeight,
      maxHeight,
      widgetHeightInPixels,
      roundHeight,
    ],
  );

  const onWidthUpdate = useCallback(
    (widthPx: number) => {
      let clampedWidth = widthPx;
      lastWidthPx.current = widthPx;

      if (minWidth) {
        // TODO do we need to account for the 2px padding for width?
        const minWidthPx = Dimension.toPx(
          minWidth,
          widgetProps.parentColumnSpace,
        ).value;
        clampedWidth = Math.max(clampedWidth, minWidthPx);
      }
      if (maxWidth) {
        // TODO do we need to account for the 2px padding for width?
        const maxWidthPx = Dimension.toPx(
          maxWidth,
          widgetProps.parentColumnSpace,
        ).value;
        clampedWidth = Math.min(clampedWidth, maxWidthPx);
      }
      if (clampedWidth !== widgetWidthInPixels) {
        onUpdateDynamicWidth(clampedWidth);
      }
    },
    [
      onUpdateDynamicWidth,
      widgetWidthInPixels,
      minWidth,
      maxWidth,
      widgetProps.parentColumnSpace,
    ],
  );

  const onHeightUpdateRef = useCallbackAsRef(onHeightUpdate);
  const onWidthUpdateRef = useCallbackAsRef(onWidthUpdate);

  useEffect(() => {
    // This checks if we should update the height if min or max changes
    // This is important because it could affect the clamped value
    if (!useHeightOverride && isAutoHeight) {
      onHeightUpdateRef(
        lastHeightPx.current ?? widgetHeightInPixels - WIDGET_PADDING * 2,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoHeight, minHeight, maxHeight, useHeightOverride]);

  // same as above but for min and max width
  useEffect(() => {
    if (isAutoWidth) {
      onWidthUpdateRef(
        lastWidthPx.current ?? widgetWidthInPixels - WIDGET_PADDING * 2,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minWidth, maxWidth, isAutoWidth]);

  const lastHeightOverride = useRef(undefined as number | undefined);

  useEffect(() => {
    // we only want to update the last height if the widget has actual
    // underlying changes, like dragging a widget in, pasting, undoing, etc
    if (
      previousWidgetLastChange &&
      widgetLastChange &&
      widgetLastChange.valueOf() !== previousWidgetLastChange.valueOf()
    ) {
      lastHeightOverride.current = undefined;
    }
  }, [previousWidgetLastChange, widgetLastChange]);

  useEffect(() => {
    if (
      useHeightOverride &&
      isAutoHeight &&
      lastHeightOverride.current !== heightOverride
    ) {
      const oldInnerHeight = widgetHeightInPixels - WIDGET_PADDING * 2;
      if (heightOverride !== oldInnerHeight) {
        onHeightUpdateRef(heightOverride);
        lastHeightOverride.current = heightOverride;
      }
    }
  }, [
    isAutoHeight,
    heightOverride,
    useHeightOverride,
    widgetHeightInPixels,
    onHeightUpdateRef,
  ]);

  const content =
    useHeightOverride && isAutoHeight ? (
      <FullHeightContainer
        data-test={dataTest}
        style={isSection ? { height: "auto" } : undefined}
      >
        {children}
      </FullHeightContainer>
    ) : (
      <AutoSizeContainer
        isAutoHeight={isAutoHeight}
        isAutoWidth={isAutoWidth}
        onHeightUpdate={onHeightUpdateRef}
        onWidthUpdate={onWidthUpdateRef}
        widgetHeightInPixels={widgetHeightInPixels}
        widgetWidthInPixels={widgetWidthInPixels}
        maxWidthInPixels={
          (widgetProps.maxWidth ?? widgetProps.internalMaxWidth)?.value
        }
        dataTest={dataTest}
        isSection={isSection}
      >
        {children}
      </AutoSizeContainer>
    );

  if (isSection) {
    return content;
  } else {
    return (
      <LeafWidgetSizeWrapper widgetProps={widgetProps}>
        {content}
      </LeafWidgetSizeWrapper>
    );
  }
}

export default AutoSizeContainerWrapper;
