import React from "react";
import styled from "styled-components";
import { ReactComponent as HideIcon } from "assets/icons/common/hide.svg";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { isWidgetOrChildSelected } from "legacy/selectors/sagaSelectors";
import VisibilityContainer from "legacy/widgets/base/VisibilityContainer";
import { useAppSelector } from "store/helpers";
import { colors } from "styles/colors";
import SelectionAndFocusWrapper from "./SelectionAndFocusWrapper";
import type { WidgetProps } from "legacy/widgets";

const IconWrapper = styled.div`
  padding: 0;
  padding-top: 1.5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  g {
    color: ${({ theme }) => theme.colors.GREY_200};
    opacity: 0.7;
  }
  svg {
    height: 9px;
    width: auto;
    position: relative;
  }
`;

const wrappingStyle = {
  backgroundColor: colors.GREY_100,
  height: `${GridDefaults.DEFAULT_GRID_ROW_HEIGHT}px`,
  borderRadius: 4,
};

type ExpandableVisibilityComponentProps = WidgetProps & {
  children: React.ReactChildren | React.ReactNode;
};

const ExpandableVisibilityComponent = ({
  children,
  ...widget
}: ExpandableVisibilityComponentProps) => {
  const isSelectedOrChildIsSelected = useAppSelector((state) =>
    isWidgetOrChildSelected(state, widget.widgetId),
  );

  if (isSelectedOrChildIsSelected) {
    return (
      <>
        <VisibilityContainer isVisible={false} widgetId={widget.widgetId}>
          {children}
        </VisibilityContainer>
        {/* TODO(Layouts): You can't resize here atm */}
        <SelectionAndFocusWrapper
          widgetType={widget.type}
          widgetId={widget.widgetId}
          widgetName={widget.widgetName}
          hasInvalidProps={false}
        />
      </>
    );
  } else {
    return (
      <>
        <div style={wrappingStyle}>
          <IconWrapper>
            <HideIcon />
          </IconWrapper>
        </div>
        <SelectionAndFocusWrapper
          widgetType={widget.type}
          widgetId={widget.widgetId}
          widgetName={widget.widgetName}
          hasInvalidProps={false}
        />
      </>
    );
  }
};

export default ExpandableVisibilityComponent;
