import { RadioButtonGroupOption } from "components/ui/RadioButtonGroup";
import type { Placement } from "@blueprintjs/core";

type PLACEMENT_OPTION = Omit<RadioButtonGroupOption, "value"> & {
  value: Placement;
};
export const VERTICAL_PLACEMENT_OPTIONS: PLACEMENT_OPTION[] = [
  {
    value: "bottom-start",
    icon: "LEFT_ALIGN",
    tooltip:
      "Left side of the menu is aligned with the left side of the button",
  },
  {
    value: "bottom",
    tooltip: "Center of the menu is aligned with the center of the button",
    icon: "CENTER_ALIGN",
  },
  {
    value: "bottom-end",
    tooltip:
      "Right side of the menu is aligned with the right side of the button",
    icon: "RIGHT_ALIGN",
  },
];

export const HORIZONTAL_PLACEMENT_OPTIONS: PLACEMENT_OPTION[] = [
  {
    value: "right-start",
    icon: "VERTICAL_TOP",
    tooltip: "Top of the menu is aligned with the top of the button",
  },
  {
    value: "right",
    icon: "VERTICAL_CENTER",
    tooltip: "Center of the menu is aligned with the center of the button",
  },
  {
    value: "right-end",
    tooltip: "Bottom of the menu is aligned with the bottom of the button",
    icon: "VERTICAL_BOTTOM",
  },
];
