import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import { type GridWidgetProps } from "./GridWidget";

const config: PropertyPaneCategoryConfig<GridWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "title",
      label: "Header",
      controlType: "INPUT_TEXT",
      inputType: "text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      helpText: "Takes in an array of objects to display items in the list.",
      propertyName: "gridData",
      label: "Data",
      controlType: "INPUT_TEXT",
      placeholderText: 'Enter [{ "col1": "val1" }]',
      inputType: "ARRAY",
      isBindProperty: true,
      isTriggerProperty: false,
      validation: VALIDATION_TYPES.TABLE_DATA,
      propertyCategory: PropsPanelCategory.Content,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    ...typographyProperties({
      defaultVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "headerProps",
      propertyNameForHumans: "Header",
      hiddenIfPropertyNameIsNullOrFalse: "title",
    }),
    {
      propertyName: "columnCount",
      label: "Columns",
      controlType: "DROP_DOWN",
      options: [
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
      validation: VALIDATION_TYPES.NUMBER,
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      helpText: "Selects the default selected cell",
      propertyName: "defaultSelectedCell",
      label: "Default selected cell",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter cell index",
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
    {
      propertyName: "isSearchable",
      label: "Search",
      controlType: "SWITCH",
      isBindProperty: false,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    {
      propertyName: "gridCellScrollable",
      helpText: "Controls the scrollability of each grid cell",
      label: "Scroll cell contents",
      controlType: "SWITCH",
      isBindProperty: false,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Layout,
    },
    sizeSection({ heightSupportsFitContent: true }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onCellClicked",
      "Triggers an action when a grid item is clicked",
    ),
  ]),
];

export default config;
