import { Dimension } from "@superblocksteam/shared";
import React, { memo, useCallback, useMemo } from "react";
import {
  GridDefaults,
  WidgetLabelPosition,
} from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { styleAsClass } from "styles/styleAsClass";
import { SwitchInput } from "../Shared/SwitchInput";
import {
  useMinHeightFromTypography,
  useStyleClassNames,
  useTypographyStyling,
} from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import { DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import type {
  SwitchComponentProps,
  SwitchComponentWithLayoutsManagedProps,
} from "./types";

const SwitchContainerClassName = styleAsClass`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  label {
    cursor: pointer;
  }
  overflow-y: hidden;
`;

const SwitchComponent = memo(
  ({
    label,
    labelPosition,
    isToggledOn,
    isDisabled,
    isRequired,
    onSwitchChange,
    isInvalid,
    isLoading,
    labelProps,
    minHeight,
  }: SwitchComponentProps) => {
    const toggleSwitch = useCallback(() => {
      if (isDisabled) return;

      onSwitchChange(!isToggledOn);
    }, [onSwitchChange, isToggledOn, isDisabled]);

    const asterisk = "* ";

    const labelClass = useStyleClassNames({
      textStyleVariant: labelProps.textStyleVariant,
      isLoading,
      isDisabled,
      type: "label",
    });

    const SwitchLabelComp = useMemo(() => {
      return (
        <label
          data-test="switch-label"
          onClick={toggleSwitch}
          className={labelClass}
          style={labelProps.style}
        >
          {isRequired && (
            <span className={CLASS_NAMES.ERROR_MODIFIER}>{asterisk}</span>
          )}
          {label}
        </label>
      );
    }, [isRequired, label, toggleSwitch, labelClass, labelProps.style]);

    return (
      <div
        className={SwitchContainerClassName}
        style={!label ? { gap: 0, minHeight } : { minHeight }}
        data-test={isToggledOn ? "switch-checked" : "switch-not-checked"}
      >
        {labelPosition === WidgetLabelPosition.LEFT ? (
          <>
            {SwitchLabelComp}
            <SwitchInput
              isValid={!isInvalid}
              onClick={toggleSwitch}
              isToggledOn={isToggledOn}
              isDisabled={isDisabled}
            />
          </>
        ) : (
          <>
            <SwitchInput
              isValid={!isInvalid}
              onClick={toggleSwitch}
              isToggledOn={isToggledOn}
              isDisabled={isDisabled}
            />
            {SwitchLabelComp}
          </>
        )}
      </div>
    );
  },
);

export const SwitchComponentWithLayoutManaged = (
  props: SwitchComponentWithLayoutsManagedProps,
) => {
  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  const minHeight = useMinHeightFromTypography({
    isAutoHeight: props.height.mode === "fitContent",
    isAutoWidth: props.width.mode === "fitContent",
    textStyleVariant: labelProps.textStyleVariant as unknown as any,
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  return (
    <SwitchComponent
      isRequired={!!props.isRequired}
      isToggledOn={!!props.isToggledOn}
      label={props.label}
      labelPosition={props.labelPosition ?? WidgetLabelPosition.RIGHT}
      widgetId={props.widgetId}
      key={props.widgetId}
      isDisabled={!!props.isDisabled}
      onSwitchChange={props.onSwitchChange}
      isLoading={props.isLoading}
      isInvalid={props.isRequired && !props.isToggledOn && props.isTouched}
      labelProps={labelProps}
      minHeight={minHeight}
    />
  );
};

export const estimateInitialSwitchWidgetHeightGU = (
  theme?: GeneratedTheme,
): number | undefined => {
  if (!theme?.typographies) {
    return undefined;
  }

  const textHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  return Dimension.toGridUnit(
    Dimension.px(textHeight),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};

SwitchComponent.displayName = "Switch";
