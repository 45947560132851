import { Dimension } from "@superblocksteam/shared";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import tinycolor from "tinycolor2";
import { ReactComponent as CrossIcon } from "assets/icons/common/cross.svg";
import { ReactComponent as DangerIcon } from "assets/icons/common/system-danger.svg";
import { ReactComponent as InfoIcon } from "assets/icons/common/system-info.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/common/system-success.svg";
import { ReactComponent as WarningIcon } from "assets/icons/common/warning.svg";
import { AutolinkedText } from "components/ui/AutolinkedText";
import { GridDefaults, VerticalAlign } from "legacy/constants/WidgetConstants";
import {
  selectGeneratedTheme,
  selectGeneratedThemeTypographies,
} from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { generateBorderStyleObject } from "legacy/widgets/base/generateBorderStyle";
import { useAppSelector } from "store/helpers";
import { getApplicableMaxHeight, getApplicableMinHeight } from "../base/sizing";
import { isFitContent } from "../base/sizing";
import { useTypographyStyling } from "../typographyHooks";
import {
  getIconSizeBasedOnFontSize,
  getFontSizeInPxFromTextStyle,
} from "../typographyUtils";
import {
  DEFAULT_CALLOUT_WIDGET_CTA_TEXT_STYLE_VARIANT,
  DEFAULT_CALLOUT_WIDGET_DESC_TEXT_STYLE_VARIANT,
  DEFAULT_CALLOUT_WIDGET_TITLE_TEXT_STYLE_VARIANT,
  CalloutType,
} from "./Constants";
import { getColorByCalloutType } from "./utils";
import type {
  CalloutComponentProps,
  CalloutComponentWithLayoutManagedProps,
} from "./types";

const getBlendedColor = (color: string, blendColor: string, alpha = 1) => {
  return tinycolor.mix(blendColor, color, alpha * 100).toHexString();
};

const VERTICAL_PADDING = 10;

const Cta = styled.div<{
  color: string;
}>`
    color: ${({ color }) => color};
    max-width: 200px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: ${({ color }) =>
        tinycolor(color).darken(15).toString()} !important;
    }
    &:active {
      color: ${({ color }) =>
        tinycolor(color).darken(25).toString()} !important;
    }
  }
`;

const Wrapper = styled.div<{
  color: string;
  blendColor: string;
  verticalAlign: VerticalAlign;
}>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  gap: 16px;
  overflow: hidden;
  overflow-y: auto;

  border-color: ${({ color, blendColor }) =>
    getBlendedColor(color, blendColor, 0.15)};
  background-color: ${({ color, blendColor }) =>
    getBlendedColor(color, blendColor, 0.15)};
  color: ${({ color }) => color};

  .content-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    ${({ verticalAlign }) =>
      verticalAlign === VerticalAlign.TOP &&
      css`
        align-self: flex-start;
      `}
    ${({ verticalAlign }) =>
      verticalAlign === VerticalAlign.CENTER &&
      css`
        align-self: center;
      `}
    ${({ verticalAlign }) =>
      verticalAlign === VerticalAlign.BOTTOM &&
      css`
        align-self: flex-end;
      `}
  
    .text-content-wrapper {
      align-self: center;
      gap: 2px;
      display: flex;
      flex-direction: column;
    }

    .icon {
      padding-top: 2px;
      color: ${({ color }) => color};
    }
  }

  .dismiss {
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: ${({ color, blendColor }) =>
      getBlendedColor(color, blendColor, 0.5)};
    &:hover {
      color: ${({ color }) => color};
    }
    &:active {
      color: ${({ color }) => tinycolor(color).darken(15).toString()};
    }
  }

  .spacer {
    height: 100%;
    width: 0px;
    border-right: 1px solid
      ${({ color, blendColor }) => getBlendedColor(color, blendColor, 0.5)};
  }
`;

const getIconByCalloutType = (type: CalloutType, size: number) => {
  switch (type) {
    case CalloutType.INFO:
      return <InfoIcon height={size} width={size} />;
    case CalloutType.SUCCESS:
      return <SuccessIcon height={size} width={size} />;
    case CalloutType.WARNING:
      return <WarningIcon height={size} width={size} />;
    case CalloutType.DANGER:
      return <DangerIcon height={size} width={size} />;
    default:
      return <InfoIcon height={size} width={size} />;
  }
};

function CalloutComponent(props: CalloutComponentProps) {
  const {
    title,
    description,
    isDismissible,
    ctaText,
    onCtaClick,
    onDismiss,
    calloutType = CalloutType.INFO,
    verticalAlign = VerticalAlign.TOP,
    minHeight,
    maxHeight,
    isAutoHeight,
    backgroundColor,
    border,
    borderRadius,
  } = props;
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);
  const generatedTheme = useSelector(selectGeneratedTheme);
  const blendColor = backgroundColor ?? generatedTheme.colors.neutral;

  const maxHeightPx = useMemo(() => {
    if (maxHeight) {
      return Dimension.toPx(maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
        .value;
    }
    return undefined;
  }, [maxHeight]);

  const minHeightPx = useMemo(() => {
    if (minHeight) {
      return Dimension.toPx(minHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
        .value;
    }
    return undefined;
  }, [minHeight]);

  const calloutContentsRef = useRef<HTMLDivElement>(null);
  const [calloutHeight, setCalloutHeight] = useState<number | null>(null);

  const calculateCalloutHeight = useCallback(() => {
    if (!calloutContentsRef.current || !isAutoHeight) return;
    const border = 4;
    const padding = VERTICAL_PADDING * 2;
    let height = padding + calloutContentsRef.current.offsetHeight;

    let wasClamped = false;
    if (maxHeightPx != null && height > maxHeightPx) {
      height = maxHeightPx;
      wasClamped = true;
    }
    if (minHeightPx != null && height < minHeightPx) {
      height = minHeightPx;
      wasClamped = true;
    }
    if (!wasClamped) {
      const remainder =
        (height + border) % GridDefaults.DEFAULT_GRID_ROW_HEIGHT;
      if (remainder !== 0) {
        // round UP to the nearest grid unit when using auto height
        height += GridDefaults.DEFAULT_GRID_ROW_HEIGHT - remainder;
      }
    }
    setCalloutHeight(height);
  }, [minHeightPx, maxHeightPx, isAutoHeight]);

  // add a resize observer to the calloutContentsRef to update the height when the content changes
  useEffect(() => {
    // Run on mount and adjust on nav resize
    calculateCalloutHeight();
    const debouncedAdjustNavItems = debounce(calculateCalloutHeight, 1);
    const observer = new ResizeObserver(() => {
      debouncedAdjustNavItems();
    });
    if (calloutContentsRef.current) {
      observer.observe(calloutContentsRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [calculateCalloutHeight]);

  useEffect(() => {
    calculateCalloutHeight();
  }, [description, title, ctaText, calculateCalloutHeight]);

  const style = useMemo(() => {
    let outerStyle = isAutoHeight
      ? ({
          minHeight: minHeightPx != null ? `${minHeightPx}px` : undefined,
          maxHeight: maxHeightPx != null ? `${maxHeightPx}px` : undefined,
          height: calloutHeight != null ? `${calloutHeight}px` : undefined,
        } as React.CSSProperties)
      : {
          height: "100%",
        };

    if (border || borderRadius) {
      outerStyle = {
        ...outerStyle,
        ...generateBorderStyleObject({
          border,
          borderRadius,
        }),
      };
    }
    return outerStyle;
  }, [
    minHeightPx,
    maxHeightPx,
    isAutoHeight,
    calloutHeight,
    border,
    borderRadius,
  ]);

  const titleClassName = getTextCssClassFromVariant(
    props.titleProps.textStyleVariant,
  );

  const descriptionClassName = getTextCssClassFromVariant(
    props.descriptionProps.textStyleVariant,
  );

  const ctaClassName = getTextCssClassFromVariant(
    props.ctaProps.textStyleVariant,
  );

  const iconSize = useMemo(() => {
    return getIconSizeBasedOnFontSize(
      getFontSizeInPxFromTextStyle({
        nestedProps: props.titleProps.style,
        typographies: themeTypographies,
        textStyleVariant: props.titleProps.textStyleVariant,
        defaultTextStyleVariant:
          DEFAULT_CALLOUT_WIDGET_TITLE_TEXT_STYLE_VARIANT,
      }),
    );
  }, [props.titleProps, themeTypographies]);

  const calloutColor =
    backgroundColor ?? getColorByCalloutType(generatedTheme, calloutType);

  return (
    <Wrapper
      color={calloutColor}
      blendColor={blendColor}
      verticalAlign={verticalAlign}
      className={CLASS_NAMES.ROUNDED_CONTAINER}
      style={style}
    >
      {/* this div's height will be observed when using fitContent and will force the Wrapper to grow/shrink 
      as needed */}
      <div className="content-wrapper" ref={calloutContentsRef}>
        <div
          className="icon"
          style={
            description ? { alignSelf: "flex-start" } : { alignSelf: "center" }
          }
        >
          {getIconByCalloutType(calloutType, iconSize)}
        </div>
        <div className="text-content-wrapper">
          {title && (
            <div className={titleClassName} style={props.titleProps.style}>
              <AutolinkedText text={title} target="_parent" />
            </div>
          )}
          {description && (
            <div
              className={descriptionClassName}
              style={props.descriptionProps.style}
            >
              <AutolinkedText text={description} target="_parent" />
            </div>
          )}
        </div>
      </div>

      {ctaText && <div className="spacer" />}

      {ctaText && (
        <Cta
          color={props.ctaProps.style?.color ?? calloutColor}
          className={ctaClassName}
          onClick={onCtaClick}
          style={props.ctaProps.style}
        >
          {ctaText}
        </Cta>
      )}

      {isDismissible && (
        <div className="dismiss" onClick={onDismiss}>
          <CrossIcon />
        </div>
      )}
    </Wrapper>
  );
}

export const CalloutComponentWithLayoutManaged: React.FC<
  CalloutComponentWithLayoutManagedProps
> = (props) => {
  const minHeight = getApplicableMinHeight(props);
  const maxHeight = getApplicableMaxHeight(props);
  const isAutoHeight = isFitContent(props.height.mode);
  const generatedTheme = useSelector(selectGeneratedTheme);

  const titleProps = useTypographyStyling({
    textStyle: props.titleProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CALLOUT_WIDGET_TITLE_TEXT_STYLE_VARIANT,
  });

  const descriptionProps = useTypographyStyling({
    textStyle: props.descriptionProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CALLOUT_WIDGET_DESC_TEXT_STYLE_VARIANT,
  });

  // We ignore the typography's own color.
  // We use either the override value or the color by callout type
  const priorityStyle = useMemo(() => {
    return {
      color:
        props.ctaProps?.textStyle?.textColor?.default ??
        getColorByCalloutType(generatedTheme, props.calloutType),
    };
  }, [
    props.ctaProps?.textStyle?.textColor?.default,
    generatedTheme,
    props.calloutType,
  ]);

  const ctaProps = useTypographyStyling({
    textStyle: props.ctaProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CALLOUT_WIDGET_CTA_TEXT_STYLE_VARIANT,
    skipTextStyleColorDefaultOverwrite: true,
    priorityStyle,
  });

  return (
    <CalloutComponent
      title={props.title}
      description={props.description}
      ctaText={props.ctaText}
      calloutType={props.calloutType}
      isDismissible={props.isDismissible}
      onCtaClick={props.onCtaClick}
      onDismiss={props.onDismiss}
      verticalAlign={props.verticalAlign}
      minHeight={minHeight}
      maxHeight={maxHeight}
      isAutoHeight={isAutoHeight}
      titleProps={titleProps}
      descriptionProps={descriptionProps}
      ctaProps={ctaProps}
      backgroundColor={props.backgroundColor}
      border={props.border}
      borderRadius={props.borderRadius}
    />
  );
};
