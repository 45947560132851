import React from "react";
import styled, { css } from "styled-components";
import { Layers } from "legacy/constants/Layers";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import type { WidgetPosition } from "@superblocksteam/shared";

const EDGE_RESIZE_HANDLE_WIDTH = 12;
const CORNER_RESIZE_HANDLE_WIDTH = 9;

const VISIBLE_HANDLE_SIZE = 9;
const INSET_DISTANCE = 5.5;
const EdgeHandleStyles = css<{ hasInvalidProps: boolean; invisible?: boolean }>`
  position: absolute;
  z-index: ${Layers.widgetResizer};
  width: ${EDGE_RESIZE_HANDLE_WIDTH}px;
  height: ${EDGE_RESIZE_HANDLE_WIDTH}px;
  &::after {
    opacity: ${({ invisible }) => (invisible ? 0 : 1)};
  }
`;

// This pseudo element is the visible circular handle itself
const VisibleHandleStyles = css<HandleStylesProps>`
  position: absolute;
  content: "";
  width: ${VISIBLE_HANDLE_SIZE}px;
  height: ${VISIBLE_HANDLE_SIZE}px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.WHITE};
  box-sizing: border-box;
  border: 1px solid
    ${({ theme, hasInvalidProps }) =>
      hasInvalidProps
        ? theme.legacy.colors.error
        : theme.colors.ACCENT_BLUE_NEW_DARKER};
`;

// This pseudo element is the visible rectangle handle itself for left and right handles
const VISIBLE_REC_HANDLE_SIZE = 16;
const VisibleXRectangleHandleStyles = css<{
  hasInvalidProps: boolean;
}>`
  position: absolute;
  content: "";
  width: 6px;
  height: ${VISIBLE_REC_HANDLE_SIZE}px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  background: ${({ theme }) => theme.colors.WHITE};
  box-sizing: border-box;
  transition: height 0.2s ease-in-out;
`;

// This pseudo element is the visible rectangle handle itself for top and bottom handles
const VisibleYRectangleHandleStyles = css<{
  hasInvalidProps: boolean;
}>`
  position: absolute;
  content: "";
  width: ${VISIBLE_REC_HANDLE_SIZE}px;
  height: 6px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  background: ${({ theme }) => theme.colors.WHITE};
  box-sizing: border-box;
  transition: width 0.2s ease-in-out;
`;

const VerticalHandleStyles = css`
  ${EdgeHandleStyles}
  top: -${WIDGET_PADDING - 1}px;
  height: calc(100% + ${2 * WIDGET_PADDING - 1}px);
  cursor: col-resize;
`;

const HorizontalHandleStyles = css`
  ${EdgeHandleStyles}
  left: -${WIDGET_PADDING}px;
  width: calc(100% + ${2 * WIDGET_PADDING}px);
  cursor: row-resize;
`;

export interface HandleStylesProps {
  isValid: boolean;
  hasInvalidProps: boolean;
  heightMode?: WidgetPosition["height"]["mode"];
  // The next props are based on if the widget is on the edge of the canvas and hard to resize
  insetLeft?: boolean;
  insetRight?: boolean;
  insetTop?: boolean;
  insetBottom?: boolean;
  invisible?: boolean;
  children?: React.ReactNode;
}

export const LeftHandleStyles = styled.div<HandleStylesProps>`
  ${VerticalHandleStyles}
  left: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;
`;

export const RightHandleStyles = styled.div<HandleStylesProps>`
  ${VerticalHandleStyles};
  right: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;
`;

export const RightHandleWithRectangleStyles = styled.div<HandleStylesProps>`
  ${VerticalHandleStyles};
  right: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;

  &::after {
    ${VisibleXRectangleHandleStyles};
    top: calc(50% - ${VISIBLE_REC_HANDLE_SIZE / 2}px);
    right: ${({ insetRight }) =>
      insetRight ? `calc(50% + 1px)` : "calc(50% - 3px)"};

    border-top-right-radius: ${({ insetRight }) => (insetRight ? "0" : "3px")};
    border-bottom-right-radius: ${({ insetRight }) =>
      insetRight ? "0" : "3px"};
  }
`;

export const LeftHandleWithRectangleStyles = styled.div<HandleStylesProps>`
  ${VerticalHandleStyles};
  left: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;

  &::after {
    ${VisibleXRectangleHandleStyles};
    top: calc(50% - ${VISIBLE_REC_HANDLE_SIZE / 2}px);
    left: ${({ insetLeft }) =>
      insetLeft ? `calc(50% + 1px)` : "calc(50% - 3px)"};

    border-top-left-radius: ${({ insetLeft }) => (insetLeft ? "0" : "3px")};
    border-bottom-left-radius: ${({ insetLeft }) => (insetLeft ? "0" : "3px")};
  }
`;

export const TopHandleStyles = styled.div<HandleStylesProps>`
  ${HorizontalHandleStyles};
  top: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;
`;

const BottomHandleStyles = styled.div<HandleStylesProps>`
  ${HorizontalHandleStyles};
  bottom: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;
`;

export const TopHandleWithRectangleStyles = styled.div<HandleStylesProps>`
  ${HorizontalHandleStyles};
  top: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;

  &::after {
    ${VisibleYRectangleHandleStyles};
    left: calc(50% - ${VISIBLE_REC_HANDLE_SIZE / 2}px);
    top: ${({ insetTop }) =>
      insetTop ? `calc(50% + 1px)` : "calc(50% - 3px)"};

    border-top-left-radius: ${({ insetTop }) => (insetTop ? "0" : "3px")};
    border-top-right-radius: ${({ insetTop }) => (insetTop ? "0" : "3px")};
  }
`;

export const BottomHandleWithRectangleStyles = styled.div<HandleStylesProps>`
  ${HorizontalHandleStyles};
  bottom: ${-EDGE_RESIZE_HANDLE_WIDTH / 2 - WIDGET_PADDING}px;

  &::after {
    ${VisibleYRectangleHandleStyles};
    left: calc(50% - ${VISIBLE_REC_HANDLE_SIZE / 2}px);
    bottom: ${({ insetBottom }) =>
      insetBottom ? `calc(50% + 1px)` : "calc(50% - 3px)"};

    border-bottom-left-radius: ${({ insetBottom }) =>
      insetBottom ? "0" : "3px"};
    border-bottom-right-radius: ${({ insetBottom }) =>
      insetBottom ? "0" : "3px"};
  }
`;

const CornerHandleStyles = css`
  position: absolute;
  z-index: ${Layers.widgetResizer};
  width: ${CORNER_RESIZE_HANDLE_WIDTH}px;
  height: ${CORNER_RESIZE_HANDLE_WIDTH}px;
`;

export const BottomRightHandleStyles = styled.div<HandleStylesProps>`
  ${CornerHandleStyles};
  bottom: ${({ insetBottom }) =>
    insetBottom ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  right: ${({ insetRight }) =>
    insetRight ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  cursor: se-resize;

  &::after {
    ${VisibleHandleStyles}

    left: 50%;
    top: 50%;
    margin-left: ${({ insetRight }) =>
      insetRight ? `-${INSET_DISTANCE}px` : "-50%"};
    margin-top: ${({ insetBottom }) =>
      insetBottom ? `-${INSET_DISTANCE}px` : "-50%"};
  }
`;

export const BottomLeftHandleStyles = styled.div<HandleStylesProps>`
  ${CornerHandleStyles};
  left: ${({ insetLeft }) =>
    insetLeft ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  bottom: ${({ insetBottom }) =>
    insetBottom ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  cursor: sw-resize;

  &::after {
    ${VisibleHandleStyles}
    left: 50%;
    top: 50%;
    margin-left: ${({ insetLeft }) =>
      insetLeft ? `-${VISIBLE_HANDLE_SIZE - INSET_DISTANCE}px` : "-50%"};
    margin-top: ${({ insetBottom }) =>
      insetBottom ? `-${INSET_DISTANCE}px` : "-50%"};
  }
`;

export const TopLeftHandleStyles = styled.div<HandleStylesProps>`
  ${CornerHandleStyles};
  left: ${({ insetLeft }) =>
    insetLeft ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  top: ${({ insetTop }) =>
    insetTop ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  cursor: nw-resize;

  &::after {
    ${VisibleHandleStyles}
    left: 50%;
    top: 50%;
    margin-left: ${({ insetLeft }) =>
      insetLeft ? `-${VISIBLE_HANDLE_SIZE - INSET_DISTANCE}px` : "-50%"};
    margin-top: ${({ insetTop }) =>
      insetTop ? `-${VISIBLE_HANDLE_SIZE - INSET_DISTANCE}px` : "-50%"};
  }
`;

export const TopRightHandleStyles = styled.div<HandleStylesProps>`
  ${CornerHandleStyles};
  right: ${({ insetRight }) =>
    insetRight ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  top: ${({ insetTop }) =>
    insetTop ? 0 : `-${CORNER_RESIZE_HANDLE_WIDTH / 2}px`};
  cursor: ne-resize;

  &::after {
    ${VisibleHandleStyles}
    left: 50%;
    top: 50%;
    margin-left: ${({ insetRight }) =>
      insetRight ? `-${INSET_DISTANCE}px` : "-50%"};
    margin-top: ${({ insetTop }) =>
      insetTop ? `-${VISIBLE_HANDLE_SIZE - INSET_DISTANCE}px` : "-50%"};
  }
`;

export const BottomSwapHandleStyles = (props: HandleStylesProps) => {
  const { heightMode, children, ...rest } = props;
  return (
    <BottomHandleStyles
      {...rest}
      style={{
        cursor: "row-resize",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </BottomHandleStyles>
  );
};
