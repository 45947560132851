import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

export const setCanvasSelectionStateAction = (
  start: boolean,
  widgetId: string,
  forceUnselectAll?: boolean,
) => {
  return {
    type: start
      ? ReduxActionTypes.START_CANVAS_SELECTION
      : ReduxActionTypes.STOP_CANVAS_SELECTION,
    payload: {
      widgetId,
      forceUnselectAll,
    },
  };
};
