import {
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared";
import { useMemo, type CSSProperties } from "react";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { useAppSelector } from "store/helpers";
import { generateBorderStyleObject } from "./generateBorderStyle";
import { generatePaddingStyleObject } from "./generatePaddingStyle";

type StyleProps = {
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
  backgroundColor?: string;
  padding?: Padding;
  fallbackBorderColor?: string;
};

const computeWrapperStyle = (
  props: StyleProps,
): {
  borderStyle: CSSProperties;
  backgroundStyle: CSSProperties;
  paddingStyle: CSSProperties;
  allStyles: CSSProperties;
} => {
  const borderStyle =
    generateBorderStyleObject({
      border: props.border,
      borderRadius: props.borderRadius,
      fallbackBorderColor: props.fallbackBorderColor,
    }) ?? {};

  const paddingStyle = generatePaddingStyleObject(props.padding) ?? {};

  const backgroundStyle: CSSProperties = {};
  if (props.backgroundColor) {
    // note, important that it's background rather than backgroundColor. This needs to match the defaultAppBg assigned in ModalComponent.tsx
    backgroundStyle.background = props.backgroundColor;
  }
  return {
    borderStyle,
    backgroundStyle,
    paddingStyle,
    allStyles: { ...borderStyle, ...backgroundStyle, ...paddingStyle },
  };
};

export type ComputeStyleProps = {
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
  backgroundColor?: string;
  padding?: Padding;
  fallbackBorderColorThemeKey?: keyof GeneratedTheme["colors"];
  fallbackBackgroundColorThemeKey?: keyof GeneratedTheme["colors"];
};

const resolveDefaultColor = (
  theme: GeneratedTheme,
  key: undefined | keyof GeneratedTheme["colors"],
  fallbackColor: string,
): string => {
  if (key) {
    const value = theme.colors[key];
    if (typeof value === "string") {
      return value;
    }
  }
  return fallbackColor;
};

export const useComputeWidgetStyles = (props: ComputeStyleProps) => {
  const theme = useAppSelector(selectGeneratedTheme);

  const {
    border,
    borderRadius,
    backgroundColor,
    padding,
    fallbackBorderColorThemeKey,
    fallbackBackgroundColorThemeKey,
  } = props;

  const fallbackBackgroundColor = resolveDefaultColor(
    theme,
    fallbackBackgroundColorThemeKey,
    theme.colors.neutral,
  );

  const fallbackBorderColor = resolveDefaultColor(
    theme,
    fallbackBorderColorThemeKey,
    theme.colors.neutral100,
  );

  return useMemo(() => {
    return computeWrapperStyle({
      borderRadius,
      border,
      fallbackBorderColor,
      backgroundColor: backgroundColor ?? fallbackBackgroundColor,
      padding,
    });
  }, [
    border,
    borderRadius,
    fallbackBorderColor,
    padding,
    fallbackBackgroundColor,
    backgroundColor,
  ]);
};
