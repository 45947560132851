import React from "react";
import { useSelector } from "react-redux";
import { getStickySectionTopValuePx } from "legacy/selectors/editorSelectors";
import { type WidgetPropsRuntime } from "legacy/widgets/BaseWidget";
import type { AppState } from "store/types";

type StickySectionComponentProps = WidgetPropsRuntime & {
  children: React.ReactNode | React.ReactNode[];
};

const StickySectionComponent: React.FC<StickySectionComponentProps> = (
  props,
) => {
  const { children, widgetId } = props;

  const topValuePx = useSelector((state: AppState) => {
    return getStickySectionTopValuePx(state, widgetId);
  });

  return (
    <div
      data-test="sticky-section"
      data-superblocks="sticky-section"
      style={{
        position: "sticky",
        top: `${String(topValuePx)}px`,
      }}
    >
      {children}
    </div>
  );
};

export default StickySectionComponent;
