import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as NoFileIcon } from "assets/icons/common/no-file.svg";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { ComponentProps } from "../../components/designSystems/default/BaseComponent";

const Wrapper = styled.div`
  overflow: hidden;

  iframe {
    border: 0;
    border-radius: inherit;
    width: 100%;
    height: 100%;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .ant-typography {
    max-width: 150px;
    margin-top: 12px;
    text-align: center;
  }

  svg {
    width: 48px;
    height: 48px;
  }
`;

const IFrameComponent = ({
  source,
  srcDoc,
  widgetId,
  isLoading,
  onMessageReceived,
}: IFrameComponentProps) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const iframeWindow =
        frameRef.current?.contentWindow ||
        frameRef.current?.contentDocument?.defaultView;
      // Accept messages only from the current iframe
      if (event.source !== iframeWindow) return;
      onMessageReceived(event);
    };
    // add a listener
    window.addEventListener("message", handler, false);
    // clean up
    return () => window.removeEventListener("message", handler, false);
  }, [onMessageReceived]);

  return (
    <Wrapper
      className={isLoading ? "bp5-skeleton" : CLASS_NAMES.DEFAULT_CONTAINER}
    >
      {srcDoc ? (
        <iframe
          title={`iframe-${widgetId}`}
          ref={frameRef}
          allow="camera; microphone"
          sandbox="allow-scripts allow-same-origin allow-forms allow-downloads allow-popups allow-modals"
          src={source}
          srcDoc={srcDoc}
        />
      ) : source ? (
        <iframe
          title={`iframe-${widgetId}`}
          ref={frameRef}
          allow="camera; microphone"
          sandbox="allow-scripts allow-same-origin allow-forms allow-downloads allow-popups allow-modals"
          src={source}
        />
      ) : (
        <EmptyWrapper>
          <NoFileIcon />
          <div className={CLASS_NAMES.BODY2}>
            At least either of Source URL or srcDoc is required
          </div>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};

interface IFrameComponentProps extends ComponentProps {
  source: string;
  srcDoc?: string;
  isLoading: boolean;
  onMessageReceived: (currentMessage: MessageEvent) => void;
}

export default IFrameComponent;
