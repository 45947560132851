import { Dimension } from "@superblocksteam/shared";
import { useCallback, useRef, useState } from "react";
import {
  GridDefaults,
  WidgetHeightConstraintType,
} from "legacy/constants/WidgetConstants";
import type { WidgetProps } from "legacy/widgets/BaseWidget";
import type { StaticWidgetProps } from "legacy/widgets/shared";

const toPx = (
  dimension: Dimension<"gridUnit" | "fitContent" | "fillParent" | "px">,
) => {
  return Dimension.toPx(dimension, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value;
};

const ZERO_HEIGHT = Dimension.gridUnit(0);

const getResizingConstraintHeightPx = (
  constraintType: WidgetHeightConstraintType,
  height: WidgetProps["height"],
  minHeight: WidgetProps["minHeight"],
  maxHeight: WidgetProps["maxHeight"],
): number => {
  let resizingHeight = toPx(height);

  if (constraintType === "minHeight") {
    resizingHeight = toPx(minHeight ?? Dimension.px(0));
  } else if (constraintType === "maxHeight") {
    resizingHeight = toPx(maxHeight ?? Dimension.px(0));
  }

  return resizingHeight;
};

const getMinHeightResizerHeight = (params: {
  useStateHeight: WidgetHeightConstraintType | false;
  resizingConstraintHeightPx: number;
  minHeight: WidgetProps["minHeight"];
}): Dimension<"px" | "gridUnit"> => {
  const { useStateHeight, resizingConstraintHeightPx, minHeight } = params;
  if (useStateHeight === "minHeight") {
    return Dimension.px(resizingConstraintHeightPx);
  } else if (useStateHeight === "maxHeight" && minHeight) {
    return Dimension.px(Math.min(resizingConstraintHeightPx, toPx(minHeight)));
  }

  return minHeight || ZERO_HEIGHT;
};

const getMaxHeightResizerHeight = (params: {
  useStateHeight: WidgetHeightConstraintType | false;
  resizingConstraintHeightPx: number;
  maxHeight: WidgetProps["maxHeight"];
}): Dimension<"px" | "gridUnit"> => {
  const { useStateHeight, resizingConstraintHeightPx, maxHeight } = params;
  if (useStateHeight === "maxHeight") {
    return Dimension.px(resizingConstraintHeightPx);
  } else if (useStateHeight === "minHeight" && maxHeight) {
    return Dimension.px(Math.max(resizingConstraintHeightPx, toPx(maxHeight)));
  }

  return maxHeight || ZERO_HEIGHT;
};

export const useWidgetConstraintResizers = (
  widget: StaticWidgetProps<unknown>,
) => {
  const [isResizingConstraint, setIsResizingConstraintState] = useState(false);
  const [useStateHeight, setUseStateHeight] = useState<
    WidgetHeightConstraintType | false
  >(false);

  const [resizingConstraintHeightPx, setResizingConstraintHeightPx] =
    useState<number>(0);

  const resizerMaxHeight = getMaxHeightResizerHeight({
    useStateHeight,
    resizingConstraintHeightPx,
    maxHeight: widget.maxHeight,
  });

  const resizerMinHeight = getMinHeightResizerHeight({
    useStateHeight,
    resizingConstraintHeightPx,
    minHeight: widget.minHeight,
  });

  // TODO layouts: This override exists so that the section doesn't snap back to its original position
  // briefly before redux catches up. TODO the proper way to do this is to create a special saga for just this component that we can await on
  const postResizeTimeout = useRef<number | undefined>(undefined);

  const setIsResizingConstraint = useCallback(
    (newValue: boolean, constraintType: WidgetHeightConstraintType) => {
      if (newValue === true) {
        postResizeTimeout.current && clearTimeout(postResizeTimeout.current);
        setUseStateHeight(constraintType);
        setResizingConstraintHeightPx(
          getResizingConstraintHeightPx(
            constraintType,
            widget.height,
            widget.minHeight,
            widget.maxHeight,
          ),
        );
      } else {
        postResizeTimeout.current = window.setTimeout(() => {
          setUseStateHeight(false);
        }, 150);
      }
      setIsResizingConstraintState(newValue);
    },
    [
      widget.height,
      widget.minHeight,
      widget.maxHeight,
      setResizingConstraintHeightPx,
      setIsResizingConstraintState,
    ],
  );

  return {
    isResizingConstraint,
    setIsResizingConstraint,
    resizerMaxHeight,
    resizerMinHeight,
    resizingConstraintHeightPx,
    useStateHeight,
    setResizingConstraintHeightPx,
  };
};
