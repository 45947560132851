import { Padding } from "@superblocksteam/shared";
import styled from "styled-components";

export const generateMaskSize = (padding?: Padding) => {
  const top = `${padding?.top?.value ?? 0}px`;
  const right = `${padding?.right?.value ?? 0}px`;
  const bottom = `${padding?.bottom?.value ?? 0}px`;
  const left = `${padding?.left?.value ?? 0}px`;
  return `linear-gradient(to bottom, black ${top}, transparent ${top}),
          linear-gradient(to right, black ${left}, transparent ${left}),
          linear-gradient(to top, black ${bottom}, transparent ${bottom}),
          linear-gradient(to left, black ${right}, transparent ${right})
  `;
};

export const StyledBorder = styled.span`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  pointer-events: none;
  background-repeat: repeat;
`;
