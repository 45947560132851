import React from "react";

import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";
import { useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import TabsComponent from "./TabsComponent";
import { DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT } from "./constants";
import { TabsComponentPropsWithLayoutsManagedProps } from "./types";

export const TabsComponentWithLayoutManaged = (
  props: TabsComponentPropsWithLayoutsManagedProps,
) => {
  const headerProps = useTypographyStyling({
    textStyle: props.headerProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const typographies = useAppSelector(selectGeneratedThemeTypographies);

  const headerLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: headerProps?.textStyleVariant,
    nestedProps: props.headerProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT,
    typographies,
  });

  return (
    <TabsComponent
      {...props}
      headerProps={headerProps}
      headerLineHeightPx={headerLineHeightPx}
    />
  );
};
