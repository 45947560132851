import { Button } from "antd";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ReactComponent as ConfigureIcon } from "assets/icons/common/customization.svg";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { colors } from "styles/colors";

const ManageTypefaceOption = styled.div`
  .divider {
    width: calc(100% - 4px);
    height: 1px;
    background: ${colors.GREY_100};
    margin: 6px 2px;
  }
  .option {
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${colors.ACCENT_BLUE_500};
    font-size: 12px;
    border-radius: 4px;
    min-height: 32px;
    padding: 5px 12px;
    cursor: pointer;
    margin: 0px 4px;
    &:hover {
      background: ${colors.ACCENT_BLUE_500_25}; // match blueprint
    }
  }
`;

export const createThemeTooltip = () => {
  return <ManageThemeTooltip />;
};

const ManageThemeTooltip = () => {
  const dispatch = useDispatch();
  const openThemeSidebar = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKey.ACTIVE_LEFT_PANE,
      SideBarKeys.THEME,
    );
    dispatch(updateApplicationSidebarKey({ selectedKey: SideBarKeys.THEME }));
  }, [dispatch]);

  return (
    <div>
      Select a text style from the{" "}
      <Button
        type="link"
        onClick={openThemeSidebar}
        style={{ padding: "0px", fontSize: "12px", height: "min-content" }}
      >
        app theme
      </Button>
    </div>
  );
};

export const createThemeOption = () => {
  return <ManageThemeOption />;
};

const ManageThemeOption = () => {
  const dispatch = useDispatch();
  const openThemeSidebar = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKey.ACTIVE_LEFT_PANE,
      SideBarKeys.THEME,
    );
    dispatch(updateApplicationSidebarKey({ selectedKey: SideBarKeys.THEME }));
  }, [dispatch]);

  return (
    <ManageTypefaceOption>
      <div className="divider" />
      <div onClick={openThemeSidebar} className="option">
        <ConfigureIcon />
        <span>Manage styles</span>
      </div>
    </ManageTypefaceOption>
  );
};
