import { Dimension } from "@superblocksteam/shared";
import React, { useEffect, useMemo } from "react";
import { useHeightChange, useWidthChange } from "hooks/ui/useElementRect";
import {
  GridDefaults,
  PAGE_WIDGET_ID,
  WidgetTypes,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import {
  clearWidgetDynamicHeight,
  clearWidgetDynamicWidth,
  updateWidgetDynamicProps,
} from "legacy/selectors/layoutActions";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { getDynamicVisibilityWidgets } from "legacy/selectors/visibilitySelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import WidgetFactory from "../../Factory";
import { doesWidgetContainFillParentSection } from "./dynamicLayoutUtils";

// We have to add this back to the height and width change hooks
// otherwise they get confused and size down incorrectly
// Be careful if changing or remvoing this, or the use of WIDGET_PADDING
// for stack component wrappers
const PADDING_OFFSET = WIDGET_PADDING * 2;

export function useClearFillParent(
  widgetId: string,
  isFillParent: boolean,
  axis: "height" | "width",
) {
  const dispatch = useAppDispatch();
  const wasFillParent = React.useRef<boolean>(isFillParent);
  useEffect(() => {
    if (wasFillParent.current && !isFillParent) {
      if (axis === "height") {
        dispatch(clearWidgetDynamicHeight(widgetId));
      } else {
        dispatch(clearWidgetDynamicWidth(widgetId));
      }
    }
    wasFillParent.current = isFillParent;
  }, [isFillParent, widgetId, axis, dispatch]);
}

export function useUpdateFillParentHeight(
  widgetId: string,
  widgetPadding: number,
  enabled = true,
) {
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  useHeightChange(ref, (height) => {
    if (enabled) {
      dispatch(
        updateWidgetDynamicProps(widgetId, {
          height: Dimension.px(height + PADDING_OFFSET - widgetPadding),
        }),
      );
    }
  });
  return ref;
}

export function useUpdateFillParentWidth(widgetId: string, enabled = true) {
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  useWidthChange(ref, (width) => {
    if (enabled) {
      dispatch(
        updateWidgetDynamicProps(widgetId, {
          width: Dimension.px(width + PADDING_OFFSET),
        }),
      );
    }
  });
  return ref;
}

/*
 *  Min height: the minimum amount of height the page contents needs to support the min-heights of all sections
 *  Max height: only applicable when there is at least 1 fill parent sections, and all fill parent sections have a max height set
 */
export function useFillParentHeightInfo(rootId: string = PAGE_WIDGET_ID): [
  containsFillParentSection: boolean,
  minHeight: number | undefined, // is undefined if there are no fill parent sections
  maxWithFP: number | undefined, // is undefined if there are no fill parent sections or if any fill parent sections don't have a max height set
] {
  const widgets = useAppSelector(getFlattenedCanvasWidgets);
  const appMode = useAppSelector(getAppMode) ?? APP_MODE.PUBLISHED;
  const theme = useAppSelector(selectGeneratedTheme);
  const dynamicVisibility = useAppSelector(getDynamicVisibilityWidgets);

  return useMemo(() => {
    const hasFillParent = doesWidgetContainFillParentSection(widgets, rootId);
    if (!hasFillParent) return [false, undefined, undefined];

    const rootWidget = widgets[rootId];

    let minHeightPx = Dimension.px(0);
    let fillParentAdditionalHeight = Dimension.px(0);
    let allFPSectionsHaveMaxHeight = true;
    (rootWidget?.children ?? []).forEach((childId) => {
      const child = widgets[childId];
      if (child && child.type === WidgetTypes.SECTION_WIDGET) {
        const widget = widgets[child.widgetId];

        const sectionRequiredHeight = Dimension.toPx(
          WidgetFactory.getWidgetComputedHeight(
            widget,
            widgets,
            theme,
            appMode,
            dynamicVisibility,
          ) ?? Dimension.px(0),
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        );
        minHeightPx = Dimension.add(
          minHeightPx,
          sectionRequiredHeight,
        ).asFirst();

        if (widget.height.mode === "fillParent") {
          if (!widget.maxHeight) {
            allFPSectionsHaveMaxHeight = false;
          } else {
            const deltaPx = Dimension.minus(
              Dimension.toPx(
                widget.maxHeight,
                GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              ),
              sectionRequiredHeight,
            ).asFirst();
            fillParentAdditionalHeight = Dimension.add(
              fillParentAdditionalHeight,
              deltaPx,
            ).asFirst();
          }
        }
      }
    });

    const maxHeightPx = Dimension.add(minHeightPx, fillParentAdditionalHeight);

    return [
      hasFillParent,
      minHeightPx.value,
      allFPSectionsHaveMaxHeight ? maxHeightPx.value : undefined,
    ];
  }, [appMode, theme, widgets, rootId, dynamicVisibility]);
}
