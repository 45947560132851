const derived = {
  getSelectedCell: `() => {
    const selectedCellIndex = _.isNumber(this.selectedCellIndex)
      ? this.selectedCellIndex
        : _.isNumber(this.defaultSelectedCell)
        ? this.defaultSelectedCell
        : -1;

    const items = this.gridData || [];

    if (selectedCellIndex === -1) {
      return null;
    }

    const selectedCell = { ...items[selectedCellIndex] };
    return selectedCell;
  }`,
  getSelectedCellSchema: `() => {
    const items = this.gridData || [];
    if (items.length <= 0) {
      return;
    }
    const selectedCellIndex = _.isNumber(this.selectedCellIndex)
      ? this.selectedCellIndex
        : _.isNumber(this.defaultSelectedCell)
        ? this.defaultSelectedCell
        : -1;
    const emptyRow = { ...items[selectedCellIndex > 0 ? selectedCellIndex : 0] };
    Object.keys(emptyRow).forEach((key) => {
      emptyRow[key] = null;
    });
    return emptyRow;
  }`,
  getCells: `() => {
    const cell = {};

    Object.keys(this.gridBindings).map((widgetName) => {
      cell[widgetName] = { ...this.gridBindings[widgetName] };
    });

    const updatedCells = [];

    for (let cellIndex = 0; cellIndex < this.gridData.length; cellIndex++) {
      const currentCell = JSON.parse(JSON.stringify(cell));
      const widgetKeys = Object.keys(currentCell);

      for (let i = 0; i < widgetKeys.length; i++) {
        const currentWidgetName = widgetKeys[i];
        const currentWidget = currentCell[currentWidgetName];

        const dynamicPaths = _.compact(
          currentWidget.dynamicBindingPathList?.map((path) => path.key),
        );

        dynamicPaths.forEach((path) => {
          const evaluatedProperty = _.get(
            this.gridBindings,
            currentWidget.widgetName + "." + path,
          );

          if (Array.isArray(evaluatedProperty)) {
            const evaluatedValue = evaluatedProperty[cellIndex];

            _.set(currentWidget, path, evaluatedValue);
          }
        });

        currentCell[currentWidgetName] = currentWidget;
      }

      updatedCells[cellIndex] = currentCell;
    }

    return updatedCells;
  }`,
};

export default derived;
