import { Dimension } from "@superblocksteam/shared";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import tinycolor from "tinycolor2";
import { Layers } from "legacy/constants/Layers";
import {
  GridDefaults,
  WidgetHeightConstraintType,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import useWidgetHeightConstraintResize from "./useWidgetHeightConstraintResize";

const resizingCSS = css`
  background-color: ${(props) => props.theme.colors.ACCENT_BLUE_700};
  box-shadow: 0 0 0 3px
    ${(props) =>
      tinycolor(props.theme.colors.ACCENT_BLUE_500)
        .setAlpha(0.5)
        .toRgbString()};
`;

const BOTTOM_OFFSET = 6;
const CURSOR_STYLE = "row-resize";

const HeightResizerLine = styled.div`
  width: 100%;
  height: 12px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: ${Layers.selectionConstraintResize};
  pointer-events: all;
  cursor: ${CURSOR_STYLE};

  &[data-is-disabled="false"] {
    &:hover > .HeightResizerLineInner {
      ${resizingCSS};
    }
  }
`;

const HeightResizerLineInner = styled.div`
  pointer-events: none;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;

  &[data-is-resizing="true"] {
    ${resizingCSS};
  }

  &[data-constraint-type="minHeight"],
  &[data-constraint-type="maxHeight"] {
    background: linear-gradient(
      to right,
      transparent 50%,
      ${(props) => props.theme.colors.BLUE_MINMAX_LABELS} 50%
    );
    background-size: 6px 1px;
  }
`;

const ConstraintLabelContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: ${(props) => props.theme.colors.BLUE_MINMAX_LABELS};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 20px;
  padding: 0 8px 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    top: 0;
    left: -8px;
  }

  svg > path {
    fill: ${(props) => props.theme.colors.BLUE_MINMAX_LABELS};
  }
`;

const ConstraintLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.WHITE};
`;

type WidgetHeightConstraintResizerProps = {
  height: Dimension<WidgetHeightModes>;
  isResizing: boolean;
  constraintLabel?: string;
  setIsResizingConstraint: (
    isResizing: boolean,
    constraintType: WidgetHeightConstraintType,
  ) => void;
  setResizingHeight: (size: number) => void;
  constraintType: WidgetHeightConstraintType;
  onResizeEnded: (
    constraintType: WidgetHeightConstraintType,
    height: Dimension<"gridUnit">,
  ) => void;
  disabled?: boolean;
  showDisabledMessage?: () => void;
  widgetRelativePosition?: {
    top: number;
    left: number;
    width: number;
  };
  paddingOffsetPx?: number;
};

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

const ResizerLabelArrow: React.FC = () => {
  return (
    <svg
      width="8"
      height="20"
      viewBox="0 0 8 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49711 1.25722C3.80084 0.497903 4.53626 0 5.35407 0H8V20H5.35407C4.53626 20 3.80084 19.5021 3.49711 18.7428L0.297113 10.7428C0.106384 10.266 0.106384 9.73404 0.297113 9.25722L3.49711 1.25722Z"
        fill="transparent"
      />
    </svg>
  );
};

const WidgetHeightConstraintResizer = ({
  height,
  isResizing,
  constraintLabel,
  setIsResizingConstraint,
  setResizingHeight,
  constraintType,
  onResizeEnded,
  disabled = false,
  showDisabledMessage,
  widgetRelativePosition,
  paddingOffsetPx,
}: WidgetHeightConstraintResizerProps) => {
  const { startDrag } = useWidgetHeightConstraintResize({
    height,
    setIsResizingConstraint,
    setResizingHeight,
    constraintType,
    onResizeEnded,
  });

  const heightValuePx = Dimension.toPx(
    height,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).value;

  const showDisabledMessageInternal = React.useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation();
      showDisabledMessage?.();
    },
    [showDisabledMessage],
  );

  useEffect(() => {
    document.body.style.cursor = isResizing ? CURSOR_STYLE : "";

    return () => {
      document.body.style.cursor = "";
    };
  }, [isResizing]);

  return (
    <HeightResizerLine
      data-test="widget-height-resizer"
      data-is-disabled={disabled}
      onClick={stopPropagation}
      onPointerDown={disabled ? showDisabledMessageInternal : startDrag}
      style={{
        top:
          (widgetRelativePosition?.top ?? 0) +
          heightValuePx -
          BOTTOM_OFFSET +
          (paddingOffsetPx ?? 0),
        left: widgetRelativePosition?.left ?? 0,
        width: widgetRelativePosition?.width
          ? `${widgetRelativePosition.width}px`
          : "100%",
      }}
    >
      <HeightResizerLineInner
        className="HeightResizerLineInner"
        data-constraint-type={constraintType}
        data-is-resizing={isResizing}
      >
        {constraintLabel && (
          <ConstraintLabelContainer>
            <ResizerLabelArrow />
            <ConstraintLabel>{constraintLabel}</ConstraintLabel>
          </ConstraintLabelContainer>
        )}
      </HeightResizerLineInner>
    </HeightResizerLine>
  );
};

export default WidgetHeightConstraintResizer;
