import { Dimension } from "@superblocksteam/shared";
import { DefaultTheme, StyledComponent } from "styled-components";
import {
  WidgetHeightModes,
  WidgetWidthModes,
} from "legacy/constants/WidgetConstants";
import { ResizableProps } from "../Resizable";
import {
  LeftHandleStyles,
  RightHandleStyles,
  TopHandleStyles,
  TopLeftHandleStyles,
  TopRightHandleStyles,
  BottomLeftHandleStyles,
  BottomRightHandleStyles,
  BottomSwapHandleStyles,
} from "../ResizeStyledComponents";
import type { UIElementSize } from "../ResizableUtils";

export const makeHandle = (
  component: StyledComponent<"div", DefaultTheme>,
  disabledText?: string,
) => ({
  component,
  disabledText,
});

export const allHandles: ResizableProps["handles"] = {
  left: makeHandle(LeftHandleStyles),
  right: makeHandle(RightHandleStyles),
  bottom: makeHandle(BottomSwapHandleStyles as any),
  top: makeHandle(TopHandleStyles),
  bottomRight: makeHandle(BottomRightHandleStyles),
  topLeft: makeHandle(TopLeftHandleStyles),
  topRight: makeHandle(TopRightHandleStyles),
  bottomLeft: makeHandle(BottomLeftHandleStyles),
};

export const FitContentVerticalResizeDisabledTooltip =
  "Height mode is Fit content. Change height to pixel to enable manual resizing.";

export const FitContentHorizontalResizeDisabledTooltip =
  "Width mode is Fit content. Change width to fluid or pixel to enable manual resizing.";

export const FillParentVerticalResizeDisabledTooltip =
  "Height mode is Fill parent. Change height to pixel to enable manual resizing.";

export const FillParentHorizontalResizeDisabledTooltip =
  "Width mode is Fill parent. Change width to fluid or pixel to enable manual resizing.";

export const StretchHorizontalResizeDisabledTooltip =
  "Parent horizontal alignment is Stretch. Change to enable manual resizing.";

export const StretchVerticalResizeDisabledTooltip =
  "Parent vertical alignment is Stretch. Change to enable manual resizing.";

export function getNewDimensions({
  currentWidth,
  currentHeight,
  newDimensions,
  parentColumnSpace,
  parentRowSpace,
}: {
  currentWidth: Dimension<WidgetWidthModes>;
  currentHeight: Dimension<WidgetHeightModes>;
  newDimensions: UIElementSize;
  parentColumnSpace: number;
  parentRowSpace: number;
}) {
  const heightMode = currentHeight.mode;
  const widthMode = currentWidth.mode;

  const newWidthGU = Math.round(newDimensions.width / parentColumnSpace);
  const newHeightGU = Math.round(newDimensions.height / parentRowSpace);

  let newHeight: Dimension<WidgetHeightModes> | undefined;
  if (heightMode === "px" || heightMode === "gridUnit") {
    // Grid Units on the height are pixel perfect
    const unroundedGridUnits = newDimensions.height / parentRowSpace;
    if (unroundedGridUnits === newHeightGU) {
      newHeight = Dimension.gridUnit(newHeightGU);
    } else {
      newHeight = Dimension.px(newDimensions.height);
    }
  }

  let newWidth: Dimension<WidgetWidthModes> | undefined;
  if (widthMode === "px") {
    newWidth = Dimension.px(newDimensions.width);
  } else if (widthMode === "gridUnit") {
    // Grid Units on the width are percent based
    newWidth = Dimension.gridUnit(newWidthGU);
  }
  return { newWidth, newHeight };
}
