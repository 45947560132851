import React from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { Layers } from "legacy/constants/Layers";
import { ContainerWidgetProps } from "../ContainerWidget";
import { WidgetLayoutProps } from "../shared";

const SectionResizeOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: transparent;
  z-index: ${Layers.dragPreview};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

const GRID_COLS_GAP = 4;

const SectionGridLinesResizeOverlay = styled(SectionResizeOverlay)`
  justify-content: space-around;
`;

const OverlayGridItem = styled.div`
  box-sizing: border-box;
  height: 100%;
  background: ${({ theme }) =>
    tinycolor(theme.colors.ACCENT_BLUE_500).setAlpha(0.2).toRgbString()};
  pointer-events: none;
`;

const ColumnSize = styled.div`
  height: 100%;
  position: relative;
  pointer-events: none;
  background: transparent;
  z-index: ${Layers.dragPreview};
  transition: width 0.2s ease;
`;

const ColumnSizeBorder = styled.div`
  position: absolute;
  inset: 0px;
  border: 1px solid ${(props) => props.theme.colors.GREY_200};
`;

const ColumnSizeLabel = styled.span`
  position: absolute;
  z-index: ${Layers.widgetName};
  white-space: nowrap;
  word-break: keep-all;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  color: ${(props) => props.theme.colors.WHITE};
  font-size: 12px;
  font-weight: 500;
  background: ${(props) => props.theme.colors.ACCENT_BLUE_NEW_DARKER};
  border-radius: 4px;
  padding: 2px 6px;

  > span {
    opacity: 0.56;
  }
`;

export const SectionColumnResizeLabels = ({
  sectionColumns,
  childWidgets,
  gridColumnsPerSectionColumn,
}: {
  sectionColumns: number;
  childWidgets: ContainerWidgetProps["children"];
  gridColumnsPerSectionColumn: number;
}) => {
  return (
    <SectionResizeOverlay>
      {childWidgets?.map((childWidget: WidgetLayoutProps, index: number) => {
        const columnWidth =
          (childWidget.gridColumns || 0) * (childWidget.parentColumnSpace || 0);
        const numSectionColumns =
          (childWidget.gridColumns || 0) / gridColumnsPerSectionColumn;

        return (
          <ColumnSize
            key={childWidget.widgetId}
            style={{
              width: columnWidth,
            }}
          >
            <ColumnSizeBorder />
            <ColumnSizeLabel>
              {numSectionColumns} <span>/ {sectionColumns}</span>
            </ColumnSizeLabel>
          </ColumnSize>
        );
      })}
    </SectionResizeOverlay>
  );
};

SectionColumnResizeLabels.displayName = "SectionColumnResizeLabels";

export const SectionColumnResizeGridLines = ({
  sectionColumns,
}: {
  sectionColumns: number;
}) => {
  return (
    <SectionGridLinesResizeOverlay>
      {[...Array(sectionColumns).keys()].map((_, index) => (
        <OverlayGridItem
          key={index}
          style={{
            width: `calc((100% / ${sectionColumns}) - ${GRID_COLS_GAP}px)`,
          }}
        />
      ))}
    </SectionGridLinesResizeOverlay>
  );
};

SectionColumnResizeGridLines.displayName = "SectionColumnResizeGridLines";
