import { InputRef } from "antd";
import Fuse from "fuse.js";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  SearchableListContainer,
  SearchableListInput,
  SearchableListResults,
  useListKeyboardNavigation,
  useScrollToFocusedItem,
} from "pages/Editors/ApiEditor/ApiListControlFlow/SearchableList/SearchableList";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { ScrollContainer } from "./ScrollContainer";

const SearchableListWrapper = styled(SearchableListContainer)`
  background: ${colors.WHITE};
  box-shadow:
    0px 0px 1px 0px #22272f52,
    0px 12px 32px -8px #22272f29,
    0px 1px 3px 0px #22272f1f;
  width: 180px;
  height: 350px;
  border-radius: 4px;
`;

export const SearchListItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.GREY_700};
  padding: 8px 10px;
  border-radius: 4px;
  margin: 0 4px;

  &:hover,
  &[data-focused="true"] {
    background: ${colors.GREY_50};
  }
  &[data-hasPaidTag="true"] {
    cursor: not-allowed;
  }

  // Support for items with icons
  display: flex;
  align-items: center;
  gap: 6px;

  &[data-disabled="true"] {
    cursor: not-allowed;
  }
`;

const RenderBelowListClassName = styleAsClass`
  border-top: 1px solid ${colors.GREY_100};
`;

const ScrollContainerClassName = styleAsClass`
  padding-bottom: 6px;
`;

export type ItemsType = {
  value: string;
  label: string;
  hasDivider?: boolean;
  icon?: React.ReactNode;
  customRender?: (props: CustomRenderProps) => React.ReactNode;
  dataTest?: string;
};

export type CustomRenderProps = {
  focusedIndex: number | undefined;
  index: number;
  className: string;
  onMouseEnter: () => void;
  onClick: () => void;
  dataTest: string;
};

export type SearchableListProps = {
  items: Array<ItemsType>;
  onItemSelect: (item: string) => void;
  id?: string;
  wrapperStyles?: React.CSSProperties;
  dataTest?: string;
  renderBelowList?: React.ReactNode;
  wrapperClassName?: string;
  placeholder?: string;
  noResultsMessage?: string | React.ReactNode;
};

export const SearchableListPopup = (props: SearchableListProps) => {
  const searchInputRef = useRef<InputRef>(null);
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollItemIntoView = useScrollToFocusedItem(scrollWrapperRef);
  const [searchText, setSearchText] = useState<string>("");

  const fuseList = useMemo(() => {
    const fuse = new Fuse(props.items, {
      keys: ["label", "value"],
      distance: 1000,
      threshold: 0.3,
    });

    return fuse;
  }, [props.items]);

  const searchFilteredOptionsList = useMemo(() => {
    if (searchText) {
      return fuseList.search(searchText).map((result) => result.item);
    }

    return props.items;
  }, [props.items, fuseList, searchText]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [setSearchText, scrollContainerRef],
  );

  const { handleKeyPress, focusedIndex, resetFocusedIndex, setFocusedIndex } =
    useListKeyboardNavigation({
      defaultFocusedIndex: 0,
      filteredItems: searchFilteredOptionsList,
      onEnterKeypress: props.onItemSelect,
      onFocused: scrollItemIntoView,
      idKey: "value" satisfies keyof React.ComponentProps<
        typeof SearchableListPopup
      >["items"][0],
    });

  const focusSearchInput = useCallback(() => {
    searchInputRef.current?.focus();
  }, [searchInputRef]);

  useEffect(() => {
    focusSearchInput();
  }, [focusSearchInput, resetFocusedIndex]);

  const handleItemMouseOver = useCallback(
    (index: number) => {
      setFocusedIndex(index);
    },
    [setFocusedIndex],
  );

  const renderStandardItem = (
    item: ItemsType,
    index: number,
    focusedIndex: number | undefined,
  ) => {
    return (
      <SearchListItem
        className="searchable-list-item" // for useScrollToFocusedItem support
        onClick={() => props.onItemSelect(item.value)}
        onMouseEnter={() => handleItemMouseOver(index)}
        data-focused={focusedIndex === index}
        data-test={`dropdown-option-${item.dataTest ?? item.value}`}
      >
        {item.icon && <div>{item.icon}</div>}
        {item.label}
      </SearchListItem>
    );
  };

  const renderCustomItem = (
    item: ItemsType,
    index: number,
    focusedIndex: number | undefined,
  ) => {
    return item.customRender
      ? item.customRender({
          focusedIndex,
          index,
          className: "searchable-list-item",
          onMouseEnter: () => handleItemMouseOver(index),
          onClick: () => props.onItemSelect(item.value),
          dataTest: `dropdown-option-${item.value}`,
        })
      : null;
  };

  const renderItem = (
    item: ItemsType,
    index: number,
    focusedIndex: number | undefined,
  ) => {
    const itemToRender = item.customRender
      ? renderCustomItem
      : renderStandardItem;

    return (
      <React.Fragment key={item.value}>
        {itemToRender(item, index, focusedIndex)}
        {item.hasDivider && searchText.length === 0 && (
          <div
            style={{
              borderTop: `1px solid ${colors.GREY_100}`,
              margin: "4px 0",
            }}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <SearchableListWrapper
      id={props.id}
      style={props.wrapperStyles}
      data-test={props.dataTest}
      className={props.wrapperClassName}
    >
      <SearchableListInput
        ref={searchInputRef}
        value={searchText}
        onSearchChange={handleSearchChange}
        allowClear={false}
        showSearchIcon={true}
        onKeyPress={handleKeyPress}
        placeholder={props.placeholder}
      />
      {searchFilteredOptionsList?.length > 0 ? (
        <ScrollContainer
          className={ScrollContainerClassName}
          display="table"
          ref={scrollContainerRef}
        >
          <SearchableListResults ref={scrollWrapperRef}>
            {searchFilteredOptionsList.map((item, index) => {
              return renderItem(item, index, focusedIndex);
            })}
          </SearchableListResults>
        </ScrollContainer>
      ) : (
        <div style={{ color: colors.GREY_500 }}>{props.noResultsMessage}</div>
      )}
      {props.renderBelowList && (
        <div className={RenderBelowListClassName}>{props.renderBelowList}</div>
      )}
    </SearchableListWrapper>
  );
};
