import { WidgetTypes } from "@superblocksteam/shared";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getLowestAncestorWithType } from "legacy/selectors/sagaSelectors";
import { AppState } from "store/types";

export const useMountingNode = (widgetId: string) => {
  const mountingNodeRef = useRef<HTMLElement | null>(
    document.getElementById("canvas-root"),
  );

  const ancestorSlideoutOrModal = useSelector((state: AppState) =>
    getLowestAncestorWithType(state, widgetId, [
      WidgetTypes.SLIDEOUT_WIDGET,
      WidgetTypes.MODAL_WIDGET,
    ]),
  );

  useEffect(() => {
    if (ancestorSlideoutOrModal?.widgetId) {
      const isSlideout =
        ancestorSlideoutOrModal.type === WidgetTypes.SLIDEOUT_WIDGET;
      mountingNodeRef.current = document.getElementById(
        `${isSlideout ? "slideout" : "modal"}-component-${
          ancestorSlideoutOrModal?.widgetId
        }`,
      );
    }
  }, [ancestorSlideoutOrModal?.type, ancestorSlideoutOrModal?.widgetId]);

  if (ancestorSlideoutOrModal?.widgetId) {
    const isSlideout =
      ancestorSlideoutOrModal.type === WidgetTypes.SLIDEOUT_WIDGET;
    mountingNodeRef.current = document.getElementById(
      `${isSlideout ? "slideout" : "modal"}-component-${
        ancestorSlideoutOrModal?.widgetId
      }`,
    );
  }

  return mountingNodeRef.current;
};
