import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { type IFrameWidgetProps } from "./IFrameWidget";

const config: PropertyPaneCategoryConfig<IFrameWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "source",
      helpText: "Set the URL to display.",
      label: "Source URL",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter URL",
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "srcDoc",
      helpText: "Inline HTML to embed (Overrides URL)",
      label: "Source doc",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter HTML",
      forceVertical: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection(),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onMessageReceived",
      "Triggers an action when a message event is received",
    ),
  ]),
];

export default config;
