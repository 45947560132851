import { noop } from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import { CLASS_NAMES } from "legacy/themes/classnames";

export const CheckboxInput = ({
  isChecked,
  isDisabled,
  ariaLabel,
  isValid,
  onClick,
  isIndeterminate,
}: {
  isChecked: boolean;
  isDisabled: boolean;
  ariaLabel?: string;
  isValid: boolean;
  isIndeterminate?: boolean;
  onClick?: (isChecked: boolean) => void;
}) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  useEffect(() => {
    if (isIndeterminate != null && inputRef.current) {
      inputRef.current.indeterminate = isIndeterminate;
    }
  }, [isIndeterminate]);

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick(!isChecked);
    }
  }, [isChecked, onClick]);

  return (
    <div
      className={`${CLASS_NAMES.CHECKBOX} ${
        !isValid ? CLASS_NAMES.ERROR_MODIFIER : ""
      }`}
      onClick={onClickHandler}
    >
      <input
        ref={inputRef}
        aria-label={ariaLabel}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={noop} // handled by parent
      />
      {/* this span will be the checkmark */}
      <span />
    </div>
  );
};
