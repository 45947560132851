/**
 * This file manages effects that need to be run when the base blueprints change.
 * We store our blueprints response statically and update it statically for custom components.
 * Every time a custom component is added, we have a new component and need to apply the overrides to it.
 * Since the selectors use `createSelector`, which is cached, we need to manually update the cache
 * when the blueprints change.
 */

const effects: Array<() => void> = [];

export default function createConfigChanged() {
  effects.forEach((effect) => effect());
}

export function onCreateConfigChanged(effect: () => void) {
  effects.push(effect);
}
