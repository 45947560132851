import React from "react";

interface HelpTooltipProps {
  text: string;
  docsUrl?: string;
}

const HelpTooltip: React.FC<HelpTooltipProps> = ({ text, docsUrl }) => {
  return (
    <>
      <p style={{ marginBottom: "0" }}>{text}</p>
      {docsUrl && (
        <a href={docsUrl} target="_blank" rel="noopener noreferrer">
          Learn more in the docs →
        </a>
      )}
    </>
  );
};

export default HelpTooltip;
