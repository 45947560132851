import tinycolor from "tinycolor2";

type RGB = [number, number, number];
type RGBA = [number, number, number, number];

export const rgba2rgb = (color: RGBA, background: RGB) => {
  const alpha = color[3];
  return [
    Math.floor((1 - alpha) * background[0] + alpha * color[0] + 0.5),
    Math.floor((1 - alpha) * background[1] + alpha * color[1] + 0.5),
    Math.floor((1 - alpha) * background[2] + alpha * color[2] + 0.5),
  ];
};

/**
 * Adjusts color brightness or returns a default.
 *
 * Takes a base color, default color, and change percentage.
 * Lightens or darkens the base color, or returns default if no base color.
 *
 * Used for creating UI element contrast while keeping color consistency.
 */
export const getLightenedOrDarkenedColor = (
  baseColor: string | undefined,
  defaultColor: string,
  brightnessChangePercentage: number,
): string => {
  if (!baseColor) {
    return defaultColor;
  }

  const tinyColor = tinycolor(baseColor);
  return tinyColor.isLight()
    ? tinyColor.darken(brightnessChangePercentage).toString()
    : tinyColor.lighten(brightnessChangePercentage).toString();
};
