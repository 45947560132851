import { useCallback, useRef } from "react";
import { useFeatureFlag } from "hooks/ui";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { useWidgetSelection } from "legacy/hooks/dragResizeHooks";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import useOpenContextMenuForWidget from "legacy/pages/Editor/WidgetContextMenu/useOpenContextMenuForWidget";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import {
  getIsMultipleWidgetsSelected,
  getSingleSelectedWidgetId,
} from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import type { WidgetProps } from "../BaseWidget";
import type { StaticWidgetProps } from "../shared";

type Props = {
  deselectOnSecondClick?: boolean;
  focusDisabled?: boolean;
  disabled?: boolean;
  requireDoubleClick?: boolean;
} & (StaticWidgetProps<unknown> | WidgetProps);

export const useWidgetFocusHandlers = (props: Props) => {
  const { selectWidgets, focusWidget, unfocusWidget } = useWidgetSelection();

  const enableGrouping = useFeatureFlag(Flag.LAYOUTS_ENABLE_GROUPING);

  const selectedWidget = useAppSelector(getSingleSelectedWidgetId);

  // This state tells us which widget is focused
  // The value is the widgetId of the focused widget.
  const focusedWidget = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.focusedWidgetId,
  );

  // This state tells us whether a `ResizableComponent` is resizing
  const isResizing = useAppSelector(selectIsResizing);

  // This state tells us whether a `DraggableComponent` is dragging
  const isDragging = useAppSelector(selectIsDragging);

  const canvasWidgets = useAppSelector(getCanvasWidgets);
  const parentWidgetToSelect = getParentToOpenIfAny(
    props.widgetId,
    canvasWidgets,
  );

  const isMultipleWidgetsSelected = useAppSelector(
    getIsMultipleWidgetsSelected,
  );

  const handleSelectionClick = useCallback(
    (e: any, requireDoubleClick?: boolean) => {
      if (isDragging || isResizing || requireDoubleClick) {
        // Prevent selection as a result of dragging, that is a separate action
        e.stopPropagation();
        return;
      }
      if (
        !parentWidgetToSelect ||
        parentWidgetToSelect.type !== WidgetTypes.GRID_WIDGET
      ) {
        // Focus is allowed to propagate since the Grid depends on it
        e.stopPropagation();
      }
      focusedWidget !== props.widgetId && focusWidget?.(props.widgetId);

      if (selectedWidget === props.widgetId && props.deselectOnSecondClick) {
        selectWidgets?.([]);
      } else if (selectedWidget !== props.widgetId) {
        selectWidgets?.([props.widgetId]);
      }
    },
    [
      props.widgetId,
      props.deselectOnSecondClick,
      parentWidgetToSelect,
      isDragging,
      isResizing,
      focusedWidget,
      focusWidget,
      selectedWidget,
      selectWidgets,
    ],
  );

  const handleClick = useCallback(
    (e: any) => {
      handleSelectionClick(e, props.requireDoubleClick);
    },
    [handleSelectionClick, props.requireDoubleClick],
  );

  const openContextMenuForWidget = useOpenContextMenuForWidget();
  const handleContextMenuClick = useCallback(
    (e: React.MouseEvent) => {
      // We want to allow widgets to stay selected when right-clicking somewhere else because it gives grouping options.
      if (!enableGrouping || !isMultipleWidgetsSelected) {
        handleSelectionClick(e, false);
      }

      e.preventDefault();
      e.stopPropagation();
      openContextMenuForWidget({
        widgetId: props.widgetId,
        isInsideIframe: true,
        clientX: e.clientX,
        clientY: e.clientY,
      });
    },
    [
      handleSelectionClick,
      props.widgetId,
      openContextMenuForWidget,
      enableGrouping,
      isMultipleWidgetsSelected,
    ],
  );

  const handleDoubleClick: React.MouseEventHandler = useCallback(
    (e) => {
      if (props.requireDoubleClick) {
        focusedWidget !== props.widgetId && focusWidget?.(props.widgetId);
        selectedWidget !== props.widgetId && selectWidgets?.([props.widgetId]);
      }
    },
    [
      props.requireDoubleClick,
      props.widgetId,
      focusWidget,
      focusedWidget,
      selectWidgets,
      selectedWidget,
    ],
  );

  // When mouse is over this draggable
  const handleMouseOver = useCallback(
    (e: any) => {
      !props.focusDisabled &&
        focusWidget &&
        focusedWidget !== props.widgetId &&
        focusWidget(props.widgetId);
      e.stopPropagation();
    },
    [props.focusDisabled, focusWidget, focusedWidget, props.widgetId],
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // When mouse is moves out of this draggable
  const handleMouseOut = useCallback(
    (e: any) => {
      const selfNode = wrapperRef.current;
      const isInternalMove =
        selfNode?.contains(e.relatedTarget) && selfNode?.contains(e.target);
      !props.focusDisabled &&
        unfocusWidget &&
        focusedWidget === props.widgetId &&
        !isInternalMove &&
        !e.relatedTarget?.classList?.contains?.("t--resize-handle") &&
        e.relatedTarget?.dataset?.maintainWidgetFocus !== "true" &&
        unfocusWidget();
      e.stopPropagation();
    },
    [props.focusDisabled, focusedWidget, props.widgetId, unfocusWidget],
  );

  return {
    wrapperRef,
    handleClick,
    handleDoubleClick,
    handleContextMenuClick,
    handleMouseOver,
    handleMouseOut,
  };
};
