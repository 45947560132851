import { CalloutType } from "./Constants";
import type { GeneratedTheme } from "legacy/themes/types";

const COLOR_NAME_BY_CALLOUT_TYPE: Record<
  CalloutType,
  keyof GeneratedTheme["colors"]
> = {
  [CalloutType.INFO]: "info",
  [CalloutType.SUCCESS]: "success",
  [CalloutType.WARNING]: "warning",
  [CalloutType.DANGER]: "danger",
};

export const getColorByCalloutType = (
  generatedTheme: GeneratedTheme,
  type: CalloutType,
): string => {
  const themeColors = generatedTheme.colors;
  switch (type) {
    case CalloutType.INFO:
      return themeColors[
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.INFO]
      ] as string;
    case CalloutType.SUCCESS:
      return themeColors[
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.SUCCESS]
      ] as string;
    case CalloutType.WARNING:
      return themeColors[
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.WARNING]
      ] as string;
    case CalloutType.DANGER:
      return themeColors[
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.DANGER]
      ] as string;
    default:
      return themeColors[
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.INFO]
      ] as string;
  }
};

export const getThemeColorByCalloutType = (type: CalloutType): string => {
  switch (type) {
    case CalloutType.INFO:
      return `{{ theme.colors.${
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.INFO]
      } }}`;
    case CalloutType.SUCCESS:
      return `{{ theme.colors.${
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.SUCCESS]
      } }}`;
    case CalloutType.WARNING:
      return `{{ theme.colors.${
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.WARNING]
      } }}`;
    case CalloutType.DANGER:
      return `{{ theme.colors.${
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.DANGER]
      } }}`;
    default:
      return `{{ theme.colors.${
        COLOR_NAME_BY_CALLOUT_TYPE[CalloutType.INFO]
      } }}`;
  }
};
