import { Dimension } from "@superblocksteam/shared";
import { MIN_WIDGET_SIZE } from "legacy/constants/WidgetConstants";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "../shared";
import { STACK_ADJUSTMENT_EPSILON } from "./constants";
import { StackAdjustmentsInfo, WidgetToDrop } from "./types";

const getProspectiveRemovableSpace = (
  widget: FlattenedWidgetLayoutProps,
): Dimension<"gridUnit"> => {
  if (widget.width.mode === "gridUnit") {
    const minWidth = MIN_WIDGET_SIZE.width;
    return Dimension.gridUnit(Math.max(widget.width.value - minWidth, 0));
  } else {
    // we can't take space away from non gridUnit width widgets
    return Dimension.gridUnit(0);
  }
};

export class AdjustmentManager {
  private adjustments: StackAdjustmentsInfo = {};
  private widgets: FlattenedWidgetLayoutMap = {};
  private availableSpacePx: number;
  private initialAvailableSpacePx: number;
  private parentColumnSpace: number;
  private dirty = false;

  constructor(params: { availableSpacePx: number; parentColumnSpace: number }) {
    this.availableSpacePx = params.availableSpacePx;
    this.initialAvailableSpacePx = params.availableSpacePx;
    this.parentColumnSpace = params.parentColumnSpace;
  }

  private recalculateAvailableSpace() {
    this.availableSpacePx = this.initialAvailableSpacePx;
    Object.entries(this.adjustments).forEach(([widgetId, adjustment]) => {
      const widget = this.widgets[widgetId];
      const newWidth = adjustment.newWidth;
      const difference = Dimension.minus(
        widget.width,
        newWidth,
        this.parentColumnSpace,
      ).as("px");
      this.availableSpacePx += difference.value;
    });
    this.dirty = false;
  }

  private getAvailableSpacePx() {
    if (this.dirty) {
      this.recalculateAvailableSpace();
    }
    return this.availableSpacePx;
  }

  addAdjustment(
    widget: FlattenedWidgetLayoutProps,
    newWidth: Dimension<"gridUnit">,
  ) {
    this.adjustments[widget.widgetId] = { newWidth };
    this.widgets[widget.widgetId] = widget;
    const difference = Dimension.minus(
      widget.width,
      newWidth,
      this.parentColumnSpace,
    ).as("px");
    this.availableSpacePx += difference.value;
  }

  removeAdjustment(widgetId: string) {
    const adjustment = this.adjustments[widgetId];
    if (adjustment) {
      delete this.adjustments[widgetId];
      delete this.widgets[widgetId];
      this.dirty = true;
    }
  }

  getAdjustments() {
    return this.adjustments;
  }

  getOverflowPx() {
    return -1 * this.getAvailableSpacePx();
  }

  untrimWidgets(
    widgetsToUntrim: Array<WidgetToDrop | FlattenedWidgetLayoutProps>,
  ): AdjustmentManager {
    for (const widget of widgetsToUntrim) {
      this.removeAdjustment(widget.widgetId);
    }
    return this;
  }

  // trims the given widgets to try and fit the available space
  trimWidgets(
    widgetsToTrim: Array<WidgetToDrop | FlattenedWidgetLayoutProps>,
  ): AdjustmentManager {
    const removableSpaceForWidgets = widgetsToTrim.map((widget) => ({
      removable: getProspectiveRemovableSpace(widget),
      widget,
    }));
    const totalRemovableSpaceGu = removableSpaceForWidgets.reduce(
      (acc, space) =>
        acc + (space.removable satisfies Dimension<"gridUnit">).value,
      0,
    );
    const totalRemovableSpacePx =
      totalRemovableSpaceGu * this.parentColumnSpace;

    if (totalRemovableSpacePx > this.getOverflowPx()) {
      const spaceToTakePx = this.getOverflowPx();
      const toTakeRatio = spaceToTakePx / totalRemovableSpacePx; // we know this is < 1
      for (const removableSpace of removableSpaceForWidgets) {
        if (this.getOverflowPx() < STACK_ADJUSTMENT_EPSILON) {
          break;
        }
        if (removableSpace.widget.width.mode === "gridUnit") {
          const spaceToTakeGu = Math.ceil(
            (removableSpace.removable satisfies Dimension<"gridUnit">).value *
              toTakeRatio,
          ); // this will always be less than or equal to the removable amount

          // due to rounding issues, might end up not needing to remove as much space
          const roundingAdjustment = Math.floor(
            STACK_ADJUSTMENT_EPSILON +
              spaceToTakeGu -
              this.getOverflowPx() / this.parentColumnSpace,
          );

          const newWidth = Dimension.gridUnit(
            removableSpace.widget.width.value -
              spaceToTakeGu +
              Math.max(0, roundingAdjustment),
          );
          this.addAdjustment(removableSpace.widget, newWidth);
        }
      }
      return this;
    } else {
      // then take all the space away
      for (const removableSpace of removableSpaceForWidgets) {
        if (removableSpace.widget.width.mode === "gridUnit") {
          const toRemove: Dimension<"gridUnit"> = removableSpace.removable;
          const newWidth = Dimension.gridUnit(
            removableSpace.widget.width.value - toRemove.value,
          );
          this.addAdjustment(removableSpace.widget, newWidth);
        }
      }
      return this;
    }
  }
}
