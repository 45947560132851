import { WidgetProps } from '../page';

export enum UIBlocksStatus {
  PENDING = 'PENDING',
  PUBLIC = 'PUBLIC',
  DRAFT = 'DRAFT'
}

export type UIBlockDto = {
  id: string;
  name: string;
  imageUri: string;
  description: string;
  children: WidgetProps[];
  namespace: string;
  metadata: Record<string, unknown> | null;
  keywords: string[] | null;
  status: UIBlocksStatus;
  lastUpdatedByUserId: string | null;
  categories?: string[];
  created: Date;
  updated: Date;
};

export type UIBlockCategoryDto = {
  id: string;
  name: string;
  description: string;
  imageUri: string;
  metadata: Record<string, unknown> | null;
  uiBlocks: UIBlockDto[];
  lastUpdatedByUserId: string | null;
  isPublic: boolean;
  created: Date;
  updated: Date;
};
