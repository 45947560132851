import {
  Border,
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  Dimension,
  PerSideBorder,
} from "@superblocksteam/shared";
import { get } from "lodash";
import { TextAlign } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { BUTTON_PADDING } from "legacy/themes/constants";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ZERO_PADDING } from "../base/sizing";
import { LinkWidgetProps } from "./types";

export const textAlignmentDefaultValue = (props: LinkWidgetProps) =>
  props.linkStyle === "LINK" ? TextAlign.LEFT : TextAlign.CENTER;

export const textVariantDefaultValue = (props: LinkWidgetProps) =>
  props.linkStyle === "LINK" ? "label" : "buttonLabel";

export const textColorDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) => {
  // handle custom variant color
  const variant = props.textProps?.textStyle?.variant;
  if (variant && variant.startsWith(CUSTOM_THEME_TYPOGRAPHY_KEY)) {
    const customVariantColor = get(
      theme?.typographies,
      `${variant}.textColor.default`,
    );
    if (customVariantColor) {
      return customVariantColor;
    }
  }

  switch (props.linkStyle) {
    case "PRIMARY_BUTTON":
      return theme?.buttons.primary.textColor.default;
    case "SECONDARY_BUTTON":
      return theme?.buttons.secondary.textColor.default;
    case "TERTIARY_BUTTON":
      return theme?.buttons.tertiary.textColor.default;
    case "LINK":
      return theme?.typographies.link.textColor.default;
    default:
      return theme?.buttons.primary.textColor.default;
  }
};
export const backgroundColorDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) => {
  switch (props.linkStyle) {
    case "PRIMARY_BUTTON":
      return theme?.colors.primary500;
    case "SECONDARY_BUTTON":
    case "TERTIARY_BUTTON":
    case "LINK":
      return "transparent";
    default:
      return theme?.colors.primary500;
  }
};

export const paddingDefaultValue = (props: LinkWidgetProps) =>
  props.linkStyle === "LINK" ? ZERO_PADDING : BUTTON_PADDING;

export const borderRadiusDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) => {
  return createPerCornerBorderRadius(theme?.borderRadius ?? Dimension.px(4));
};

const DEFAULT_LINK_BORDER: Border = {
  width: Dimension.px(1),
  style: "solid",
  color: "transparent",
};

const createBorderObject = (override?: Partial<Border>): PerSideBorder => {
  return {
    left: { ...DEFAULT_LINK_BORDER, ...override },
    right: { ...DEFAULT_LINK_BORDER, ...override },
    top: { ...DEFAULT_LINK_BORDER, ...override },
    bottom: { ...DEFAULT_LINK_BORDER, ...override },
  };
};

const DEFAULT_LINK_BORDER_OBJECT = createBorderObject();

export const borderDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) =>
  props.linkStyle === "SECONDARY_BUTTON"
    ? createBorderObject({ color: theme?.colors.primary500 })
    : DEFAULT_LINK_BORDER_OBJECT;
