import React from "react";
import { Layers } from "legacy/constants/Layers";

export const ComponentBorder = ({
  borderStyle,
  className,
}: {
  borderStyle: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div
      data-test="component-border-overlay"
      className={className}
      style={{
        background: "transparent",
        position: "absolute",
        inset: "0px",
        borderRadius: "inherit",
        pointerEvents: "none",
        zIndex: Layers.componentBorder,
        ...borderStyle,
      }}
    ></div>
  );
};
