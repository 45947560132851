import CodeMirror from "codemirror";
import HJSON from "hjson";
import { CodeWidgetLanguages } from "legacy/constants/WidgetConstants";

CodeMirror.registerHelper(
  // Helper category is "lint", used by the lint extension
  "lint",
  // Helpers are looked up in a deterministic order based on the mode:
  // we are tagging all json-like modes as "javascript" with "json" enabled.
  "json",
  function (
    text: string,
    options: Record<string, unknown>,
    cm: CodeMirror.Editor,
  ) {
    const mode = cm.getOption("mode");
    const found: Array<{
      from: CodeMirror.Position;
      to: CodeMirror.Position;
      message: string;
    }> = [];
    if (mode === CodeWidgetLanguages.HJSON) {
      try {
        HJSON.parse(text);
        // If valid, return immediately
        return found;
      } catch (e: any) {
        const matches = (e.message as string).match(
          /(.*) at line (\d+),(\d+) >>>/,
        );
        if (matches?.[2] && matches?.[3]) {
          const line = Number(matches[2]) - 1;
          const column = Number(matches[3]) - 1;
          const hint = e.hint ?? "";
          found.push({
            from: CodeMirror.Pos(line, column),
            to: CodeMirror.Pos(Infinity, Infinity),
            message: `${matches[1]}${hint}`,
          });
        }
      }
    } else if (mode === CodeWidgetLanguages.JSON) {
      try {
        JSON.parse(text);
      } catch (e: any) {
        const matches = (e.message as string).match(/(.*) at position (\d+)/);
        if (matches?.[2]) {
          const offset = Number(matches[2]);
          found.push({
            from: cm.getDoc().posFromIndex(offset),
            to: CodeMirror.Pos(Infinity, Infinity),
            message: matches[1],
          });
        }
      }
    }
    return found;
  },
);
