import { UrlDataState } from "legacy/reducers/entityReducers/appReducer";
import { APP_MODE } from "legacy/reducers/types";
import { parseValidationBoolean } from "legacy/workers/validators/parseValidationBoolean";
import { buildLocalUrlFromRoute } from "utils/navigation/localNavigation";
import { buildURL } from "utils/navigation/shared";
import { getLocalizedUrl } from "../LinkWidget/localizeUrl";
import {
  ComponentMenuItem,
  LinkToType,
  ManualMenuItem,
  MenuChildrenType,
  MenuItemType,
} from "./types";
import type { RouteDef } from "@superblocksteam/shared";
import type { getSystemQueryParams } from "legacy/utils/queryParams";

type Context = {
  routes: { [id: string]: RouteDef };
  currentPageUrlState: UrlDataState;
  systemQueryParams: ReturnType<typeof getSystemQueryParams>;
  appMode: APP_MODE | undefined;
};

export function transformItem(
  item: ManualMenuItem,
  context: Context,
): ComponentMenuItem {
  if (item.type === MenuItemType.Divider) {
    return item;
  }

  if (item.childrenType === MenuChildrenType.Manual) {
    const children =
      item.manualChildren && item.manualChildren.length > 0
        ? item.manualChildren.map((child) => transformItem(child, context))
        : undefined;

    /**
     * Nested items don't support our native VALIDATION_TYPES yet.
     * Those validations not only validate values, but also parse them.
     * So we need to do the parsing here, until we properly support VALIDATION_TYPES in nested items.
     *
     * Biggest drawback here is we don't support showing a validation message in case of error.
     */
    const isVisible = parseValidationBoolean(item.isVisible).parsed;
    const isDisabled = parseValidationBoolean(item.isDisabled).parsed;

    switch (item.type) {
      case MenuItemType.Button: {
        return {
          type: item.type,
          label: item.label,
          icon: item.icon,
          iconPosition: item.iconPosition,
          openOn: item.openOn,
          isVisible,
          isDisabled,
          onClick: item.onClick,
          children,
        };
      }
      case MenuItemType.Link: {
        let href = "";
        let hasMissingRouteParams = undefined;
        let urlForInternalNavigation = undefined;

        if (item.linkTo === LinkToType.Page && item.routeId) {
          const route = context.routes[item.routeId];

          const result = buildLocalUrlFromRoute(route, {
            ...item,
            currentQueryParamsToKeep: context.currentPageUrlState.queryParams,
          });

          if (result.ok) {
            hasMissingRouteParams = false;

            const url = result.value;
            const localizedUrl = getLocalizedUrl({
              inputUrl: url.pathname + url.search,
              keepExistingQueryParams: false, // already applied
              currentPageUrlState: context.currentPageUrlState,
              systemQueryParams: context.systemQueryParams,
              appMode: context.appMode,
            });

            href = localizedUrl.url;
            urlForInternalNavigation = localizedUrl.urlForInternalNavigation;
          } else {
            hasMissingRouteParams = true;
          }
        } else if (item.linkTo === LinkToType.App) {
          const appUrl = buildURL({
            targetPath: item.targetApp.url,
            params: item.queryParams,
          });
          const { url } = getLocalizedUrl({
            inputUrl: appUrl.pathname + appUrl.search,
            keepExistingQueryParams: item.keepQueryParams,
            currentPageUrlState: context.currentPageUrlState,
            systemQueryParams: context.systemQueryParams,
            appMode: context.appMode,
          });

          href = url;
        } else if (item.linkTo === LinkToType.CustomUrl) {
          const { url, urlForInternalNavigation: _urlForInternalNavigation } =
            getLocalizedUrl({
              inputUrl: item.href,
              keepExistingQueryParams: false,
              currentPageUrlState: context.currentPageUrlState,
              systemQueryParams: context.systemQueryParams,
              appMode: context.appMode,
            });

          href = url;
          urlForInternalNavigation = _urlForInternalNavigation;
        }

        return {
          type: item.type,
          label: item.label,
          href: href,
          urlForInternalNavigation,
          icon: item.icon,
          iconPosition: item.iconPosition,
          targetBlank: item.targetBlank,
          children,
          hasMissingRouteParams,
          isVisible,
          isDisabled,
        };
      }
      default: {
        throw new Error(`Invalid menu item type for ${item}`);
      }
    }
  } else {
    throw new Error(`Non-manual children types are implemented yet`);
  }
}
