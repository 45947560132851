import { Tag, Tooltip } from "antd";
import React, { MouseEventHandler } from "react";
import styled, { css } from "styled-components";
import {
  CompactMode,
  CompactModeTypes,
} from "legacy/widgets/TableWidget/TableComponent/types";

/** the minimum horizontal space that should be available to a tag for it to look ok */
export const MIN_TAG_WIDTH = 32;

export const MARGIN_BETWEEN_TAGS = 4;

const StyledTag = styled(Tag)<{
  $bgColor: string;
  $fgColor: string;
  $borderColor: string;
  $compactMode?: CompactMode;
}>`
  margin-right: 0;
  margin-left: ${MARGIN_BETWEEN_TAGS}px;
  ${({ $compactMode }) => {
    if (
      $compactMode &&
      (
        [
          CompactModeTypes.VERY_SHORT,
          CompactModeTypes.SHORT,
        ] as Array<CompactMode>
      ).includes($compactMode)
    ) {
      return css`
        font-size: 12px;
        padding: 1px 8px;
      `;
    }
    return css`
      font-size: 14px;
      padding: 4px 8px;
    `;
  }}
  transition: none;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 32px;
  background-color: ${(props) => props.$bgColor};
  border: 1px solid ${(props) => props.$borderColor};
  color: ${(props) => props.$fgColor};

  &:first-of-type {
    margin-left: 0;
  }
`;

interface TableTagProps {
  text: string;
  bgColor: string;
  fgColor: string;
  borderColor: string;
  showTooltip?: boolean;
  compactMode?: CompactMode;
}

export const TableTag = ({
  text,
  bgColor,
  fgColor,
  borderColor,
  showTooltip,
  compactMode,
}: TableTagProps) => {
  return (
    <Tooltip title={showTooltip ? text : undefined}>
      <StyledTag
        $bgColor={bgColor}
        $fgColor={fgColor}
        $borderColor={borderColor}
        $compactMode={compactMode}
      >
        {text}
      </StyledTag>
    </Tooltip>
  );
};

interface TableTagsShowMoreProps {
  count: number;
  // the following 2 event handlers are needed for antd's Tooltip/Popover
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  compactMode: CompactMode;
}

export const TableTagsShowMore = ({
  count,
  onMouseEnter,
  onMouseLeave,
  compactMode,
}: TableTagsShowMoreProps) => {
  return (
    <StyledTag
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $bgColor="#eee"
      $fgColor="#000"
      $borderColor="#dedede"
      $compactMode={compactMode}
    >
      +{count}
    </StyledTag>
  );
};
