import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import type { VideoWidgetProps } from "./VideoWidget";

const config: PropertyPaneCategoryConfig<VideoWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "url",
      label: "URL",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter url",
      inputType: "TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection(),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "autoPlay",
      label: "Autoplay",
      helpText: "Video will be automatically played",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig("onPlay", "Triggers an action when the video is played"),
    getPopoverConfig("onPause", "Triggers an action when the video is paused"),
    getPopoverConfig("onEnd", "Triggers an action when the video ends"),
  ]),
];

export default config;
