import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { type FilePickerWidgetProps } from "./FilepickerWidget";

const config: PropertyPaneCategoryConfig<FilePickerWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "selectionType",
      label: "Selection type",
      controlType: "DROP_DOWN",
      options: [
        {
          label: "Single select",
          value: 1,
        },
        {
          label: "Multi select",
          value: undefined,
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      propertyName: "allowedFileTypes",
      helpText:
        "File types can either be mime types or suffixes. * is wildcard.",
      label: "Allowed file types",
      controlType: "INPUT_TEXT",
      placeholderText: "['image/*', '.csv', '.zip']",
      inputType: "ARRAY",
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Content,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      propertyName: "animateLoading",
      label: "Loading animation",
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({ heightSupportsFitContent: true }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes input to the component mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Interaction,
    },
  ]),
];

export default config;
