import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  widgetId: string;
  children: ReactNode;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalContentContainer: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return <Container>{children}</Container>;
};
