import tinycolor from "tinycolor2";
import { CLASS_NAMES } from "legacy/themes/classnames";

const HOVER_COLOR = "--hover-color";
const HOVER_BOX_SHADOW = "--hover-box-shadow";
const HOVER_BACKGROUND_COLOR = "--hover-background-color";
const HOVER_BORDER_LEFT_COLOR = "--hover-border-left-color";
const HOVER_BORDER_RIGHT_COLOR = "--hover-border-right-color";
const HOVER_BORDER_TOP_COLOR = "--hover-border-top-color";
const HOVER_BORDER_BOTTOM_COLOR = "--hover-border-bottom-color";

export const getHoverCss = (disabledSelector: string) => `
  &.${CLASS_NAMES.PRIMARY_BUTTON}:hover,
  &.${CLASS_NAMES.SECONDARY_BUTTON}:hover,
  &.${CLASS_NAMES.TERTIARY_BUTTON}:hover {
    &:not(${disabledSelector}) {
      background: var(${HOVER_BACKGROUND_COLOR}) !important;
      border-color: var(${HOVER_BORDER_TOP_COLOR})
                    var(${HOVER_BORDER_RIGHT_COLOR})
                    var(${HOVER_BORDER_BOTTOM_COLOR})
                    var(${HOVER_BORDER_LEFT_COLOR}) !important;
      box-shadow: var(${HOVER_BOX_SHADOW}) !important;
    }
  }

  &.${CLASS_NAMES.PRIMARY_BUTTON}:hover,
  &.${CLASS_NAMES.SECONDARY_BUTTON}:hover,
  &.${CLASS_NAMES.TERTIARY_BUTTON}:hover,
  &.${CLASS_NAMES.LINK}:hover {
    &:not(${disabledSelector}) {
      .button-content, .button-text {
        color: var(${HOVER_COLOR}) !important;
      }
    }
  }`;

const getHoverColor = (color: string) => {
  const overrideColor = tinycolor(color);

  if (overrideColor.getAlpha() === 0) {
    return color;
  }

  const shouldLighten = overrideColor.isDark();
  return shouldLighten
    ? overrideColor.lighten(15).toHexString()
    : overrideColor.darken(15).toHexString();
};

export const generateHoverVariableAssignmentStyleObject = ({
  borderStyleObject,
  backgroundColor,
  fallbackBackgroundColor,
  textColor,
  fallbackTextColor,
}: {
  borderStyleObject: React.CSSProperties;
  backgroundColor?: string;
  fallbackBackgroundColor?: string;
  textColor?: string;
  fallbackTextColor?: string;
}): { [key: `--${string}`]: string } => {
  const backgroundTinyColor = tinycolor(
    backgroundColor ?? fallbackBackgroundColor,
  );
  const backgroundIsOpaque = backgroundTinyColor.getAlpha() !== 0;

  const hoverBoxShadow = backgroundIsOpaque
    ? `0px 3px 8px 0px ${backgroundTinyColor.setAlpha(0.32).toRgbString()}`
    : "";

  let hoverColor;
  if (textColor) {
    if (backgroundIsOpaque) {
      hoverColor = textColor;
    } else {
      hoverColor = getHoverColor(textColor);
    }
  } else {
    hoverColor = fallbackTextColor ?? "inherit";
  }

  const hoverBackgroundColor = backgroundColor
    ? getHoverColor(backgroundColor)
    : (fallbackBackgroundColor ?? "inherit");

  const hoverBorderTopColor = getHoverColor(
    borderStyleObject.borderTopColor as string,
  );
  const hoverBorderRightColor = getHoverColor(
    borderStyleObject.borderRightColor as string,
  );
  const hoverBorderBottomColor = getHoverColor(
    borderStyleObject.borderBottomColor as string,
  );
  const hoverBorderLeftColor = getHoverColor(
    borderStyleObject.borderLeftColor as string,
  );

  return {
    [HOVER_BOX_SHADOW]: hoverBoxShadow,
    [HOVER_COLOR]: hoverColor,
    [HOVER_BACKGROUND_COLOR]: hoverBackgroundColor,
    [HOVER_BORDER_TOP_COLOR]: hoverBorderTopColor,
    [HOVER_BORDER_RIGHT_COLOR]: hoverBorderRightColor,
    [HOVER_BORDER_BOTTOM_COLOR]: hoverBorderBottomColor,
    [HOVER_BORDER_LEFT_COLOR]: hoverBorderLeftColor,
  };
};
