import { useCallback } from "react";
import { useStore } from "react-redux";
import { Store } from "redux";
import { UpdateCanvasHeightPayload } from "legacy/actions/controlActions";
import {
  selectWidgets,
  focusWidget,
  unfocusWidget,
} from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import {
  getSingleSelectedWidget,
  getIsWidgetSelected,
  getSelectedWidgetsParentId,
  getIsMultipleWidgetsSelected,
} from "legacy/selectors/sagaSelectors";
import { useAppDispatch } from "store/helpers";
import { type AppState } from "store/types";
import type { WidgetProps } from "legacy/widgets";

export const useCanvasSnapRowsUpdateHook = () => {
  const dispatch = useAppDispatch();
  const updateCanvasSnapRows = useCallback(
    (canvasWidgetId: string, gridRows: number) => {
      dispatch({
        type: ReduxActionTypes.UPDATE_CANVAS_SIZE,
        payload: {
          canvasWidgetId,
          gridRows,
        } satisfies UpdateCanvasHeightPayload,
      });
    },
    [dispatch],
  );
  return updateCanvasSnapRows;
};

export const useWidgetSelection = () => {
  const dispatch = useAppDispatch();

  return {
    selectWidgets: useCallback(
      (widgetIds: string[], isSelectingMultiple?: boolean) => {
        dispatch(selectWidgets(widgetIds, isSelectingMultiple));
      },
      [dispatch],
    ),
    focusWidget: useCallback(
      (widgetId?: string) => dispatch(focusWidget(widgetId)),
      [dispatch],
    ),
    unfocusWidget: useCallback(() => dispatch(unfocusWidget()), [dispatch]),
  };
};

export const useSelectWidget = (widget: WidgetProps) => {
  const dispatch = useAppDispatch();
  const store = useStore<AppState>();

  return useCallback(
    (e: React.MouseEvent) => {
      e?.stopPropagation();

      const state = store.getState();
      // This state tells us whether a `ResizableComponent` is resizing
      const isResizing = state.legacy.ui.widgetDragResize.isResizing;
      // This state tells us whether a `DraggableComponent` is dragging
      const isDragging = state.legacy.ui.widgetDragResize.isDragging;
      const singleSelectedWidget = getSingleSelectedWidget(state);
      const isMultipleSelected = getIsMultipleWidgetsSelected(state);
      const selectedWidgetsParentId = getSelectedWidgetsParentId(state);
      const isSelected = getIsWidgetSelected(state, widget.widgetId);
      const isResizingOrDragging = !!isResizing || !!isDragging;

      if (isResizingOrDragging) return;

      const selectingMultiple = e?.shiftKey;

      // If we're selecting multiple, there are edge cases to handle:

      const singleSelectedWidgetIsModalOrSlideout = [
        WidgetTypes.MODAL_WIDGET,
        WidgetTypes.SLIDEOUT_WIDGET,
      ].includes(singleSelectedWidget?.type as any); // includes types are kinda goofy and restrictive.

      // 1. Don't select if we're trying to add a new widget to our multi-select
      // that is not on the same level (ex: select a container and one of its children)
      // in the same multi-select group
      if (
        selectingMultiple &&
        !singleSelectedWidgetIsModalOrSlideout &&
        selectedWidgetsParentId !== undefined &&
        selectedWidgetsParentId !== widget.parentId
      ) {
        return;
      }

      // 2. We make an exception for trying to multi-select children of modals and slideouts,
      // because the modal or slideout is automatically selected and cannot be unselected.
      // So we let the user start a multi-selection on modal or slideout child, but just select
      // the child alone, without the parent in the multi-selection.
      if (
        selectingMultiple &&
        singleSelectedWidgetIsModalOrSlideout &&
        !isSelected
      ) {
        dispatch(selectWidgets([widget.widgetId], false));
        return;
      }

      // 3. If we're not selecting multiple and this component is not already selected
      // we can safely just ignore
      if (!selectingMultiple && !isMultipleSelected && isSelected) return;

      // Otherwise, select away!
      dispatch(selectWidgets([widget.widgetId], selectingMultiple));
    },
    // Be very careful updating this deps array, it can cause expensive renders
    [store, widget.widgetId, widget.parentId, dispatch],
  );
};

export const useWidgetDragResize = () => {
  const dispatch = useAppDispatch();
  const store = useStore<AppState>();
  return {
    setIsDragging: useCallback(
      (isDragging: boolean, isDraggingNewWidget = false) => {
        if (isDragging) {
          document.body.classList.add("dragging");
        } else {
          document.body.classList.remove("dragging");
        }

        const dispatchDrag = () =>
          dispatch({
            type: ReduxActionTypes.SET_WIDGET_DRAGGING,
            payload: { isDragging, isDraggingNewWidget },
          });

        // Update redux state immediately if not dragging
        if (isDragging) {
          dispatchDrag();
          return;
        }

        // If dragging, wait for any operations to finish before updating redux
        // We need to wait for operations to finish because
        // we could see a flicker if we update redux while an operation is running
        runAfterWidgetOperationsAreDone(store, dispatchDrag);
      },
      [dispatch, store],
    ),
    setIsResizing: useCallback(
      (isResizing: boolean, callback?: () => any) => {
        const dispatchResize = () => {
          dispatch({
            type: ReduxActionTypes.SET_WIDGET_RESIZING,
            payload: { isResizing },
          });
          callback?.();
        };

        if (isResizing) {
          dispatchResize();
        } else {
          // If actively resizing, wait for any operations to finish before updating redux
          // We need to wait for operations to finish because
          // we could see a flicker if we update redux while an operation is running
          runAfterWidgetOperationsAreDone(store, dispatchResize);
        }
      },
      [dispatch, store],
    ),
    setWidgetResizingDimensions: useCallback(
      (size: { width: number; height: number }) => {
        dispatch({
          type: ReduxActionTypes.SET_WIDGET_RESIZING_DIMENSIONS,
          payload: size,
        });
      },
      [dispatch],
    ),
    setResizingStackWidth: useCallback(
      (resizingStackWidth: number | null | undefined) => {
        dispatch({
          type: ReduxActionTypes.SET_WIDGET_RESIZING_STACK_WIDTH,
          payload: { resizingStackWidth },
        });
      },
      [dispatch],
    ),
    setCurrentDropTarget: useCallback(
      (dropTargetId?: string) => {
        dispatch({
          type: ReduxActionTypes.SET_DRAGGING_DROP_TARGET,
          payload: { dropTargetId },
        });
      },
      [dispatch],
    ),
    setCurrentDropTargetRows: useCallback(
      (dropTargetRows?: number) => {
        dispatch({
          type: ReduxActionTypes.SET_DRAGGING_DROP_TARGET_ROWS,
          payload: { dropTargetRows },
        });
      },
      [dispatch],
    ),
  };
};

const TIMEOUT_MS = 5;
const MAX_TIMEOUT_MS = 2000;

export const runAfterWidgetOperationsAreDone = (
  store: Store<AppState>,
  runner: () => unknown,
) => {
  const tryToUpdate = (sumTimeoutMs: number) => {
    const opRunning = Boolean(
      store.getState().legacy.ui.editor.widgetOperations.runningOperation,
    );
    if (!opRunning || sumTimeoutMs > MAX_TIMEOUT_MS) {
      runner();
    } else {
      setTimeout(() => {
        tryToUpdate(sumTimeoutMs + TIMEOUT_MS);
      }, TIMEOUT_MS);
    }
  };
  setTimeout(() => {
    tryToUpdate(0);
  }, 0);
};
