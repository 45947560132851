import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { LoadingAnimator } from "../shared";

const LoadingAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;
`;

const LoadingMessagBubble = styled.div<{
  width: string;
}>`
  width: ${(props) => props.width};
  height: 100%;
  border-radius: 4px;
`;
const LoadingMessage = styled.div<{ height: string }>`
  display: flex;
  height: ${(props) => props.height};
  margin-bottom: 16px;
  > div:first-child {
    margin-right: 12px;
  }
  align-items: end;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
`;

const ChatLoading = ({ width }: { width: number }) => {
  const generatedTheme = useSelector(selectGeneratedTheme);
  const style = useMemo(() => {
    return { background: generatedTheme.colors.neutral50 };
  }, [generatedTheme.colors.neutral50]);

  return (
    <Wrapper style={{ padding: 16 }} className={CLASS_NAMES.DEFAULT_CONTAINER}>
      <LoadingMessage height={"10%"}>
        <LoadingAvatar style={style} />
        <LoadingMessagBubble width={"60%"} style={style} />
      </LoadingMessage>

      <LoadingMessage height={"30%"}>
        <LoadingAvatar style={style} />
        <LoadingMessagBubble width={"70%"} style={style} />
      </LoadingMessage>

      <LoadingMessage height={"12%"}>
        <LoadingAvatar style={style} />
        <LoadingMessagBubble width={"65%"} style={style} />
      </LoadingMessage>

      <LoadingMessage height={"10%"}>
        <LoadingAvatar style={style} />
        <LoadingMessagBubble width={"40%"} style={style} />
      </LoadingMessage>
      <LoadingAnimator $width={width} bgColor={generatedTheme.colors.neutral} />
    </Wrapper>
  );
};

export default ChatLoading;
