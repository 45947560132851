import {
  Border,
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  Dimension,
  PerSideBorder,
  type Typographies,
} from "@superblocksteam/shared";
import { get } from "lodash";
import type { MenuWidgetProps } from "./MenuWidget";
import type { GeneratedTheme } from "legacy/themes/types";

export const DEFAULT_MENU_WIDGET_BUTTON_STYLE_VARIANT: keyof Typographies =
  "buttonLabel";
export const DEFAULT_MENU_WIDGET_MENU_ITEM_STYLE_VARIANT: keyof Typographies =
  "body2";

const DEFAULT_MENU_BORDER: Border = {
  width: Dimension.px(1),
  style: "solid",
  color: "transparent",
};

const createBorderObject = (override?: Partial<Border>): PerSideBorder => {
  return {
    left: { ...DEFAULT_MENU_BORDER, ...override },
    right: { ...DEFAULT_MENU_BORDER, ...override },
    top: { ...DEFAULT_MENU_BORDER, ...override },
    bottom: { ...DEFAULT_MENU_BORDER, ...override },
  };
};

const BUTTON_BORDER_DEFAULT_VALUE = createBorderObject();

export const borderDefaultValue = (
  props: MenuWidgetProps,
  theme?: GeneratedTheme,
) =>
  props.buttonProps.buttonStyle === "SECONDARY_BUTTON"
    ? createBorderObject({ color: theme?.colors.primary500 })
    : BUTTON_BORDER_DEFAULT_VALUE;

export const textColorDefaultValue = (
  props: MenuWidgetProps,
  theme?: GeneratedTheme,
) => {
  // handle custom variant color
  const variant = props.buttonProps.textStyle?.variant;
  if (variant && variant.startsWith(CUSTOM_THEME_TYPOGRAPHY_KEY)) {
    const customVariantColor = get(
      theme?.typographies,
      `${variant}.textColor.default`,
    );
    if (customVariantColor) {
      return customVariantColor;
    }
  }

  switch (props.buttonProps.buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme?.buttons.primary.textColor.default;
    case "SECONDARY_BUTTON":
      return theme?.buttons.secondary.textColor.default;
    case "TERTIARY_BUTTON":
      return theme?.buttons.tertiary.textColor.default;
    default:
      return theme?.buttons.primary.textColor.default;
  }
};
