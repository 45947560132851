import { Typographies } from "@superblocksteam/shared";
import { TextAlign, VerticalAlign } from "legacy/constants/WidgetConstants";
import { TextStyleWithVariant } from "legacy/themes";
import { WidgetPropsRuntime } from "../BaseWidget";

// "legacy" because this mapping is hard coded specific to the TextWidget, but
// as of the Typography project, we use typography variant names from the theme
export const legacyTextWidgetTextStyleToTypographyMap: Record<
  TextStyle,
  keyof Typographies
> = {
  HEADING_1: "heading3",
  HEADING_2: "heading4",
  LABEL: "label",
  NORMAL_TEXT: "body2",
};

export const getMissingTextStyleVariant = (props: Partial<TextWidgetProps>) => {
  return legacyTextWidgetTextStyleToTypographyMap[props.textStyle ?? "LABEL"];
};

export const getTextStyleVariant = (props: Partial<TextWidgetProps>) => {
  if (props.textStyle != null && props.textProps?.textStyle?.variant == null) {
    return legacyTextWidgetTextStyleToTypographyMap[props.textStyle];
  } else if (props.textProps?.textStyle != null) {
    return props.textProps.textStyle.variant;
  }
  return undefined;
};

export const getDefaultTextColor = (props: TextWidgetProps): string => {
  if (
    props.textColor != null &&
    props.textProps?.textStyle?.textColor?.default == null
  ) {
    return props.textColor;
  } else if (props.textProps?.textStyle?.textColor?.default != null) {
    return props.textProps.textStyle.textColor.default;
  }

  return props.textColor;
};

export const DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT: keyof Typographies =
  "label";

type TextType = "text" | "number" | "currency" | "date";
export type TextStyle = "HEADING_1" | "HEADING_2" | "LABEL" | "NORMAL_TEXT";

export interface TextWidgetProps extends WidgetPropsRuntime {
  text?: string;
  textAlign: TextAlign;
  textColor: string;
  textStyle: TextStyle;
  textProps?: {
    textStyle: TextStyleWithVariant;
  };
  verticalAlign: VerticalAlign;
  textType: TextType;
  notation?: Intl.NumberFormatOptions["notation"] | "unformatted";
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  dateInputFormat: string;
  dateOutputFormat: string;
  isLoading: boolean;
  animateLoading: boolean;
  shouldScroll: boolean;
  icon?: string;
  iconPosition?: string;
}
