import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { Layers } from "legacy/constants/Layers";
import {
  WIDGETS_WITH_PADDED_FOCUS,
  WIDGET_PADDED_FOCUS_PX,
} from "legacy/constants/WidgetConstants";
import {
  isChildADropTarget,
  selectIsDragging,
} from "legacy/selectors/dndSelectors";
import {
  getIsWidgetSelected,
  getIsWidgetFocused,
  isNonCanvasChildSelected,
} from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { type AppState } from "store/types";
import type { WidgetProps } from "legacy/widgets";

const SharedWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: ${Layers.selectedWrapper};
`;

const FocusedWrapper = styled(SharedWrapper)`
  border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  border-radius: 0px;
  inset: -1px;
`;

const SelectedWrapper = styled(SharedWrapper)`
  border: 2px solid ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  border-radius: 0px;
  inset: -1px;
`;

// A dashed border for the parent selected widget
const ParentSelectedWrapper = styled(SharedWrapper)`
  border: 1px dashed
    ${({ theme }) =>
      tinycolor(theme.colors.ACCENT_BLUE_500).setAlpha(0.5).toRgbString()};
  border-radius: 0px;
  inset: 0px;
  z-index: ${Layers.parentSelectedWrapper};
`;

// This element will receive events behind the widget for focus and selection
const PaddedFocusWrapper = styled.div`
  position: absolute;
  background: transparent;
  inset: -${WIDGET_PADDED_FOCUS_PX}px;
  pointer-events: auto;
  z-index: -1; /*Ensure the pseudo-element is behind the content*/
`;

type SelectionAndFocusWrapperProps = {
  widgetType: WidgetProps["type"];
  widgetId: WidgetProps["widgetId"];
  widgetName: WidgetProps["widgetName"];
  focusedRectInset?: React.CSSProperties["inset"];
  selectedRectInset?: React.CSSProperties["inset"];
  parentRectInset?: React.CSSProperties["inset"];
  disabled?: boolean;
  hasInvalidProps?: boolean; // FIXME: Can this be removed?
};

const SelectionAndFocusWrapper = (props: SelectionAndFocusWrapperProps) => {
  const isSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, props.widgetId),
  );
  const isChildSelected = useAppSelector((state) =>
    isNonCanvasChildSelected(state, props.widgetId),
  );
  const isChildACurrentDropTarget = useSelector((state: AppState) =>
    isChildADropTarget(state, props.widgetId),
  );
  const isDragging = useSelector(selectIsDragging);
  const isFocused = useSelector((state: AppState) =>
    getIsWidgetFocused(state, props.widgetId),
  );

  const focusIsPadded = WIDGETS_WITH_PADDED_FOCUS.includes(props.widgetType);
  const focusPaddedAndNotSelected = focusIsPadded && !isSelected;

  return (
    <>
      {!props.disabled && !isDragging && isFocused && (
        <>
          <FocusedWrapper
            data-test={`widget-focused-${props.widgetName}`}
            style={{
              inset: isSelected
                ? props.selectedRectInset
                : props.focusedRectInset,
            }}
          />
          {focusPaddedAndNotSelected && (
            <PaddedFocusWrapper data-test="padded-focus-wrapper" />
          )}
        </>
      )}
      {!props.disabled && isSelected && (
        <>
          <SelectedWrapper
            data-test={`widget-selected-${props.widgetName}`}
            style={{
              inset: props.selectedRectInset,
            }}
          />
        </>
      )}
      {!isSelected &&
        !isFocused &&
        (isChildSelected || isChildACurrentDropTarget) && (
          <ParentSelectedWrapper
            data-test={`parent-selected-${props.widgetName}`}
            style={{
              inset: props.parentRectInset,
            }}
          />
        )}
    </>
  );
};

SelectionAndFocusWrapper.displayName = "SelectionAndFocusWrapper";

export default SelectionAndFocusWrapper;
