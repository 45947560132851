import { Padding } from "@superblocksteam/shared";
import { CSSProperties } from "react";
import { css } from "styled-components";

export const generatePaddingCss = (padding?: Padding) => css`
  padding-left: ${padding?.left?.value ?? 0}${padding?.left?.mode ?? "px"};
  padding-right: ${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"};
  padding-top: ${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"};
  padding-bottom: ${padding?.bottom?.value ?? 0}${padding?.bottom?.mode ?? "px"};
`;

export const generatePaddingStyleObject = (
  padding?: Padding,
): CSSProperties => ({
  paddingLeft: `${padding?.left?.value ?? 0}${padding?.left?.mode ?? "px"}`,
  paddingRight: `${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"}`,
  paddingTop: `${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"}`,
  paddingBottom: `${padding?.bottom?.value ?? 0}${
    padding?.bottom?.mode ?? "px"
  }`,
});

export const generateVerticallyCenteredOffsetWithinPaddingCss = ({
  padding,
  additionalOffsetPx,
}: {
  padding?: Padding;
  additionalOffsetPx?: number;
}) => {
  return css`
    calc(
      50% - (${padding?.bottom?.value ?? 0}${
        padding?.bottom?.mode ?? "px"
      } / 2) +
        (${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"} / 2) - ${
          additionalOffsetPx ?? 0
        }px
    )
  `;
};

const INPUT_PADDING_TOP = "--padding-top";
const INPUT_PADDING_RIGHT = "--padding-right";
const INPUT_PADDING_BOTTOM = "--padding-bottom";
const INPUT_PADDING_LEFT = "--padding-left";

export const generatePaddingVariableDeclarationCss = () => `
  padding-left: var(${INPUT_PADDING_LEFT});
  padding-right: var(${INPUT_PADDING_RIGHT});
  padding-top: var(${INPUT_PADDING_TOP});
  padding-bottom: var(${INPUT_PADDING_BOTTOM});
`;

export const generatePaddingVariableAssignmentStyleObject = (
  padding?: Padding,
): { [key: `--${string}`]: string } => ({
  [INPUT_PADDING_TOP]: `${padding?.top?.value ?? 0}${
    padding?.top?.mode ?? "px"
  }`,
  [INPUT_PADDING_RIGHT]: `${padding?.right?.value ?? 0}${
    padding?.right?.mode ?? "px"
  }`,
  [INPUT_PADDING_BOTTOM]: `${padding?.bottom?.value ?? 0}${
    padding?.bottom?.mode ?? "px"
  }`,
  [INPUT_PADDING_LEFT]: `${padding?.left?.value ?? 0}${
    padding?.left?.mode ?? "px"
  }`,
});
