import { ApplicationScope } from "@superblocksteam/shared";
import {
  EventType,
  ExecuteActionCallStack,
  MultiStepDef,
  RunEventHandlersPayload,
} from "legacy/constants/ActionConstants";
import { eventNameToSpanName } from "tracing/utils";

// the type of the argument of `createRunEventHandlersPayload`
export interface CreateRunEventHandlersPayloadArgs {
  steps: MultiStepDef;
  type: EventType;
  currentScope: ApplicationScope;
  entityName: string;
  /** This is the user visible trigger label. If it is not specified, one will be created automatically from `type`. */
  triggerLabel?: string;
  callStack?: ExecuteActionCallStack;
  callbackId?: string;
  spanId?: string;
  additionalNamedArguments?: Record<string, unknown>;
  additionalEventAttributes?: Record<string, string>;
}

/**
 * Creates a payload for the `RUN_EVENT_HANDLERS` action. It takes care of adding the event
 * to the call stack and creating an appropriate property path.
 */
export function createRunEventHandlersPayload({
  steps,
  type,
  currentScope,
  entityName,
  triggerLabel,
  callStack = [],
  callbackId,
  spanId,
  additionalNamedArguments = {},
  additionalEventAttributes,
}: CreateRunEventHandlersPayloadArgs): RunEventHandlersPayload {
  const propertyPath = `${entityName}.${
    triggerLabel ?? eventNameToSpanName(type)
  }`;
  return {
    steps,
    event: {
      type,
      callStack: [{ type, propertyPath }, ...callStack],
      callbackId,
      spanId,
    },
    currentScope,
    propertyPath,
    additionalNamedArguments,
    additionalEventAttributes,
  };
}

// the type of the argument of `createRunEventHandlersPayloadOptional`
interface CreateRunEventHandlersPayloadOptionalArgs
  extends Omit<CreateRunEventHandlersPayloadArgs, "steps"> {
  steps?: CreateRunEventHandlersPayloadArgs["steps"];
}

/**
 * Similar to `createRunEventHandlersPayload`, but the `steps` argument is optional. If `steps` is undefined, this function returns undefined.
 */
export function createRunEventHandlersPayloadOptional(
  args: CreateRunEventHandlersPayloadOptionalArgs,
): RunEventHandlersPayload | undefined {
  return !args.steps
    ? undefined
    : createRunEventHandlersPayload(args as CreateRunEventHandlersPayloadArgs);
}
