import { isArray } from "lodash";
import React, { useMemo } from "react";
import Popper from "components/ui/Popper";
import { Layers } from "legacy/constants/Layers";
import logger from "utils/logger";

interface Props {
  isOpen: boolean;
  messages: string | string[];
  wrapperClassName?: string;
  attachTo: Element | null | undefined;
}

const ErrorTooltip = (props: Props) => {
  const messages = useMemo(() => {
    const rawData = props.messages;
    let msgArray = isArray(rawData) ? rawData.filter((msg) => !!msg) : rawData;
    if (
      isArray(msgArray) &&
      !msgArray.every((msg) => typeof msg === "string")
    ) {
      // TODO: Once we figure out why this is crashing, fix it. For now this will not crash the component.
      msgArray = [];
      logger.error(
        `Debugging component crash: error tooltip was called with ${JSON.stringify(
          rawData,
        )}`,
      );
    }
    return msgArray;
  }, [props.messages]);

  if (!props.attachTo || !props.isOpen || !messages?.length) return null;

  return (
    <Popper
      targetNode={props.attachTo}
      isOpen={props.isOpen && !!messages?.length}
      placement="bottom"
      zIndex={Layers.max}
    >
      <div className={`${props.wrapperClassName} error-tooltip-popover`}>
        {isArray(messages) ? (
          messages.length > 0 ? (
            <ul>
              {messages.map((msg) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          ) : undefined
        ) : (
          String(messages)
        )}
      </div>
    </Popper>
  );
};

export default ErrorTooltip;
