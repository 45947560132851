import { Dimension } from "@superblocksteam/shared";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import tinycolor from "tinycolor2";
import { Layers } from "legacy/constants/Layers";
import {
  GridDefaults,
  WidgetWidthConstraintType,
  WidgetWidthModes,
} from "legacy/constants/WidgetConstants";
import useWidgetWidthConstraintResize from "./useWidgetWidthConstraintResize";

const resizingCSS = css`
  background-color: ${(props) => props.theme.colors.ACCENT_BLUE_700};
  box-shadow: 0 0 0 3px
    ${(props) =>
      tinycolor(props.theme.colors.ACCENT_BLUE_500)
        .setAlpha(0.5)
        .toRgbString()};
`;

const RIGHT_OFFSET = 6;
const CURSOR_STYLE = "col-resize";
const EXTRA_LENGTH = 52;

const WidthResizerLine = styled.div`
  width: 12px;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: ${Layers.selectionConstraintResize};
  pointer-events: all;
  cursor: ${CURSOR_STYLE};

  &[data-is-disabled="false"] {
    &:hover > .WidthResizerLineInner {
      ${resizingCSS};
    }
  }
`;

const WidthResizerLineInner = styled.div`
  pointer-events: none;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;

  &[data-is-resizing="true"] {
    ${resizingCSS};
  }

  &[data-constraint-type="minWidth"],
  &[data-constraint-type="maxWidth"] {
    background: linear-gradient(
      to bottom,
      transparent 50%,
      ${(props) => props.theme.colors.BLUE_MINMAX_LABELS} 50%
    );
    background-size: 1px 6px;
  }
`;

const ConstraintLabelContainer = styled.div`
  position: absolute;
  bottom: -14px;
  right: -16px;
  transform: translateY(-50%) rotate(90deg);
  background-color: ${(props) => props.theme.colors.BLUE_MINMAX_LABELS};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 20px;
  padding: 0 8px 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    top: 0;
    left: -7px;
  }

  svg > path {
    fill: ${(props) => props.theme.colors.BLUE_MINMAX_LABELS};
  }
`;

const ConstraintLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.WHITE};
`;

type WidgetWidthConstraintResizerProps = {
  width: Dimension<WidgetWidthModes>;
  isResizing: boolean;
  constraintLabel?: string;
  setIsResizingConstraintWidth: (
    isResizing: boolean,
    constraintType: WidgetWidthConstraintType,
  ) => void;
  setResizingWidth: (size: number) => void;
  constraintType: WidgetWidthConstraintType;
  onResizeEnded: (
    constraintType: WidgetWidthConstraintType,
    width: Dimension<"px">,
  ) => void;
  disabled?: boolean;
  showDisabledMessage?: () => void;
  widgetRelativePosition?: {
    top: number;
    left: number;
    height: number;
  };
  paddingOffsetPx?: number;
};

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

const ResizerLabelArrow: React.FC = () => {
  return (
    <svg
      width="8"
      height="20"
      viewBox="0 0 8 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49711 1.25722C3.80084 0.497903 4.53626 0 5.35407 0H8V20H5.35407C4.53626 20 3.80084 19.5021 3.49711 18.7428L0.297113 10.7428C0.106384 10.266 0.106384 9.73404 0.297113 9.25722L3.49711 1.25722Z"
        fill="transparent"
      />
    </svg>
  );
};

const WidgeWidthConstraintResizer = ({
  width,
  isResizing,
  constraintLabel,
  setIsResizingConstraintWidth,
  setResizingWidth,
  constraintType,
  onResizeEnded,
  disabled = false,
  showDisabledMessage,
  widgetRelativePosition,
  paddingOffsetPx,
}: WidgetWidthConstraintResizerProps) => {
  const { startDrag } = useWidgetWidthConstraintResize({
    width,
    setIsResizingConstraintWidth,
    setResizingWidth,
    constraintType,
    onResizeEnded,
  });

  const widthValuePx = Dimension.toPx(
    width,
    GridDefaults.DEFAULT_GRID_COLUMN_WIDTH,
  ).value;

  const showDisabledMessageInternal = React.useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation();
      showDisabledMessage?.();
    },
    [showDisabledMessage],
  );

  useEffect(() => {
    document.body.style.cursor = isResizing ? CURSOR_STYLE : "";

    return () => {
      document.body.style.cursor = "";
    };
  }, [isResizing]);

  return (
    <WidthResizerLine
      data-test="widget-width-resizer"
      data-is-disabled={disabled}
      onClick={stopPropagation}
      onPointerDown={disabled ? showDisabledMessageInternal : startDrag}
      style={{
        left:
          (widgetRelativePosition?.left ?? 0) +
          widthValuePx -
          RIGHT_OFFSET +
          (paddingOffsetPx ?? 0),
        top: widgetRelativePosition?.top ?? 0,
        height: widgetRelativePosition?.height
          ? `calc(${widgetRelativePosition.height}px + ${EXTRA_LENGTH}px)`
          : `calc(100% + ${EXTRA_LENGTH}px)`,
      }}
    >
      <WidthResizerLineInner
        className="WidthResizerLineInner"
        data-constraint-type={constraintType}
        data-is-resizing={isResizing}
      >
        {constraintLabel && (
          <ConstraintLabelContainer>
            <ResizerLabelArrow />
            <ConstraintLabel>{constraintLabel}</ConstraintLabel>
          </ConstraintLabelContainer>
        )}
      </WidthResizerLineInner>
    </WidthResizerLine>
  );
};

export default WidgeWidthConstraintResizer;
