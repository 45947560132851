import { PerSideBorder } from "@superblocksteam/shared";
import { GeneratedTheme } from "legacy/themes";
import { ButtonStyle } from "./types";

export const getButtonThemeDefaultTextColor = (
  theme: GeneratedTheme,
  buttonStyle?: ButtonStyle,
) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme.buttons.primary.textColor.default;
    case "SECONDARY_BUTTON":
      return theme.buttons.secondary.textColor.default;
    case "TERTIARY_BUTTON":
      return theme.buttons.tertiary.textColor.default;

    default:
      return theme.buttons.primary.textColor.default;
  }
};

export const getButtonThemeDefaultBackgroundColor = (
  theme: GeneratedTheme,
  buttonStyle?: ButtonStyle,
) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme.buttons.primary.backgroundColor.default;
    case "SECONDARY_BUTTON":
      return theme.buttons.secondary.backgroundColor.default;
    case "TERTIARY_BUTTON":
      return theme.buttons.tertiary.backgroundColor.default;

    default:
      return theme.buttons.primary.backgroundColor.default;
  }
};

export const getButtonThemeDefaultHoverBackgroundColor = (
  theme: GeneratedTheme,
  buttonStyle?: ButtonStyle,
) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme.buttons.primary.backgroundColor.hover;
    case "SECONDARY_BUTTON":
      return theme.buttons.secondary.backgroundColor.hover;
    case "TERTIARY_BUTTON":
      return theme.buttons.tertiary.backgroundColor.hover;
    default:
      return theme.buttons.primary.backgroundColor.hover;
  }
};

export const getButtonThemeDefaultHoverTextColor = (
  theme: GeneratedTheme,
  buttonStyle?: ButtonStyle,
) => {
  switch (buttonStyle) {
    case "PRIMARY_BUTTON":
      return theme.buttons.primary.textColor.hover;
    case "SECONDARY_BUTTON":
      return theme.buttons.secondary.textColor.hover;
    case "TERTIARY_BUTTON":
      return theme.buttons.tertiary.textColor.hover;
    default:
      return theme.buttons.primary.textColor.hover;
  }
};

export const getFallbackBorderColor = (
  buttonStyle: ButtonStyle | undefined,
  backgroundColor: string,
  themeColor: string,
) => {
  if (buttonStyle === "PRIMARY_BUTTON") {
    return backgroundColor;
  } else if (buttonStyle === "SECONDARY_BUTTON") {
    return themeColor;
  }
  return "transparent";
};

export const getButtonBorderStyleObjectV1 = ({
  border,
  isLoadingColor,
  buttonStyle,
  isLoading,
  fallbackBorderColor,
  backgroundColorIfSet,
}: {
  border: PerSideBorder | undefined;
  isLoadingColor: string;
  fallbackBorderColor: string;
  buttonStyle: ButtonStyle | undefined;
  isLoading: boolean;
  backgroundColorIfSet: string | undefined;
}) => {
  const borderStyleObject: React.CSSProperties = {};

  if (!border) {
    borderStyleObject.borderStyle = "solid";
  }

  if (isLoading) {
    borderStyleObject.borderLeftColor = isLoadingColor;
    borderStyleObject.borderRightColor = isLoadingColor;
    borderStyleObject.borderTopColor = isLoadingColor;
    borderStyleObject.borderBottomColor = isLoadingColor;
  } else if (buttonStyle === "TERTIARY_BUTTON") {
    borderStyleObject.borderColor = "transparent"; // override `generateBorderStyleObject`
    borderStyleObject.borderLeftColor = "transparent";
    borderStyleObject.borderRightColor = "transparent";
    borderStyleObject.borderTopColor = "transparent";
    borderStyleObject.borderBottomColor = "transparent";
  } else if (buttonStyle === "SECONDARY_BUTTON") {
    const color = backgroundColorIfSet ?? fallbackBorderColor;
    borderStyleObject.borderColor = color; // override `generateBorderStyleObject`
    borderStyleObject.borderLeftColor = color;
    borderStyleObject.borderRightColor = color;
    borderStyleObject.borderTopColor = color;
    borderStyleObject.borderBottomColor = color;
  }

  if (buttonStyle !== "PRIMARY_BUTTON") {
    // Border width is not supported for non-primary buttons
    // We don't update its `style` because that can't be set yet by users
    borderStyleObject.borderLeftWidth = "1px";
    borderStyleObject.borderRightWidth = "1px";
    borderStyleObject.borderTopWidth = "1px";
    borderStyleObject.borderBottomWidth = "1px";
  }

  return borderStyleObject;
};
