import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const AdvancedEditorWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-width: 0px;
`;

export const AdvancedEditorRow = styleAsClass`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const AdvancedEditorValue = styleAsClass`
    width: 100%;
    border: 1px solid ${colors.GREY_100};
    border-radius: 4px;
    padding: 4px 8px;
    color: ${colors.GREY_700};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
`;
