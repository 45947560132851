import { useCallback } from "react";
import { useStore } from "react-redux";
import {
  getPasteAtCursorInsertionIndexes,
  PasteInsertionIndexes,
} from "hooks/ui/usePasteWidget";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getWidgets } from "legacy/selectors/sagaSelectors";
import { AppState } from "store/types";
import { sendMessage } from "utils/iframe";
import widgetContextMenuEventBus, {
  WidgetContextMenuEvents,
} from "./WidgetContextMenuEventBus";

export type WidgetContextMenuClickEventDetails = {
  widgetId: string;
  clientX: number;
  clientY: number;
  pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
};

const useOpenContextMenuForWidget = () => {
  const store = useStore<AppState>();

  const openContextMenuForWidgetLocal = useCallback(
    ({
      widgetId,
      isInsideIframe,
      clientX,
      clientY,
      pasteAtCursorInsertionIndexes,
    }: {
      widgetId: string;
      isInsideIframe?: boolean;
      clientX: number;
      clientY: number;
      pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
    }) => {
      let pasteAtCursorInsertionIndexesDefaulted =
        pasteAtCursorInsertionIndexes;

      if (!pasteAtCursorInsertionIndexes) {
        const state = store.getState();
        const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;
        const selectedWidgetIds =
          state.legacy.ui.widgetDragResize.selectedWidgets;
        const pasteTargetIdDefaulted =
          widgetId ?? selectedWidgetIds[0] ?? PAGE_WIDGET_ID;
        const widgets = getWidgets(state);

        pasteAtCursorInsertionIndexesDefaulted =
          getPasteAtCursorInsertionIndexes({
            focusedWidget,
            pasteTargetId: pasteTargetIdDefaulted,
            mousePosition: {
              x: clientX,
              y: clientY,
            },
            widgets,
          });
      }

      openContextMenuForWidget({
        widgetId,
        isInsideIframe,
        clientX,
        clientY,
        pasteAtCursorInsertionIndexes: pasteAtCursorInsertionIndexesDefaulted,
      });
    },
    [store],
  );

  return openContextMenuForWidgetLocal;
};

const openContextMenuForWidget = ({
  widgetId,
  isInsideIframe,
  clientX,
  clientY,
  pasteAtCursorInsertionIndexes,
}: {
  widgetId: string;
  isInsideIframe?: boolean;
  clientX: number;
  clientY: number;
  pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
}) => {
  const payload = {
    widgetId,
    clientX,
    clientY,
    pasteAtCursorInsertionIndexes,
  };

  if (isInsideIframe) {
    sendMessage({
      type: "open-widget-context-menu",
      payload,
    });
  } else {
    widgetContextMenuEventBus.dispatchEvent(
      new CustomEvent<WidgetContextMenuClickEventDetails>(
        WidgetContextMenuEvents.CONTEXT_MENU_CLICK,
        {
          detail: payload,
        },
      ),
    );
  }
};

export default useOpenContextMenuForWidget;
