import React, { useState, useEffect, useRef } from "react";

type UseProximity = (
  distance: number,
  disabled?: boolean,
) => [React.RefObject<any>, boolean];

const useProximity: UseProximity = (distance, disable = false) => {
  const [isNear, setIsNear] = useState(false);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    const checkDistance = (event: any) => {
      const rect = ref.current?.getBoundingClientRect?.();
      if (!rect) return;

      const centerX = rect.x + rect.width / 2;
      const centerY = rect.y + rect.height / 2;

      const dist = Math.hypot(centerX - event.clientX, centerY - event.clientY);
      setIsNear(dist < distance);
    };

    if (disable) return;

    window.addEventListener("mousemove", checkDistance);
    return () => {
      window.removeEventListener("mousemove", checkDistance);
    };
  }, [distance, disable]);

  return [ref, isNear];
};

export default useProximity;
