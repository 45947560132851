import equal from "@superblocksteam/fast-deep-equal/es6";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "hooks/ui";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";

class UserPerformance {
  static get debounceAmount(): number {
    let debounceAmount: number | undefined;
    return (() => {
      if (debounceAmount === undefined) {
        if (typeof navigator === "undefined") debounceAmount = 30;
        else if (navigator.hardwareConcurrency <= 4) debounceAmount = 100;
        else if (navigator.hardwareConcurrency <= 8) debounceAmount = 40;
        else debounceAmount = 20;
      }
      return debounceAmount;
    })();
  }
}

/**
 * Custom hook that provides a debounced dispatch function with deduplication.
 *
 * @param options - An optional object with a `deep` property indicating whether to perform deep equality check for deduplication.
 * @returns A debounced dispatch function.
 */
export const useDeferredDispatch = ({ deep }: { deep?: boolean } = {}) => {
  const dispatch = useDispatch();
  const previousAction = useRef<ReduxAction<unknown> | undefined>(undefined);
  const dispatchDeDup = useCallback(
    (action: ReduxAction<unknown>) => {
      if (
        previousAction.current &&
        action.type === previousAction.current.type &&
        deep
          ? equal(action.payload, previousAction.current.payload)
          : action.payload === previousAction.current?.payload
      ) {
        return;
      }
      dispatch(action);
      previousAction.current = action;
    },
    [dispatch, deep],
  );
  return useDebounce(dispatchDeDup, UserPerformance.debounceAmount);
};
