import { getLightenedOrDarkenedColor } from "utils/color";
import type { PerSideBorder } from "@superblocksteam/shared/src/types/application";
import type { GeneratedTheme } from "legacy/themes/types";

export const generateInputBorderOnHoverColor = (
  border: PerSideBorder | undefined,
  defaultColor: string,
  themeColors: GeneratedTheme["colors"],
) => {
  // Currently, all borders are of the same color
  const borderColor = border?.top?.color;
  if (!borderColor) {
    return defaultColor;
  }

  // Neutral colors from color picker palette
  if (
    [
      themeColors.neutral,
      themeColors.neutral50,
      themeColors.neutral100,
      themeColors.neutral300,
      themeColors.neutral500,
      themeColors.neutral700,
      themeColors.neutral900,
    ].includes(borderColor)
  ) {
    return defaultColor;
  }

  return getLightenedOrDarkenedColor(borderColor, defaultColor, 15);
};
