import { convertQueryStringToParams } from "./Utils";

export function getSystemQueryParams() {
  const currentQueryParams = convertQueryStringToParams(window.location.search);
  const { environment, branch, commitId } = currentQueryParams;
  return {
    ...(environment ? { environment } : {}),
    ...(branch ? { branch } : {}),
    ...(commitId ? { commitId } : {}),
  };
}

export function getCurrentQueryParams(includeSystem = false) {
  const { environment, branch, commitId, ...currentQueryParams } =
    convertQueryStringToParams(window.location.search);

  return includeSystem
    ? {
        ...currentQueryParams,
        ...(environment ? { environment } : {}),
        ...(branch ? { branch } : {}),
        ...(commitId ? { commitId } : {}),
      }
    : currentQueryParams;
}
