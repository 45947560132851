import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { UIErrorType } from "@superblocksteam/shared";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { CLASS_NAMES } from "legacy/themes/classnames";
import logger, { stringifyError } from "utils/logger";

// A mechanism that allows exceptions to indicate that they have a custom error display:
// if an exception is caught by this error boundary that has a property with the `customErrorDisplay` symbol as its key,
// the value of that property will be used as the error displayed instead of the default error message.
const customErrorDisplay = Symbol("customErrorDisplay");

type Props = {
  isValid: boolean;
  children: ReactNode;
  widgetType: string;
  widgetId: string;
  getErrorInformation?: () => void | Record<string, string>;
};

type State = { hasError: boolean; errorDisplay: React.ReactNode };

const ErrorBoundaryContainer = styled.div<{
  isValid: boolean; // unclear if isValid is some special property used elsewhere
}>`
  display: contents;
`;

const RetryLink = styled.span`
  color: ${(props) => props.theme.legacy.colors.primaryDarkest};
  cursor: pointer;
`;

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorDisplay: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    const errorDisplay = error[customErrorDisplay] || null;
    return { hasError: true, errorDisplay };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const extraInfo = this.props.getErrorInformation?.();
    const errorMessage = `Something crashed in ${this.props.widgetType},
widgetId: ${this.props.widgetId},
error:
${stringifyError ? stringifyError(error) : JSON.stringify(error)}
${JSON.stringify(errorInfo)}
${extraInfo ? JSON.stringify(extraInfo) : ""}
  `;
    logger.error(errorMessage, {
      superblocks_ui_error_type: UIErrorType.CRASH_APP_ERROR,
    });
    datadogRum.addError(new Error(errorMessage));
    Sentry.captureException(error);
  }
  closeError = () => this.setState({ hasError: false, errorDisplay: null });

  render() {
    return (
      <ErrorBoundaryContainer
        isValid={this.props.isValid}
        data-test-haserror={this.state.hasError}
        className={CLASS_NAMES.STYLED_SCROLLBAR}
      >
        {this.state.hasError ? (
          <p>
            {this.state.errorDisplay ?? "Oops, Something went wrong."}
            <br />
            <RetryLink onClick={this.closeError}>Click here to retry</RetryLink>
          </p>
        ) : (
          this.props.children
        )}
      </ErrorBoundaryContainer>
    );
  }
}

export default ErrorBoundary;
