import { Padding } from "@superblocksteam/shared";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { INPUT_PADDING } from "legacy/themes/constants";
import { generateInputBorderOnHoverColor } from "../Shared/InputUtils";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import InputComponent from "./InputComponent";
import {
  DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
} from "./InputWidgetConstants";
import type { InputComponentWithLayoutManagedProps } from "./types";

export const InputComponentWithLayoutManaged = (
  props: InputComponentWithLayoutManagedProps,
) => {
  const inputBaseStyle = useMemo(() => {
    return props.inputProps?.backgroundColor
      ? { backgroundColor: props.inputProps.backgroundColor }
      : {};
  }, [props.inputProps?.backgroundColor]);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputProps = useTypographyStyling({
    textStyle: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
    priorityStyle: inputBaseStyle,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "label",
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps?.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "input",
  });

  const theme = useSelector(selectGeneratedTheme);

  const inputLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: inputProps?.textStyleVariant,
    nestedProps: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
    typographies: theme.typographies,
  });

  const labelLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: labelProps.textStyleVariant,
    nestedProps: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
    typographies: theme.typographies,
  });

  const inputGroupStylingProps = useMemo(() => {
    return {
      border: props.inputProps?.border,
      fallbackBorderColor: theme.colors.neutral100, // Adapts according to theme's dark/light mode
      borderRadius: props.inputProps?.borderRadius,
      fallbackBorderRadius: theme.borderRadius, // InputGroups (inputs w/ prefix/suffix addons) don't inherit theme styles, we style them at InputComponent level
      borderColorOnHover: generateInputBorderOnHoverColor(
        props.inputProps?.border,
        theme.colors.primary500,
        theme.colors,
      ),
      defaultBorderWidth: theme.defaultBorder.borderWidth,
      padding: props.inputProps?.padding,
    };
  }, [
    props.inputProps?.border,
    props.inputProps?.borderRadius,
    theme.borderRadius,
    theme.colors,
    theme.defaultBorder,
    props.inputProps?.padding,
  ]);

  const appMode = useSelector(getAppMode);
  const padding = props.inputProps?.padding ?? INPUT_PADDING;
  const isMultiline =
    props.inputType === "TEXT" &&
    props.componentHeight -
      Padding.y(padding).value -
      (props.isVertical && props.label ? labelLineHeightPx : 0) >=
      inputLineHeightPx * 2;

  return (
    <InputComponent
      {...props}
      isDisabled={props.isDisabled}
      inputClassName={inputClass}
      inputStyleOverride={inputProps.style}
      labelClassName={labelClass}
      multiline={isMultiline}
      labelStyleOverride={labelProps.style}
      inputGroupStylingProps={inputGroupStylingProps}
      appMode={appMode}
    />
  );
};
