import { Dimension, Margin } from "@superblocksteam/shared";
import { useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { CanvasDefaults, CanvasLayout } from "legacy/constants/WidgetConstants";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { generateDropTargetElementId } from "legacy/utils/generators";
import { AppState } from "store/types";
import { hstackParentColumnSpace } from "utils/size";
import { getWidgetMinWidth } from "../base/sizing";
import { FlattenedWidgetLayoutProps } from "../shared";
import { AdjustmentManager } from "./StackAdjustmentManager";
import { NEW_WIDGET_SYMBOL, STACK_ADJUSTMENT_EPSILON } from "./constants";
import { StackAdjustmentsInfo, WidgetToDrop } from "./types";

const UNAVAILABLE_SPACE = {
  gridUnits: -1,
  px: -1,
  prospectiveColumnSpace: CanvasDefaults.MIN_GRID_UNIT_WIDTH,
};

const getProspectiveConsumedSpacePx = (
  widget: FlattenedWidgetLayoutProps | WidgetToDrop,
  prospectiveColumnSpace: number,
) => {
  return getWidgetMinWidth(widget, prospectiveColumnSpace);
};

const isSelfDrop = (
  parentWidget: FlattenedWidgetLayoutProps,
  selectedWidgetIds: string[],
) => {
  return parentWidget?.children?.indexOf(selectedWidgetIds[0]) !== -1;
};

const PIXEL_BUFFER = 2; // like stack epsilon but more forgiving because we're measuring html elements

/*
 *  This function returns the number of grid units that could be added to the parent widget before it begins to scroll.
 *  If the parent is already overflowing, it returns 0.
 *  For dropping new items, it tries to predict how the widths of items would change (since parentColumnSpace adjusts based on spacing & the number of children)
 */
export const computeAvailableSpace = (params: {
  parentWidget: FlattenedWidgetLayoutProps;
  isCreate: boolean;
  selectedWidgetIds: string[];
  state: any;
}): { gridUnits: number; prospectiveColumnSpace: number; px: number } => {
  const { parentWidget, state, isCreate, selectedWidgetIds } = params;

  const stackedWidgets =
    parentWidget?.children?.map((id) => getFlattenedCanvasWidget(state, id)) ??
    [];

  const parentElement = document.getElementById(
    generateDropTargetElementId(parentWidget.widgetId),
  );

  if (
    !parentElement ||
    !parentWidget ||
    !parentWidget.children ||
    !parentWidget.internalWidth
  ) {
    return UNAVAILABLE_SPACE;
  }

  const outerWidthPx = Dimension.toPx(
    parentWidget.width,
    parentWidget.parentColumnSpace,
  );
  const alreadyScrolling =
    (parentElement?.offsetWidth ?? 0) > outerWidthPx.value + PIXEL_BUFFER;

  if (alreadyScrolling) {
    return UNAVAILABLE_SPACE;
  }

  let netNewItems = 0;
  if (isCreate) {
    netNewItems = 1;
  } else if (isSelfDrop(parentWidget, selectedWidgetIds)) {
    // if we're dropping into our own parent, then nothing needs to be resized
    netNewItems = 0;
  } else {
    netNewItems = selectedWidgetIds.length;
  }

  const numVisibleChildren = parentWidget.children.length + netNewItems; // note: this will be correct unless we have legacy modals/slideouts inside the container
  const parentWidth = parentWidget.internalWidth; // we are allowed to use `internalWidth` here to compute the parentColumnSpace for one key reason.
  // if alreadyScrolling, then we return early. If not, then that means the internalWidth represents the outer width - padding - border.

  const hstackColumnSpace = hstackParentColumnSpace({
    parentWidget,
    children: stackedWidgets,
    numVisibleChildren,
    parentWidth,
  });

  const widthUsedByWidgets = stackedWidgets.reduce(
    (acc: number, child: any) => {
      if (child.detachFromLayout) return acc;
      return (
        acc +
        getProspectiveConsumedSpacePx(child, hstackColumnSpace) +
        Margin.x(child.margin).value
      );
    },
    0,
  );

  const spacing = parentWidget?.spacing ?? CanvasDefaults.SPACING;
  const widthUsedByGap = (numVisibleChildren - 1) * spacing.value;

  const remainingSpace =
    (parentWidth satisfies Dimension<"px">).value -
    widthUsedByWidgets -
    widthUsedByGap;

  const remainingSpaceGU = remainingSpace / hstackColumnSpace;
  return {
    gridUnits: remainingSpaceGU,
    prospectiveColumnSpace: hstackColumnSpace,
    px: remainingSpace,
  };
};

export const useDimensionAdjustmentsOnDrop = (params: {
  parentWidgetId: string;
}) => {
  const { parentWidgetId } = params;

  const store = useStore<AppState>();
  const selectedWidgetIds = useSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.selectedWidgets,
  );

  // currently only hstacks receive drop adjustments
  const computeDropAdjustments = useCallback(
    (params: {
      widget: WidgetToDrop;
      insertionIndex: number; // currently unused, but here if we want to experiment with treating the neighbors differently
    }): undefined | StackAdjustmentsInfo => {
      const { widget } = params;
      const isCreate = !widget.widgetName;
      const state = store.getState();
      const parentWidget = getFlattenedCanvasWidget(state, parentWidgetId);
      if (
        !parentWidget ||
        !parentWidget.children ||
        parentWidget.layout !== CanvasLayout.HSTACK
      ) {
        return;
      }

      const selfDrop = !isCreate && isSelfDrop(parentWidget, selectedWidgetIds);
      if (selfDrop) {
        return;
      }

      const availableSpace = computeAvailableSpace({
        parentWidget,
        state,
        isCreate,
        selectedWidgetIds,
      });

      // if there's exactly 0 space, then we should still try
      if (availableSpace.gridUnits < 0) {
        return;
      }

      // Priority for allocating space:
      // 1. Take only from available space in the hstack if possible.
      // 2. Try to take space away equally from dropped items and existing items
      const incomingWidgets: Array<WidgetToDrop | FlattenedWidgetLayoutProps> =
        isCreate
          ? [{ ...widget, widgetId: widget.widgetId ?? NEW_WIDGET_SYMBOL }]
          : selectedWidgetIds.map((id) => getFlattenedCanvasWidget(state, id));

      const incomingWidgetWidthPx = incomingWidgets.reduce<number>(
        (acc: number, widget) => {
          return (
            acc +
            getProspectiveConsumedSpacePx(
              widget,
              availableSpace.prospectiveColumnSpace,
            )
          );
        },
        0,
      );

      // priority 1, only use available space
      if (
        incomingWidgetWidthPx <
        availableSpace.px + STACK_ADJUSTMENT_EPSILON
      ) {
        return; // Great! We have enough space
      }

      const adjustments = new AdjustmentManager({
        availableSpacePx: availableSpace.px - incomingWidgetWidthPx, // this will be negative since we're initially over
        parentColumnSpace: availableSpace.prospectiveColumnSpace,
      });

      const allWidgets = parentWidget.children.map((id) =>
        getFlattenedCanvasWidget(state, id),
      );
      adjustments.trimWidgets([...incomingWidgets, ...allWidgets]);

      return adjustments.getAdjustments();
    },
    [store, parentWidgetId, selectedWidgetIds],
  );

  return computeDropAdjustments;
};
