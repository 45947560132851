import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { Layers } from "legacy/constants/Layers";
import { useWidgetDragResize } from "legacy/hooks/dragResizeHooks";
import { useWidgetSelection } from "legacy/hooks/dragResizeHooks";
import {
  getIsWidgetSelected,
  getIsWidgetFocused,
} from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { type AppState } from "store/types";
import { useSectionSizingContextSelector } from "./SectionSizingContextSelectors";

const CURSOR_STYLE = "col-resize";

const Container = styled.div`
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  cursor: ${CURSOR_STYLE};
  z-index: ${Layers.dragPreview + 1};

  &:hover,
  &[data-is-dragging="true"] {
    ::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 100%;
      top: 0;
      left: 3px;
      pointer-events: none;
      box-sizing: border-box;
      background: ${(props) =>
        tinycolor(props.theme.colors.ACCENT_BLUE_NEW_DARKER)
          .setAlpha(0.18)
          .toRgbString()};
    }
  }

  &:hover,
  &[data-is-dragging="true"] {
    ::after {
      content: "";
      pointer-events: none;
      width: 2px;
      height: calc(100% - 2px);
      position: absolute;
      top: 1px;
      right: 0;
      background: ${(props) => props.theme.colors.ACCENT_BLUE_NEW_DARKER};
    }
  }
`;

type SectionColumnResizeBorderProps = {
  widgetId: string;
  widgetGridColumns?: number;
  parentColumnSpace: number;
  widgetRightGridColumns?: number;
  columnIndex: number;
  gridColumnsPerSectionColumn: number;
};

const initPosition = () => ({
  x: 0,
  y: 0,
});

const SectionColumnResizeBorder = ({
  widgetId,
  columnIndex,
}: SectionColumnResizeBorderProps) => {
  const pointerPosition = useRef({
    initial: initPosition(),
  });

  const { focusWidget, unfocusWidget } = useWidgetSelection();
  const { setIsResizing } = useWidgetDragResize();

  const isFocused = useAppSelector((state) =>
    getIsWidgetFocused(state, widgetId),
  );
  const isSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, widgetId),
  );
  const isDraggingOrResizingWidget = useSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.isDragging ||
      state.legacy.ui.widgetDragResize.isResizing,
  );

  const onDrag = useSectionSizingContextSelector((context) => context.onDrag);
  const onDragEnd = useSectionSizingContextSelector(
    (context) => context.onDragEnd,
  );
  const onDragStart = useSectionSizingContextSelector(
    (context) => context.onDragStart,
  );

  const [isPointerDown, setPointerDown] = React.useState(false);
  const isPointerDownRef = React.useRef(isPointerDown);
  isPointerDownRef.current = isPointerDown;

  const handlePointerDown = useCallback(
    (e: React.PointerEvent) => {
      const handlePointerMove = (e: PointerEvent) => {
        if (!isPointerDownRef.current) {
          return;
        }

        e.stopPropagation();
        setIsResizing(true);
        const diffX = pointerPosition.current.initial.x - e.clientX;
        onDrag(columnIndex, diffX);
      };

      const handlePointerUp = (e: PointerEvent) => {
        document.removeEventListener("pointermove", handlePointerMove, true);
        document.removeEventListener("pointerup", handlePointerUp, true);
        e.stopPropagation();

        setPointerDown(false);
        document.body.style.cursor = ""; // remove the value
        onDragEnd();
        setIsResizing(false);
      };

      e.stopPropagation();
      setPointerDown(true);
      document.body.style.cursor = CURSOR_STYLE;
      onDragStart();
      setIsResizing(true);
      pointerPosition.current.initial = {
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener("pointermove", handlePointerMove, true);
      document.addEventListener("pointerup", handlePointerUp, true);
    },
    [columnIndex, onDrag, onDragEnd, onDragStart, setIsResizing],
  );

  const handleMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isFocused && focusWidget) {
        focusWidget(widgetId);
        e.stopPropagation();
      }
    },
    [isFocused, widgetId, focusWidget],
  );

  const handleMouseOut = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      unfocusWidget && isFocused && unfocusWidget();
      e.stopPropagation();
    },
    [unfocusWidget, isFocused],
  );

  if (!isPointerDown && isDraggingOrResizingWidget && !isSelected) {
    return null;
  }

  return (
    <Container
      data-test={`section-column-resize-border-${widgetId}`}
      data-is-dragging={isPointerDown}
      onPointerDown={handlePointerDown}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  );
};

export default SectionColumnResizeBorder;
