import { Dimension, Padding, WidgetTypes } from "@superblocksteam/shared";
import React, { useMemo } from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { ComponentProps } from "legacy/components/designSystems/default/BaseComponent";
import { Layers } from "legacy/constants/Layers";
import {
  GridDefaults,
  SectionDefaults,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getEditorReadOnly } from "legacy/selectors/editorSelectors";
import {
  getIsWidgetSelected,
  getIsWidgetFocused,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import VisibilityContainer from "legacy/widgets/base/VisibilityContainer";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import ContainerComponent from "../Shared/ContainerComponent";
import SelectableAndFocusableComponent from "../base/SelectableAndFocusableComponent";
import WidgetNameComponent from "../base/WidgetNameComponent";
import { ZERO_PADDING, getWidgetDefaultPadding } from "../base/sizing";
import { WidgetLayoutProps } from "../shared";
import withWidgetProps from "../withWidgetProps";
import SectionColumnResizeBorder from "./SectionColumnResizeBorder";
import { useSectionSizingContextSelector } from "./SectionSizingContextSelectors";

const toGridUnit = (
  dimension: Dimension<"gridUnit" | "fitContent" | "px" | "fillParent">,
  parentSpace: number = GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
): number => {
  return Dimension.toGridUnit(dimension, parentSpace).raw().value;
};

const SectionColumn = styled.div`
  position: relative;
  transition: width 0.2s ease;

  &[data-solid-border="true"] {
    ::after {
      content: "";
      position: absolute;
      inset: -0.5px;
      border-right: 1px solid
        ${(props) =>
          tinycolor(props.theme.colors.GREY_300).setAlpha(0.3).toRgbString()};
      pointer-events: none;
    }
  }

  &[data-dashed-border="true"] {
    ::after {
      z-index: ${Layers.resizer};
      content: "";
      position: absolute;
      inset: -0.5px;
      border: 1px dashed
        ${({ theme }) =>
          tinycolor(theme.colors.ACCENT_BLUE_500).setAlpha(0.5).toRgbString()};
      pointer-events: none;
    }
  }

  &[data-resize-border="true"] {
    ::after {
      z-index: ${Layers.resizer};
      content: "";
      position: absolute;
      inset: -0.5px;
      border-right: 1px solid
        ${(props) => props.theme.colors.ACCENT_BLUE_NEW_DARKER};
      pointer-events: none;
    }
  }
`;

type SectionColumnComponentProps = WidgetLayoutProps &
  ComponentProps & {
    renderChildWidget: (childData: WidgetLayoutProps) => React.ReactNode;
    columnRightWidget?: Omit<WidgetLayoutProps, "appMode">;
    appMode?: APP_MODE;
    isLoading: boolean;
    columnIndex: number;
    isLastColumn: boolean;
    gridColumnsPerSectionColumn: number;
    sectionIsSelected: boolean;
    sectionIsFocused: boolean;
    pageIsFocused: boolean;
    sectionOrColumnIsSelected: boolean;
    parentIsModalOrSlideout: boolean;
    isFirstPageSection: boolean;
    isDraggingOrResizingSectionColumnChild: boolean;
    isOverColumnChildAsDropTarget: boolean;
    isStacked: boolean;
  };

const SectionColumnComponent = ({
  appMode,
  isLoading,
  columnIndex,
  isLastColumn,
  columnRightWidget,
  gridColumnsPerSectionColumn,
  renderChildWidget,
  sectionOrColumnIsSelected,
  sectionIsSelected,
  sectionIsFocused,
  pageIsFocused,
  parentIsModalOrSlideout,
  isDraggingOrResizingSectionColumnChild,
  isOverColumnChildAsDropTarget,
  isFirstPageSection,
  isStacked,
  border,
  ...widget
}: SectionColumnComponentProps) => {
  const isVisible = widget.isVisible ?? true;

  const columnWidth = useSectionSizingContextSelector(
    (context) => context.columnWidthsPx[columnIndex],
  );
  const isColumnResizing = useSectionSizingContextSelector(
    (context) => context.isColumnResizing,
  );
  const columnCanvasExtensionRows = useSectionSizingContextSelector(
    (context) => {
      const info = context.columnCanvasExtensionRowsInfo;
      // When stacked we only want to extend ONE column
      if (isStacked) {
        return info?.dropTargetId === widget.widgetId ? info?.rows : null;
      }
      return info?.rows;
    },
  );

  const isSelected = useAppSelector((state: AppState) =>
    getIsWidgetSelected(state, widget.widgetId),
  );
  const isFocused = useAppSelector((state: AppState) =>
    getIsWidgetFocused(state, widget.widgetId),
  );

  const generatedTheme = useAppSelector(selectGeneratedTheme);
  const columnPadding =
    widget.padding ?? getWidgetDefaultPadding(generatedTheme, widget);

  const canvasWidgetHeight = useMemo(() => {
    return Dimension.gridUnit(
      Math.max(
        toGridUnit(widget.height),
        // Account for the extension of the canvas during a drag or resize
        (columnCanvasExtensionRows ?? 0) - toGridUnit(Padding.y(columnPadding)),
      ),
    );
  }, [widget.height, columnCanvasExtensionRows, columnPadding]);

  const canvasWidget = renderChildWidget({
    ...widget,
    appMode,
    // Section columns can be resized to as small as 1 grid row
    minHeight: Dimension.gridUnit(SectionDefaults.MIN_COLUMN_GRID_ROWS),
    // TODO: need to find a better way to handle not-detached but NOT positioned by grid system
    detachFromLayout: true,
    shouldScrollContents: false, // we override this because we want the container wrapper (below) to scroll, not the canvas itself
    height: canvasWidgetHeight,
  });

  const containerProps = useMemo(
    () => ({
      ...widget,
      // We don't want 2 widgets with the same ID, so we need to override given we're
      // using the canvas widget here
      widgetId: widget.widgetId + "--container-wrapper",
      padding: ZERO_PADDING,
      hasDefaultBorder: false,
      border,
      outerWidthAdjustmentPx: Padding.x(widget.padding).value,
      // We need to add the padding to the height of the container
      height: Dimension.gridUnit(
        Math.max(
          toGridUnit(widget.height) + toGridUnit(Padding.y(columnPadding)),
          // Account for the extension of the canvas during a drag or resize
          columnCanvasExtensionRows ?? 0,
        ),
      ),
      parentId: widget.parentId,
    }),
    [widget, columnPadding, columnCanvasExtensionRows, border],
  );

  let content = (
    <VisibilityContainer
      isVisible={isVisible}
      widgetId={widget.widgetId}
      style={{
        height: `calc(100% + ${WIDGET_PADDING * 2 + 1}px)`, // We add 1 to offset any rounding errors
        marginTop: -WIDGET_PADDING,
        marginBottom: -WIDGET_PADDING,
      }}
    >
      <ContainerComponent {...containerProps}>
        {canvasWidget}
      </ContainerComponent>
    </VisibilityContainer>
  );

  const [showNameOverride, setShowNameOverride] = React.useState(false);

  let shouldShowNamePill = true;
  if (
    isFirstPageSection &&
    isLastColumn &&
    isSelected &&
    (sectionIsFocused || pageIsFocused)
  ) {
    shouldShowNamePill = false;
  }

  let initialShownBreadcrumbs = 0;
  if (
    isFirstPageSection &&
    isLastColumn &&
    ((isFocused && sectionIsSelected) || (isSelected && sectionIsFocused))
  ) {
    initialShownBreadcrumbs = 1;
  }

  const editorIsReadOnly = useAppSelector(getEditorReadOnly);

  if (appMode === APP_MODE.EDIT) {
    content = (
      <>
        {!isLastColumn &&
          sectionOrColumnIsSelected &&
          !isStacked &&
          !editorIsReadOnly && (
            <SectionColumnResizeBorder
              widgetId={widget.widgetId}
              widgetGridColumns={widget.gridColumns}
              parentColumnSpace={widget.parentColumnSpace}
              widgetRightGridColumns={columnRightWidget?.gridColumns}
              columnIndex={columnIndex}
              gridColumnsPerSectionColumn={gridColumnsPerSectionColumn}
            />
          )}
        <div
          style={{ display: "contents" }}
          // This handles the case where the section is selected on the first click
          // but then with the cursor over top of a column, the widget name does not show
          // because it was already there and not moved into it
          onMouseOverCapture={() => setShowNameOverride(true)}
          onMouseLeave={() => setShowNameOverride(false)}
        >
          <SelectableAndFocusableComponent
            widget={{
              ...widget,
              appMode,
              isLoading,
            }}
            focusedRectInset={0}
            selectedRectInset={0}
            // it's important we disable this component instead of directly rendering the contents coniditionally, since this causes a remount
            disabled={
              (!sectionIsSelected && !isSelected) ||
              (isColumnResizing && !isSelected)
            }
          >
            {shouldShowNamePill && (
              <WidgetNameComponent
                {...widget}
                widgetType={WidgetTypes.CANVAS_WIDGET}
                isVisible={isVisible}
                isLoading={isLoading}
                appMode={appMode}
                isSectionColumnWidget={true}
                initialShownBreadcrumbs={initialShownBreadcrumbs}
                showNameOverride={showNameOverride && sectionIsSelected}
                showNameFocusedOverride={showNameOverride && sectionIsSelected}
                hasInvalidProps={false}
                widgetNamePosition="inset"
                disableHoverInteraction={true}
              />
            )}
            {content}
          </SelectableAndFocusableComponent>
        </div>
      </>
    );
  }

  const style = useMemo(() => {
    return {
      width: `${
        columnWidth ||
        Dimension.toPx(widget.width, widget.parentColumnSpace).value
      }px`,
      overflow: "hidden",
    };
  }, [widget.width, columnWidth, widget.parentColumnSpace]);

  return (
    <SectionColumn
      id={`widget-${widget.widgetId}`}
      key={widget.widgetId}
      data-test="section-column"
      data-solid-border={sectionIsSelected || isColumnResizing}
      data-resize-border={Boolean(isColumnResizing && !isLastColumn)}
      data-dashed-border={
        isDraggingOrResizingSectionColumnChild || isOverColumnChildAsDropTarget
      }
      style={style}
    >
      {content}
    </SectionColumn>
  );
};

export default withWidgetProps(SectionColumnComponent);
