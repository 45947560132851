import { Button } from "antd";
import React from "react";
import { ReactComponent as CustomizationIcon } from "assets/icons/common/customization.svg";
import { ReactComponent as AdvancedIcon } from "legacy/assets/icons/control/advanced-settings.svg";
import { selectedButtonStyle, unselectedButtonStyle } from "./buttonStyles";

export const AdvancedModeToggle = ({
  onToggle,
  isAdvancedMode,
  isDisabled,
  type,
  dataTest,
}: {
  isAdvancedMode: boolean;
  onToggle: () => void;
  isDisabled?: boolean;
  type: "padding" | "margin" | "border" | "spacing" | "textStyle";
  dataTest?: string;
}) => {
  return (
    <Button
      data-type={type}
      data-test={dataTest}
      icon={
        type === "spacing" || type === "textStyle" ? (
          <CustomizationIcon />
        ) : (
          <AdvancedIcon />
        )
      }
      onClick={onToggle}
      className={isAdvancedMode ? selectedButtonStyle : unselectedButtonStyle}
      disabled={isDisabled}
    />
  );
};
