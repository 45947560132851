const tableColumnTypes = [
  {
    label: "Plain text",
    value: "text",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Currency",
    value: "currency",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Button",
    value: "button",
  },
  {
    label: "Link",
    value: "link",
  },
  {
    label: "Boolean",
    value: "boolean",
  },
  {
    label: "Tags",
    value: "tags",
  },
  {
    label: "Email",
    value: "email",
  },
];

export default tableColumnTypes;
