import { ThemeMode, WidgetTypes } from "@superblocksteam/shared";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import paddingSVG from "assets/images/canvas/canvasPadding.svg";
import paddingDarkSVG from "assets/images/canvas/canvasPaddingDark.svg";
import AddButton from "components/ui/AddButton";
import { addSectionWidget } from "legacy/actions/widgetActions";
import { getEditorReadOnly } from "legacy/selectors/editorSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { AppState } from "store/types";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import type { WidgetProps } from "legacy/widgets";

const SHOW_ADD_SECTION_BUTTON_THRESHOLD = 50;

const EmptySpace = styleAsClass`
  flex: 1;
  opacity: 0;
  display: flex;
  background-repeat: repeat;
  font-family: var(--font-family);

  & > div {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;
    background: transparent;
    box-shadow: 0 0 0 1px ${colors.ACCENT_BLUE_NEW_DARKER};
    z-index: 1;
  }

  &[data-show-empty-space="true"]:hover {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  &[data-dark-theme="true"]{
    background: url(${paddingDarkSVG});
  }
  &[data-dark-theme="false"]{
    background: url(${paddingSVG});
  }
  button {
    opacity: 0;
    z-index: 1;
  }
  &:hover button {
    opacity: 1;
    background: ${colors.ACCENT_BLUE_NEW_DARKER};
    &:focus:not(:hover) {
      /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
      background: ${colors.ACCENT_BLUE_NEW_DARKER};
      color: white;
    }
    &:hover,
    &:focus:not(:hover):focus-visible {
      // when hover or keyboard navigte using tab
      background: ${colors.ACCENT_BLUE_NEW_DARKER}};
      color: white;
    }
    & button:active {
      background: ${colors.ACCENT_BLUE_NEW_DARKER};
      color: white;
    }
  }
`;

const EmptySpaceOverLay = ({
  isFocused,
  childrenWidgets,
}: {
  isFocused: boolean;
  childrenWidgets?: WidgetProps[];
}) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectGeneratedTheme);
  const readOnly = useSelector(getEditorReadOnly);
  const emptySpaceRef = useRef<HTMLDivElement>(null);
  const [enoughSpace, setEoughSpace] = useState(false);
  const isDragging = useSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isDragging,
  );
  const [lastSectionId, hasFillParentSection] = useMemo((): [
    string | undefined,
    boolean,
  ] => {
    const sections = childrenWidgets?.filter(
      (child) => child.type === WidgetTypes.SECTION_WIDGET,
    );
    const lastSectionId = sections?.[sections.length - 1]?.widgetId;
    // we do not want to insert empty space div if any of parent children section is fill viewport
    const hasFillParentSection = Boolean(
      sections?.some((section) => section.height.mode === "fillParent"),
    );
    return [lastSectionId, hasFillParentSection];
  }, [childrenWidgets]);

  const onAddSection = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      lastSectionId && dispatch(addSectionWidget(lastSectionId, "below"));
    },
    [dispatch, lastSectionId],
  );

  useEffect(() => {
    // show empty space only when hover on the area and if it is high enough
    const hasEnoughtSpace = Boolean(
      isFocused && // adding isFocused to avoid flickering when add a section that make bottom space not high enough
        emptySpaceRef.current &&
        emptySpaceRef.current.clientHeight > SHOW_ADD_SECTION_BUTTON_THRESHOLD,
    );

    let timeout: NodeJS.Timeout;

    if (hasEnoughtSpace) {
      timeout = setTimeout(() => setEoughSpace(hasEnoughtSpace), 400);
    } else {
      setEoughSpace(hasEnoughtSpace);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFocused]);

  const showEmptySpace = !isDragging && enoughSpace;

  return hasFillParentSection ? null : (
    <div
      ref={emptySpaceRef}
      className={EmptySpace}
      data-dark-theme={theme.mode === ThemeMode.DARK}
      data-show-empty-space={showEmptySpace}
    >
      <div>
        {showEmptySpace && (
          <AddButton
            text="Add Section"
            disabled={readOnly}
            onAdd={onAddSection}
          />
        )}
      </div>
    </div>
  );
};

export default EmptySpaceOverLay;
