import { throttle } from "lodash";
import { useState, useEffect } from "react";

// For when you want to rerender
export function useIsKeyDownState(
  keys: string[],
  preventDefault?: boolean,
  resetOnPointer?: boolean,
): boolean {
  const [keyIsDown, setKeyIsDown] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (keys?.includes(e.key)) {
        if (preventDefault) e.preventDefault();
        setKeyIsDown(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        if (preventDefault) e.preventDefault();
        setKeyIsDown(false);
      }
    };

    const handleBlur = () => {
      setKeyIsDown(false);
    };

    const checkForShift = keys.includes("Shift");
    const handlePointer = throttle(
      (e: PointerEvent) => {
        if (checkForShift && !e.shiftKey) {
          setKeyIsDown(false);
        }
      },
      120,
      { leading: true },
    );

    window.addEventListener("keydown", handleKeyDown, true);
    window.addEventListener("keyup", handleKeyUp, true);

    // if the user loses focus outside of the canvas, like clicks in the props panel, we should
    // also reset key state
    window.addEventListener("blur", handleBlur, true);

    // this is a last ditch attempt to make sure we dont get stuck in a state where we think a key is down
    // because the OS preempted our keyup event (e.g. taking a screenshot using keyboard on macOS)
    window.addEventListener("pointerdown", handlePointer, true);

    return (): void => {
      window.removeEventListener("keydown", handleKeyDown, true);
      window.removeEventListener("keyup", handleKeyUp, true);
      window.removeEventListener("blur", handleBlur, true);
      window.removeEventListener("pointerdown", handlePointer, true);
    };
  }, [keys, preventDefault, resetOnPointer]);

  return keyIsDown;
}
