import {
  OBS_TAG_ENTITY_ID,
  OBS_TAG_ENTITY_TYPE,
  OBS_TAG_ENTITY_NAME,
  OBS_TAG_WIDGET_TYPE,
} from "@superblocksteam/shared";
import React, { memo, useCallback, useContext } from "react";

import { eventNameToSpanName } from "tracing/utils";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { EditorContext } from "../components/editorComponents/EditorContextProvider";
import { RunEventHandlersPayload } from "../constants/ActionConstants";
import { WidgetPropsRuntime } from "./BaseWidget";

import type BaseWidget from "./BaseWidget";

export interface WithMeta {
  updateWidgetMetaProperty: (
    propertyName: string,
    propertyValue: any,
    actionExecution?: RunEventHandlersPayload,
  ) => void;
}

function isActionExecution(
  actionExecution?: RunEventHandlersPayload,
): actionExecution is RunEventHandlersPayload {
  return Boolean(actionExecution && actionExecution.steps);
}

const withMeta = (WrappedWidget: typeof BaseWidget) => {
  const WithMeta = memo((props: WidgetPropsRuntime) => {
    const { widgetId } = props;

    const { updateWidgetMetaProperty, runEventHandlers } =
      useContext(EditorContext);

    const updateMetaProperty = useCallback(
      (
        propertyName: string,
        propertyValue: any,
        actionExecution?: RunEventHandlersPayload,
      ): void => {
        if (updateWidgetMetaProperty) {
          updateWidgetMetaProperty(widgetId, propertyName, propertyValue);
        }
        if (runEventHandlers && isActionExecution(actionExecution)) {
          const eventPath = `${props.widgetName}.${eventNameToSpanName(
            actionExecution.event.type,
          )}`;
          actionExecution.additionalEventAttributes = {
            [OBS_TAG_ENTITY_TYPE]: ENTITY_TYPE.WIDGET,
            [OBS_TAG_ENTITY_ID]: props.widgetId,
            [OBS_TAG_ENTITY_NAME]: props.widgetName,
            [OBS_TAG_WIDGET_TYPE]: props.type,
            pathToDisplay: eventPath,
            propertyPath: eventPath,
          };
          runEventHandlers(actionExecution);
        }
      },
      [
        widgetId,
        updateWidgetMetaProperty,
        runEventHandlers,
        props.widgetName,
        props.widgetId,
        props.type,
      ],
    );

    return (
      <WrappedWidget {...props} updateWidgetMetaProperty={updateMetaProperty} />
    );
  });

  WithMeta.displayName = `WithMetaHoc(${WrappedWidget.name})`;

  return WithMeta;
};

export default withMeta;
