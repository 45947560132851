import React, { memo, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import CanvasWidgetsNormalizer from "legacy/normalizers/CanvasWidgetsNormalizer";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { getSingleWidgetProps } from "legacy/selectors/editorSelectors";
import type { WidgetMap } from ".";
import type { WidgetPropsRuntime } from "./BaseWidget";
import type { WidgetLayoutProps } from "./shared";
import type { AppState } from "store/types";

export type WithChildrenProps = {
  childWidgets: WidgetPropsRuntime[];
};

const withChildren = <P extends WidgetLayoutProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P & WithChildrenProps> => {
  const WithChildren: React.ComponentType<P & WithChildrenProps> = memo(
    (layoutProps) => {
      const children = useChildWidgetTree(layoutProps);
      return <WrappedComponent {...layoutProps} childWidgets={children} />;
    },
  );
  WithChildren.displayName = `WithChildren(${WrappedComponent.name})`;
  return WithChildren;
};

/**
 * a React hook that returns a list of widgets that are children of the widget
 * that is passed in
 * @param {P} layoutProps - The props of the widget that is being rendered
 * @returns a PageDSL object that contains the children of the widget
 */
function useChildWidgetTree<P extends WidgetLayoutProps>(layoutProps: P) {
  const widgetChildren = useSelectChildren(layoutProps);

  const normalized = useMemo(() => {
    if (!widgetChildren || !layoutProps.widgetId) return undefined;

    return CanvasWidgetsNormalizer.denormalize(layoutProps.widgetId, {
      canvasWidgets: {
        [layoutProps.widgetId]: {
          widgetId: layoutProps.widgetId,
          children: layoutProps.children?.map?.((c) => c.widgetId),
        } as FlattenedWidgetProps,
        ...widgetChildren,
      },
    });
  }, [layoutProps.widgetId, layoutProps.children, widgetChildren]);

  return normalized?.children;
}

function useSelectChildren(layoutProps: WidgetLayoutProps) {
  const childLayouts = useMemo(() => {
    const normalizedLayouts = (
      children: WidgetLayoutProps[] | undefined,
      depth = 0,
    ): WidgetLayoutProps[] =>
      (children ? children : []).flatMap((item) => {
        const array = normalizedLayouts(item.children, depth + 1);
        array.push(item);
        return array;
      });

    return normalizedLayouts(layoutProps.children);
  }, [layoutProps.children]);

  const childrenWidgetProps = useSelector(
    (state: AppState) => {
      return childLayouts?.map((child) =>
        getSingleWidgetProps(state, child.widgetId),
      );
    },
    (prev, next) => shallowEqual(prev, next),
  );

  const childrenMap = useMemo(() => {
    if (!childLayouts || !childrenWidgetProps) return undefined;

    return childLayouts?.reduce((acc, childLayoutProps, i) => {
      const childProps = childrenWidgetProps[i] as FlattenedWidgetProps;
      acc[childLayoutProps.widgetId] = mergeProps(childProps, childLayoutProps);
      return acc;
    }, {} as WidgetMap);
  }, [childrenWidgetProps, childLayouts]);

  return childrenMap;
}

const mergeProps = <T extends FlattenedWidgetProps>(
  childProps: T,
  childLayoutProps: WidgetLayoutProps,
): T => {
  return {
    ...childProps,
    ...childLayoutProps,
    children: childProps.children,
    key: childProps.widgetId,
    type: childProps.type,
    isVisible: childProps.isVisible ?? (childLayoutProps as any).isVisible,
    appMode: (childLayoutProps as any).appMode,
  };
};

export default withChildren;
