import { useCallback, useContext, useState } from "react";
import { DndContext, useDrag } from "react-dnd";
import { selectWidgets } from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { WidgetCardProps } from "legacy/constants/WidgetConstants";
import { getWidgetBlueprint } from "legacy/mockResponses/selectors";
import { getWidgetsAreSelected } from "legacy/selectors/sagaSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import { generateReactKey } from "legacy/utils/generators";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { getChildRef, sendMessage } from "utils/iframe";

export const useDragWidgetIntoCanvas = (
  widgetProps: WidgetCardProps,
  options: {
    onDragStart?: () => void;
  },
) => {
  const { onDragStart } = options;
  const dispatch = useAppDispatch();
  const editorHasSelectedWidgets = useAppSelector(getWidgetsAreSelected);

  const clearSelectedWidgets = useCallback(() => {
    if (editorHasSelectedWidgets) dispatch(selectWidgets([]));
  }, [dispatch, editorHasSelectedWidgets]);

  const dragManager = useContext(DndContext);

  // Generate a new widgetId which can be used in the future for this widget.
  const [widgetId, setWidgetId] = useState(generateReactKey());
  const { height, width } = useAppSelector(
    (s: Parameters<typeof getWidgetBlueprint>[0]) =>
      getWidgetBlueprint(s, widgetProps.type),
  );
  const item = {
    ...widgetProps,
    widgetId,
    height,
    width,
  };

  const [, drag, preview] = useDrag({
    type: item.type,
    item: (monitor) => {
      AnalyticsUtil.logEvent("WIDGET_CARD_DRAG", {
        widgetType: widgetProps.type,
        widgetName: widgetProps.widgetCardName,
      });
      // This is not updating the classnames on the whole drag
      dispatch({
        type: ReduxActionTypes.SET_WIDGET_DRAGGING,
        payload: { isDragging: true, isDraggingNewWidget: true },
      });
      clearSelectedWidgets();
      onDragStart?.();

      sendMessage({
        type: "dragstart",
        item: item,
      });
      // Important: the iframe needs focus in order to be draggable.
      // The endDrag() here only affects the parent app
      setTimeout(() => {
        getChildRef()?.focus();
        // We can't call endDrag() if dragging is already true
        // there is an edge case with fast pointer drag and drop movement where
        // dragging is false but then we try to end the drag again here
        // which throws an error
        if (dragManager.dragDropManager?.getMonitor().isDragging()) {
          dragManager.dragDropManager?.getActions().endDrag();
        }
      }, 0);
      return item;
    },
    end: (widget, monitor) => {
      AnalyticsUtil.logEvent("WIDGET_CARD_DROP", {
        widgetType: widgetProps.type,
        widgetName: widgetProps.widgetCardName,
        didDrop: monitor.didDrop(),
      });
      // We've finished dragging, generate a new widgetId to be used for next drag.
      setWidgetId(generateReactKey());
    },
  });

  return { preview, drag };
};
