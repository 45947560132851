import {
  Dimension,
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared";
import { CSSProperties } from "react";

export const generateBorderStyleObject = ({
  border,
  fallbackBorderColor,
  borderRadius,
  fallbackBorderRadius,
}: {
  border?: PerSideBorder;
  fallbackBorderColor?: string;
  borderRadius?: PerCornerBorderRadius;
  fallbackBorderRadius?: Dimension<"px">;
}): CSSProperties => {
  return {
    ...generateBorderWidthAndColorStyleObject({
      border,
      fallbackBorderColor,
    }),
    ...generateBorderRadiusStyleObject({ borderRadius, fallbackBorderRadius }),
  };
};

const generateBorderWidthAndColorStyleObject = ({
  border,
  fallbackBorderColor,
}: {
  border?: PerSideBorder;
  fallbackBorderColor?: string;
  borderRadius?: PerCornerBorderRadius;
  fallbackBorderRadius?: Dimension<"px">;
}): CSSProperties => {
  const styles: CSSProperties = {};
  if (!border && fallbackBorderColor) {
    styles.borderColor = fallbackBorderColor;
    styles.borderTopColor = fallbackBorderColor;
    styles.borderRightColor = fallbackBorderColor;
    styles.borderBottomColor = fallbackBorderColor;
    styles.borderLeftColor = fallbackBorderColor;
  }

  if (border?.left) {
    styles.borderLeftColor = border.left.color ?? fallbackBorderColor;
    styles.borderLeftWidth = border.left.width
      ? `${border.left.width.value ?? 0}${border.left.width.mode ?? "px"}`
      : "0px";
    styles.borderLeftStyle = border.left.style ?? "solid";
  }
  if (border?.right) {
    styles.borderRightColor = border.right.color ?? fallbackBorderColor;
    styles.borderRightWidth = border.right.width
      ? `${border.right.width.value ?? 0}${border.right.width.mode ?? "px"}`
      : "0px";
    styles.borderRightStyle = border.right.style ?? "solid";
  }
  if (border?.top) {
    styles.borderTopColor = border.top.color ?? fallbackBorderColor;
    styles.borderTopWidth = border.top.width
      ? `${border.top.width.value ?? 0}${border.top.width.mode ?? "px"}`
      : "0px";
    styles.borderTopStyle = border.top.style ?? "solid";
  }
  if (border?.bottom) {
    styles.borderBottomColor = border.bottom.color ?? fallbackBorderColor;
    styles.borderBottomWidth = border.bottom.width
      ? `${border.bottom.width.value ?? 0}${border.bottom.width.mode ?? "px"}`
      : "0px";
    styles.borderBottomStyle = border.bottom.style ?? "solid";
  }
  return styles;
};

export const generateBorderRadiusStyleObject = ({
  borderRadius,
  fallbackBorderRadius,
}: {
  borderRadius?: PerCornerBorderRadius;
  fallbackBorderRadius?: Dimension<"px">;
}): CSSProperties => {
  if (!borderRadius && fallbackBorderRadius) {
    return {
      borderRadius: `${fallbackBorderRadius.value}${fallbackBorderRadius.mode}`,
    };
  }

  const styles: CSSProperties = {};
  if (borderRadius?.topLeft) {
    styles.borderTopLeftRadius = `${
      borderRadius.topLeft.value ?? fallbackBorderRadius?.value ?? 0
    }${borderRadius.topLeft.mode ?? fallbackBorderRadius?.mode ?? "px"}`;
  }
  if (borderRadius?.topRight) {
    styles.borderTopRightRadius = `${
      borderRadius.topRight.value ?? fallbackBorderRadius?.value ?? 0
    }${borderRadius.topRight.mode ?? fallbackBorderRadius?.mode ?? "px"}`;
  }
  if (borderRadius?.bottomLeft) {
    styles.borderBottomLeftRadius = `${
      borderRadius.bottomLeft.value ?? fallbackBorderRadius?.value ?? 0
    }${borderRadius.bottomLeft.mode ?? fallbackBorderRadius?.mode ?? "px"}`;
  }
  if (borderRadius?.bottomRight) {
    styles.borderBottomRightRadius = `${borderRadius.bottomRight.value ?? 0}${
      borderRadius.bottomRight.mode ?? fallbackBorderRadius?.mode ?? "px"
    }`;
  }
  return styles;
};
