import { Dimension } from "@superblocksteam/shared";
import { MultiStepDef, StepDef } from "legacy/constants/ActionConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { getDefaultColumnProperties } from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import { fastClone } from "utils/clone";
import { ImageSize } from "./TableComponent/types";
import { TableWidgetProps } from "./TableWidgetConstants";
import type { WidgetActionResponse, CanvasWidgetsReduxState } from "../Factory";

// This class helps to inject derived columns and dynamic binding for default table upon creating

export const insertDefaultTableData = (
  widgetId: string,
  widgets: Readonly<CanvasWidgetsReduxState>,
) => {
  const draft = fastClone(widgets) as CanvasWidgetsReduxState;
  const updateList: WidgetActionResponse = [];
  const widget = draft[widgetId] as FlattenedWidgetProps & TableWidgetProps;
  const name = getDefaultColumnProperties("name", 0, widget.widgetName, false);
  const email = getDefaultColumnProperties(
    "email",
    4,
    widget.widgetName,
    false,
  );
  const photo = getDefaultColumnProperties(
    "photo",
    3,
    widget.widgetName,
    false,
  );
  const twitter = getDefaultColumnProperties(
    "twitter",
    1,
    widget.widgetName,
    false,
  );
  const date_joined = getDefaultColumnProperties(
    "date_joined",
    2,
    widget.widgetName,
    false,
  );

  const action = getDefaultColumnProperties(
    "action",
    5,
    widget.widgetName,
    false,
  );

  photo.columnType = "image";
  photo.imageBorderRadius = { mode: "%", value: 50 } as Dimension;
  photo.imageSize = ImageSize.Fixed;
  photo.openImageUrl = true;
  twitter.columnType = "link";
  twitter.linkLabel = `{{${widget.widgetName}.tableDataWithInserts.map((currentRow) => { return '@' + currentRow.twitter?.split('/').pop()})}}`;

  date_joined.label = "date joined";
  date_joined.columnType = "date";
  date_joined.inputFormat = "YYYY-MM-DD";
  date_joined.outputFormat = "MM-DD-YYYY";

  action.columnType = "button";
  action.buttonLabel = "Approve";
  action.onClick = [
    {
      id: "id0",
      type: "runJs",
      code: "showAlert(currentRow.name + ' is approved!', 'success')",
      newWindow: true, // to make url open in new window by default
    } as StepDef,
  ] as MultiStepDef;

  widget.primaryColumns = {
    name,
    email,
    photo,
    twitter,
    date_joined,
    action,
  };

  widget.columnOrder = [
    "photo",
    "name",
    "twitter",
    "date_joined",
    "email",
    "action",
  ];

  widget.dynamicBindingPathList = [
    {
      key: "primaryColumns.name.linkUrl",
    },
    {
      key: "primaryColumns.name.linkLabel",
    },
    {
      key: "primaryColumns.name.computedValue",
    },
    {
      key: "primaryColumns.name.displayedValue",
    },
    {
      key: "primaryColumns.twitter.computedValue",
    },
    {
      key: "primaryColumns.twitter.linkUrl",
    },
    {
      key: "primaryColumns.twitter.linkLabel",
    },
    {
      key: "primaryColumns.date_joined.computedValue",
    },
    {
      key: "primaryColumns.date_joined.linkUrl",
    },
    {
      key: "primaryColumns.date_joined.linkLabel",
    },
    {
      key: "primaryColumns.photo.computedValue",
    },
    {
      key: "primaryColumns.photo.linkUrl",
    },
    {
      key: "primaryColumns.photo.linkLabel",
    },
    {
      key: "primaryColumns.photo.displayedValue",
    },
    {
      key: "primaryColumns.email.computedValue",
    },
    {
      key: "primaryColumns.email.linkUrl",
    },
    {
      key: "primaryColumns.email.linkLabel",
    },
    {
      key: "primaryColumns.email.displayedValue",
    },
    {
      key: "primaryColumns.action.computedValue",
    },
    {
      key: "primaryColumns.action.linkUrl",
    },
    {
      key: "primaryColumns.action.linkLabel",
    },
    {
      key: "primaryColumns.action.buttonLabel",
    },
    {
      key: "primaryColumns.action.displayedValue",
    },
  ];

  widget.dynamicTriggerPathList = [
    {
      key: "primaryColumns.action.onClick",
    },
  ];
  updateList.push({ widgetId, widget });
  return updateList;
};
