import {
  Border,
  Dimension,
  PerSideBorder,
  Typographies,
} from "@superblocksteam/shared";

export type InputType = (typeof InputTypes)[keyof typeof InputTypes];

export const InputTypes: { [key: string]: string } = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  INTEGER: "INTEGER",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL: "EMAIL",
  PASSWORD: "PASSWORD",
  CURRENCY: "CURRENCY",
  SEARCH: "SEARCH",
  PERCENTAGE: "PERCENTAGE",
  URL: "URL",
};

export const INPUT_TYPE_OPTIONS = [
  {
    label: "Text",
    value: InputTypes.TEXT,
  },
  {
    label: "Number",
    value: InputTypes.NUMBER,
  },
  {
    label: "Percentage",
    value: InputTypes.PERCENTAGE,
  },
  {
    label: "Currency",
    value: InputTypes.CURRENCY,
  },
  {
    label: "Password",
    value: InputTypes.PASSWORD,
  },
  {
    label: "Email",
    value: InputTypes.EMAIL,
  },
  {
    label: "URL",
    value: InputTypes.URL,
  },
];

export const DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT: keyof Typographies =
  "inputText";
export const DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT: keyof Typographies =
  "inputLabel";

export const INPUT_TYPES_WITH_MINMAX = [InputTypes.TEXT, InputTypes.PASSWORD];

// map of currency ISO codes to currency symbol
// from https://github.com/bengourley/currency-symbol-map/blob/master/map.js
export const currencySymbolMap: Record<string, string> = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BOV: "BOV",
  BRL: "R$",
  BSD: "$",
  BTC: "₿",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BYR: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "$",
  CNH: "¥",
  CNY: "¥",
  COP: "$",
  COU: "COU",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYI: "UYI",
  UYU: "$U",
  UYW: "UYW",
  UZS: "лв",
  VEF: "Bs",
  VES: "Bs.S",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  XSU: "Sucre",
  XUA: "XUA",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWD: "Z$",
  ZWL: "$",
};

// map of currency ISO codes to currency decimal places
// from https://github.com/ourworldincode/currency/blob/main/currencies.json
export const currencyDecimalPlaces: Record<string, number> = {
  AED: 2,
  AFN: 2,
  ALL: 2,
  AMD: 2,
  ANG: 2,
  AOA: 2,
  ARS: 2,
  AUD: 2,
  AWG: 2,
  AZN: 2,
  BAM: 2,
  BBD: 2,
  BDT: 2,
  BGN: 2,
  BHD: 3,
  BIF: 2,
  BMD: 2,
  BND: 2,
  BOB: 2,
  BRL: 2,
  BSD: 2,
  BTN: 2,
  BWP: 2,
  BYN: 2,
  BZD: 2,
  CAD: 2,
  CDF: 2,
  CHF: 2,
  CKD: 2,
  CLP: 0,
  CNY: 2,
  COP: 2,
  CRC: 2,
  CUC: 2,
  CUP: 2,
  CVE: 2,
  CZK: 2,
  DJF: 2,
  DKK: 2,
  DOP: 2,
  DZD: 2,
  EGP: 2,
  EHP: 2,
  ERN: 2,
  ETB: 2,
  EUR: 2,
  FJD: 2,
  FKP: 2,
  FOK: 2,
  GBP: 2,
  GEL: 2,
  GGP: 2,
  GHS: 2,
  GIP: 2,
  GMD: 2,
  GNF: 2,
  GTQ: 2,
  GYD: 2,
  HKD: 2,
  HNL: 2,
  HRK: 2,
  HTG: 2,
  HUF: 2,
  IDR: 2,
  ILS: 2,
  IMP: 2,
  INR: 2,
  IQD: 3,
  IRR: 2,
  ISK: 2,
  JEP: 2,
  JMD: 2,
  JOD: 3,
  JPY: 2,
  KES: 2,
  KGS: 2,
  KHR: 2,
  KID: 2,
  KMF: 2,
  KPW: 2,
  KRW: 2,
  KWD: 3,
  KYD: 2,
  KZT: 2,
  LAK: 2,
  LBP: 2,
  LKR: 2,
  LRD: 2,
  LSL: 2,
  LYD: 3,
  MAD: 2,
  MDL: 2,
  MGA: 0,
  MKD: 2,
  MMK: 2,
  MNT: 2,
  MOP: 2,
  MRU: 0,
  MUR: 2,
  MVR: 2,
  MWK: 2,
  MXN: 2,
  MYR: 2,
  MZN: 2,
  NAD: 2,
  NGN: 2,
  NIO: 2,
  NOK: 2,
  NPR: 2,
  NZD: 2,
  OMR: 3,
  PAB: 2,
  PEN: 2,
  PGK: 2,
  PHP: 2,
  PKR: 2,
  PLN: 2,
  PND: 2,
  PRB: 2,
  PYG: 2,
  QAR: 2,
  RON: 2,
  RSD: 2,
  RUB: 2,
  RWF: 2,
  SAR: 2,
  SBD: 2,
  SCR: 2,
  SDG: 2,
  SEK: 2,
  SGD: 2,
  SHP: 2,
  SLL: 2,
  SLS: 2,
  SOS: 2,
  SRD: 2,
  SSP: 2,
  STN: 2,
  SVC: 2,
  SYP: 2,
  SZL: 2,
  THB: 2,
  TJS: 2,
  TMT: 2,
  TND: 3,
  TOP: 2,
  TRY: 2,
  TTD: 2,
  TVD: 2,
  TWD: 2,
  TZS: 2,
  UAH: 2,
  UGX: 2,
  USD: 2,
  UYU: 2,
  UZS: 2,
  VED: 2,
  VES: 2,
  VND: 2,
  VUV: 0,
  WST: 2,
  XAF: 2,
  XCD: 2,
  XOF: 2,
  XPF: 0,
  YER: 2,
  ZAR: 2,
  ZMW: 2,
  ZWB: 0,
  ZWL: 2,
};

const DEFAULT_INPUT_BORDER: Border = {
  width: Dimension.px(1),
  style: "solid",
  color: "{{ theme.colors.neutral100 }}",
};

export const DEFAULT_INPUT_BORDER_OBJECT: PerSideBorder = {
  left: DEFAULT_INPUT_BORDER,
  right: DEFAULT_INPUT_BORDER,
  top: DEFAULT_INPUT_BORDER,
  bottom: DEFAULT_INPUT_BORDER,
};
