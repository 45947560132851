import { Dimension } from "@superblocksteam/shared";
import { useCallback, useContext } from "react";
import { WidgetAddChild } from "legacy/actions/pageActions";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import {
  MultiStepDef,
  TriggerStepType,
} from "legacy/constants/ActionConstants";
import {
  WidgetTypes,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { WidgetOperations } from "legacy/widgets/WidgetOperations";
import { generateReactKey } from "./generators";

export const useCreateOpenButton = () => {
  const { updateWidget } = useContext(EditorContext);
  return useCallback(
    (params: {
      widgetToOpenInfo: {
        name: string;
        type: WidgetTypes;
      };
      parentWidgetId: string;
      position: { left: Dimension<"gridUnit">; top: Dimension<"gridUnit"> };
      size: {
        width: Dimension<WidgetWidthModes>;
        height: Dimension<WidgetHeightModes>;
      };
      newChildIndex?: number;
    }) => {
      const {
        parentWidgetId,
        widgetToOpenInfo,
        position,
        size,
        newChildIndex,
      } = params;
      const widgetId = generateReactKey();

      const updateButtonWidgetParams: {
        operation: typeof WidgetOperations.WIDGET_CREATE;
        widgetId: string;
        payload: Omit<WidgetAddChild, "widgetId">;
      } = {
        operation: WidgetOperations.WIDGET_CREATE,
        widgetId: parentWidgetId,
        payload: {
          type: WidgetTypes.BUTTON_WIDGET,
          size,
          position,
          newWidgetId: widgetId,
          newChildIndex,
        } satisfies Omit<WidgetAddChild, "widgetId">,
      };

      updateButtonWidgetParams.payload.props = {
        text: `Open ${
          widgetToOpenInfo.type === WidgetTypes.MODAL_WIDGET
            ? "Modal"
            : "Slideout"
        }`,
        dynamicTriggerPathList: [
          {
            key: "onClick",
          },
        ],
        onClick: [
          {
            direction: "open",
            id: widgetId,
            type:
              widgetToOpenInfo.type === WidgetTypes.MODAL_WIDGET
                ? TriggerStepType.CONTROL_MODAL
                : TriggerStepType.CONTROL_SLIDEOUT,
            name: widgetToOpenInfo.name,
          },
        ] as MultiStepDef,
      };
      updateWidget?.(
        updateButtonWidgetParams.operation,
        updateButtonWidgetParams.widgetId,
        updateButtonWidgetParams.payload,
      );
    },
    [updateWidget],
  );
};
