import { debounce } from "lodash";
import { useCallback, useEffect } from "react";

const BUFFER_PX = 2;
export const useStickySectionOnParentScroll = (parentDataTag?: string) => {
  const handleScroll = useCallback(() => {
    if (!parentDataTag) return;
    const parent = document.querySelector(
      `[data-superblocks=${parentDataTag}]`,
    );
    if (!parent) return;
    const stickySections = Array.from(
      parent.querySelectorAll("[data-superblocks=sticky-section]"),
    ) as HTMLElement[];
    let lastStuck: HTMLElement | null = null;
    stickySections.forEach((section) => {
      if (parent.scrollTop === 0) {
        section.classList.remove("sticky-bottom-shadow");
        section.classList.remove("sticky-stuck");
        return;
      }
      const rect = section.getBoundingClientRect();
      const topStyle = parseInt(section.style.top, 10) || 0;
      if (rect.top <= topStyle + BUFFER_PX) {
        if (lastStuck) {
          lastStuck.classList.remove("sticky-bottom-shadow"); // Remove shadow from the previously stuck section
        }
        lastStuck = section; // Update last stuck section
        section.classList.add("sticky-bottom-shadow"); // Add shadow to the current stuck section
        section.classList.add("sticky-stuck");
      } else {
        section.classList.remove("sticky-bottom-shadow"); // Ensure no shadow if not stuck
        section.classList.remove("sticky-stuck");
      }
    });
  }, [parentDataTag]);

  useEffect(() => {
    if (!parentDataTag) return;
    const parent = document.querySelector(
      `[data-superblocks=${parentDataTag}]`,
    );
    if (!parent) return;
    const debouncedHandleScroll = debounce(handleScroll, 50, {
      leading: true,
    });
    parent.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      parent.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll, parentDataTag]);
};
