import { isEmpty } from "lodash";
import React from "react";
import styled from "styled-components";
import { TextStyleWithVariant } from "legacy/themes";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { useTypographyStyling } from "../typographyHooks";

const ChartHeaderTitle = styled.div`
  margin: 15px 20px;
  white-space: pre-wrap;
`;

export const ChartHeader = ({
  title,
  headerProps,
}: {
  title?: string;
  headerProps?: { textStyle: TextStyleWithVariant };
}) => {
  const headerStyleProps = useTypographyStyling({
    textStyle: headerProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
  });

  const headerClassName = getTextCssClassFromVariant(
    headerStyleProps.textStyleVariant,
  );

  return isEmpty(title) ? null : (
    <ChartHeaderTitle
      style={headerStyleProps.style}
      className={headerClassName}
    >
      {title}
    </ChartHeaderTitle>
  );
};
