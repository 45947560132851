import { RouteDef } from "@superblocksteam/shared";
import {
  getCurrentQueryParams,
  getSystemQueryParams,
} from "legacy/utils/queryParams";
import { Result } from "utils/result";
import { extractDynamicSegments, getFinalRoutePath } from "utils/routing";

export function buildURL({
  targetPath,
  basePath,
  params,
}: {
  targetPath: string;
  basePath?: string;
  params?: Record<string, string>;
}): URL {
  // Within the Superblocks context, we only allow absolute paths _within_ the application
  const relativePath = targetPath.substring(1);

  // ensure we handle the optional relative path correctly
  const base = basePath
    ? basePath.endsWith("/")
      ? basePath
      : basePath + "/"
    : "";

  const url = new URL(relativePath, window.location.origin + base);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }
  url.search = url.searchParams.toString();

  return url;
}

export interface RouteToUrlError {
  missingParams: string[];
}

export interface NavigateToRouteOpts {
  keepQueryParams?: boolean;
  currentQueryParamsToKeep?: Record<string, string>; // if not given, we use `window.location.search` for params. When used inside an iframe, you must pass the current query params of the parent window.
  routePathDescriptor?: string;
  routeParams?: Record<string, string>;
  queryParams?: Record<string, string>;
}

export function validateAndBuildRouteInputs(
  route: RouteDef,
  navigateToRoute: NavigateToRouteOpts,
): Result<
  { path: string; queryParams: Record<string, string> },
  RouteToUrlError
> {
  const routeParams = navigateToRoute.routeParams || {};
  const systemQueryParams = getSystemQueryParams();
  let queryParams = {
    ...(navigateToRoute.queryParams || {}),
    ...systemQueryParams,
  };

  if (navigateToRoute.keepQueryParams) {
    const existingQueryParams =
      navigateToRoute.currentQueryParamsToKeep ?? getCurrentQueryParams(false);

    queryParams = {
      ...existingQueryParams,
      ...queryParams,
    };
  }

  const dynamicSegments = extractDynamicSegments(route.path);
  const haveAllRouteParams = dynamicSegments.every((segment) =>
    Boolean(routeParams[segment]),
  );

  if (!haveAllRouteParams) {
    const missingParams = dynamicSegments.filter(
      (segment) => !routeParams[segment],
    );

    return {
      ok: false,
      error: {
        missingParams,
      },
    };
  }

  const path = getFinalRoutePath(route.path, routeParams);
  return {
    ok: true,
    value: {
      path,
      queryParams,
    },
  };
}
