import { Dimension, getNextEntityName } from "@superblocksteam/shared";
import { useContext, useCallback } from "react";
import "utils/polyfills/requestIdleCallback";

import { useSelector } from "react-redux";
import { WidgetAddChild, WidgetMove } from "legacy/actions/pageActions";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import { CanvasLayout, WidgetTypes } from "legacy/constants/WidgetConstants";
import { useWidgetSelection } from "legacy/hooks/dragResizeHooks";
import { getExistingWidgetNames } from "legacy/selectors/sagaSelectors";
import { useCreateOpenButton } from "legacy/utils/useCreateOpenButton";
import { WidgetPropsRuntime } from "../BaseWidget";
import { WidgetOperations } from "../WidgetOperations";
import { NEW_WIDGET_SYMBOL } from "./constants";
import type { StackAdjustmentsInfo } from "./types";
import type { WidgetConfigProps } from "legacy/mockResponses/WidgetConfigResponse";

export const useHandleStackDrop = (params: {
  parentWidget:
    | Pick<WidgetPropsRuntime, "widgetId" | "layout" | "gridColumns">
    | undefined;
}) => {
  const {
    widgetId: parentWidgetId,
    layout,
    gridColumns,
  } = params.parentWidget ?? {};
  const { updateWidget } = useContext(EditorContext);
  const { selectWidgets } = useWidgetSelection();
  const widgetNames = useSelector(getExistingWidgetNames);
  const createOpenButton = useCreateOpenButton();

  const handleDrop = useCallback(
    (dropParams: {
      insertionIndex: number;
      widget: WidgetPropsRuntime & Partial<WidgetConfigProps>;
      adjustments?: StackAdjustmentsInfo | undefined;
    }) => {
      const { widget, insertionIndex, adjustments } = dropParams;

      let payloadWidth =
        adjustments?.[widget.widgetId ?? NEW_WIDGET_SYMBOL]?.newWidth ??
        widget.width;
      if (gridColumns != null && layout !== CanvasLayout.HSTACK) {
        payloadWidth = Dimension.min(
          widget.width,
          Dimension.gridUnit(gridColumns),
          widget.parentColumnSpace,
        ).asFirst();
      }

      const size = {
        width: payloadWidth,
        height: widget.height,
      };

      if (!parentWidgetId) {
        return;
      }

      if (widget.widgetName) {
        // then is move
        const updateWidgetParams = {
          operation: WidgetOperations.WIDGETS_MOVE,
          widgetId: widget.widgetId,
          payload: {
            position: {
              left: Dimension.gridUnit(0),
              top: Dimension.gridUnit(0),
            },
            size,
            parentId: widget.parentId,
            newParentId: parentWidgetId,
            newChildIndex: insertionIndex,
            stackAdjustments: adjustments,
          } satisfies Omit<WidgetMove, "widgetId">,
        };
        updateWidget?.(
          updateWidgetParams.operation,
          updateWidgetParams.widgetId,
          updateWidgetParams.payload,
        );
      } else {
        // is create

        const updateWidgetParams: {
          operation: typeof WidgetOperations.WIDGET_CREATE;
          widgetId: string;
          payload: Omit<WidgetAddChild, "widgetId">;
        } = {
          operation: WidgetOperations.WIDGET_CREATE,
          widgetId: parentWidgetId,
          payload: {
            type: widget.type,
            position: {
              left: Dimension.gridUnit(0),
              top: Dimension.gridUnit(0),
            },
            size,
            newChildIndex: insertionIndex,
            newWidgetId: widget.widgetId,
            stackAdjustments: adjustments,
          } satisfies Omit<WidgetAddChild, "widgetId">,
        };

        if (
          widget.type === WidgetTypes.MODAL_WIDGET ||
          widget.type === WidgetTypes.SLIDEOUT_WIDGET
        ) {
          const nextName = getNextEntityName(
            widget.type === WidgetTypes.MODAL_WIDGET ? "Modal" : "Slideout",
            widgetNames,
          );
          updateWidgetParams.payload.widgetName = nextName;
        }

        updateWidget?.(
          updateWidgetParams.operation,
          updateWidgetParams.widgetId,
          updateWidgetParams.payload,
        );

        requestIdleCallback(() => selectWidgets?.([widget.widgetId]), {
          timeout: 50,
        });

        if (
          widget.type === WidgetTypes.MODAL_WIDGET ||
          widget.type === WidgetTypes.SLIDEOUT_WIDGET
        ) {
          if (updateWidgetParams.payload.widgetName) {
            createOpenButton({
              position: {
                left: Dimension.gridUnit(0),
                top: Dimension.gridUnit(0),
              },
              size: {
                height: widget.height,
                width: widget.width,
              },
              parentWidgetId,
              widgetToOpenInfo: {
                name: updateWidgetParams.payload.widgetName,
                type: widget.type as WidgetTypes,
              },
              newChildIndex: insertionIndex,
            });
          }
        }
      }
    },
    [
      parentWidgetId,
      createOpenButton,
      updateWidget,
      widgetNames,
      gridColumns,
      selectWidgets,
      layout,
    ],
  );

  return handleDrop;
};
