// import * as diff from "diff";
// import { DiffMethod } from "./DiffMethod";

export const derived = {
  diffs: `() => {
    let result = [];
    switch (this.compareMethod) {
      case "diffJson":
        result = diff.diffJson(this.originalText ?? "", this.newText ?? "");
        break;
      case "diffCss":
        result = diff.diffCss(this.originalText ?? "", this.newText ?? "");
        break;
      case "diffChars":
        result = diff.diffChars(this.originalText ?? "", this.newText ?? "");
        break;
      case "diffWords":
        result = diff.diffWords(this.originalText ?? "", this.newText ?? "");
        break;
      case "diffLines":
      default:
        result = diff.diffLines(this.originalText ?? "", this.newText ?? "");
        break;
    }
    return result;
  }`,
};
