import { updateWidgetProperties } from "legacy/actions/controlActions";
import { deleteWidgets } from "legacy/actions/widgetActions";

export const WidgetOperations = {
  WIDGETS_MOVE: "WIDGETS_MOVE",
  WIDGET_RESIZE: "WIDGET_RESIZE",
  WIDGET_REPARENT: "WIDGET_REPARENT",
  WIDGET_CREATE: "WIDGET_CREATE",
  WIDGET_DELETE: deleteWidgets.type,
  WIDGET_ADD_CHILD_IF_NOT_EXISTS: "WIDGET_ADD_CHILD_IF_NOT_EXISTS",
  UPDATE_WIDGET_PROPERTIES: updateWidgetProperties.type,
} as const;

export type WidgetOperation =
  (typeof WidgetOperations)[keyof typeof WidgetOperations];
