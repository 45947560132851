import { Margin, ThemeMode } from "@superblocksteam/shared";
import React, { useMemo } from "react";
import styled from "styled-components";
import marginSVG from "assets/images/canvas/canvasMargin.svg";
import marginDarkSVG from "assets/images/canvas/canvasMarginDark.svg";
import { selectIsDragging } from "legacy/selectors/dndSelectors";
import { getMarginIsFocused } from "legacy/selectors/propertyPaneSelectors";
import {
  getIsWidgetFocused,
  getIsWidgetSelected,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";
import { generateMaskSize, StyledBorder } from "./StyledBorder";

const StyledMarginBorder = styled(StyledBorder)`
  transition: opacity 0.1s;
  &[data-dark-theme="true"] {
    background: url(${marginDarkSVG});
  }
  &[data-dark-theme="false"] {
    background: url(${marginSVG});
  }
`;

const MarginOverlay = (props: {
  widgetId: string;
  parentId: string;
  margin?: Margin;
}) => {
  const theme = useAppSelector(selectGeneratedTheme);
  const propertyIsHovered = useAppSelector(getMarginIsFocused);
  const widgetSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, props.widgetId),
  );
  const widgetFocused = useAppSelector((state) =>
    getIsWidgetFocused(state, props.widgetId),
  );
  const parentSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, props.parentId || ""),
  );
  const isDropTarget = useAppSelector(
    (state) =>
      state.legacy.ui.widgetDragResize.currentDropTarget === props.parentId,
  );
  const widgetIsDragging = useAppSelector(selectIsDragging) && widgetSelected;

  const show =
    !widgetIsDragging &&
    (widgetSelected || widgetFocused || isDropTarget || parentSelected);
  const style = useMemo(() => {
    return {
      maskImage: generateMaskSize(props.margin),
      top: `-${props.margin?.top?.value ?? 0}px`,
      left: `-${props.margin?.left?.value ?? 0}px`,
      height: `calc(100% + ${Margin.y(props.margin).value ?? 0}px)`,
      width: `calc(100% + ${Margin.x(props.margin).value ?? 0}px)`,
      opacity: propertyIsHovered ? 1 : 0.4,
    };
  }, [propertyIsHovered, props.margin]);

  return show ? (
    <StyledMarginBorder
      style={style}
      data-dark-theme={theme.mode === ThemeMode.DARK}
    />
  ) : (
    <></>
  );
};

export default MarginOverlay;
