import {
  PropertyPaneCategoryConfig,
  PropertyPaneConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { CATEGORY_PROPERTIES } from "./upgradePropertyConfig";

export const PanelCategory = (
  category: PropsPanelCategory,
  children: PropertyPaneConfig[],
  options?: {
    noHeader?: boolean;
  },
): PropertyPaneCategoryConfig => {
  const noHeader = options?.noHeader ?? false;
  const categoryInfo = CATEGORY_PROPERTIES[category];
  return {
    categoryName: categoryInfo.title,
    key: category,
    sectionCategory: category,
    headerType: noHeader ? undefined : "Collapse",
    showHeader: noHeader ? false : true,
    children,
  };
};
