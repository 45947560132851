import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Converter } from "showdown";
import styled, { css } from "styled-components";
import { AutolinkedText } from "components/ui/AutolinkedText";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";

const ChatWrapper = styled.div`
  display: flex;
  align-items: start;
  max-width: 100%;
  margin-bottom: 20px;
`;

const ChatBubble = styled.div<{ $isPending: boolean }>`
  flex-grow: 1;
  min-height: 32px;
  max-width: 100%;

  img {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    height: auto;
    border-radius: 4px;
  }
  p,
  ul,
  li,
  ol {
    margin: 0px;
    word-break: break-word;
  }
`;

const AvatarStyles = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  flex: 0;
  min-width: 32px;
`;

const Avatar = styled.div<{ backgroundImage: string }>`
  ${AvatarStyles};
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const DefaultAvatar = styled.div`
  ${AvatarStyles};
  font-size: 20px;
  text-align: center;
  line-height: 32px;
`;

const ChatHeader = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

interface ChatProps {
  avatar?: string;
  displayName: string;
  formattedTimestamp?: string;
  message: string;
  isPending?: boolean;
}

const converter = new Converter();
converter.setOption("simpleLineBreaks", true);

const Chat = (props: ChatProps) => {
  const generatedTheme = useSelector(selectGeneratedTheme);
  const parsedMessage = useMemo(() => {
    if (!props.message) {
      return "";
    }
    if (typeof props.message === "object") {
      try {
        const stringified = JSON.stringify(props.message, null, 2);
        return stringified;
      } catch (e) {
        return "Invalid data format";
      }
    }
    return converter.makeHtml(String(props.message)).toString();
  }, [props.message]);

  return (
    <ChatWrapper>
      {props.avatar && typeof props.avatar === "string" ? (
        <Avatar backgroundImage={props.avatar} />
      ) : (
        <DefaultAvatar
          style={{
            background: generatedTheme.colors.primary500,
            color: generatedTheme.colors.contrastText,
          }}
        >
          {(typeof props.displayName === "string" && props.displayName?.[0]) ??
            "?"}
        </DefaultAvatar>
      )}
      <ChatBubble
        $isPending={props.isPending === true}
        className={`${CLASS_NAMES.LABEL} ${
          props.isPending ? CLASS_NAMES.DISABLED_MODIFIER : ""
        }`}
      >
        <ChatHeader>
          <span
            className={CLASS_NAMES.LABEL}
            style={{
              marginRight: "4px",
            }}
          >
            {typeof props.displayName === "string"
              ? props.displayName
              : String(props.displayName)}
          </span>
          <span
            className={`${CLASS_NAMES.LABEL} ${CLASS_NAMES.DISABLED_MODIFIER}`}
            style={{ fontWeight: 400 }}
          >
            {props.formattedTimestamp}
          </span>
        </ChatHeader>
        <div className={`${CLASS_NAMES.BODY3}`}>
          {props.message && (
            <AutolinkedText text={parsedMessage} target="_parent" />
          )}
        </div>
      </ChatBubble>
    </ChatWrapper>
  );
};

export default memo(Chat);
