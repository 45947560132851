import { Dimension } from "@superblocksteam/shared";
import React, { useRef } from "react";
import {
  GridDefaults,
  WidgetHeightConstraintType,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { useWidgetDragResize } from "legacy/hooks/dragResizeHooks";

const useWidgetHeightConstraintResize = (params: {
  height: Dimension<WidgetHeightModes>;
  setIsResizingConstraint: (
    value: boolean,
    constraintType: WidgetHeightConstraintType,
  ) => void;
  setResizingHeight: (
    value: number,
    constraintType: WidgetHeightConstraintType,
  ) => void;
  constraintType: WidgetHeightConstraintType;
  onResizeEnded: (
    constraintType: WidgetHeightConstraintType,
    height: Dimension<"gridUnit">,
  ) => void;
}) => {
  const {
    height,
    setIsResizingConstraint,
    setResizingHeight,
    constraintType,
    onResizeEnded,
  } = params;

  const heightValueGU = Dimension.toGridUnit(
    height,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw().value;

  // We want to update the global editor state to indicate that we are resizing
  // a widget so that the editor responds in the same ways as when we're resizing a widget
  // with the normal resize drag handles
  const { setIsResizing: setIsResizingWidget } = useWidgetDragResize();

  const dragInitialValues = useRef<null | {
    dragYStartPosition: number;
    height: number | undefined; // in grid units
    minSizePx: number;
  }>(null);

  const previousSetHeight = useRef<Dimension<"gridUnit"> | null>(null);

  const drag = (e: PointerEvent) => {
    if (!dragInitialValues.current || dragInitialValues.current.height == null)
      return;

    const yDiff = e.clientY - dragInitialValues.current.dragYStartPosition;

    const rowsChange =
      Math.floor(Math.abs(yDiff) / GridDefaults.DEFAULT_GRID_ROW_HEIGHT) *
      Math.sign(yDiff);
    const constraintHeightChange = Dimension.gridUnit(rowsChange);

    const newHeight = Dimension.add(
      Dimension.gridUnit(dragInitialValues.current.height),
      constraintHeightChange,
    ).asFirst();

    if (previousSetHeight.current?.value === newHeight.value) return;

    const newHeightPx = Dimension.toPx(
      newHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    );
    // Don't allow resize to smaller than 1 grid row (todo: add largest canvas padding to this)
    if (newHeightPx.value < dragInitialValues.current.minSizePx) return;
    previousSetHeight.current = newHeight;

    setResizingHeight(newHeightPx.value, constraintType);
  };

  const endDrag = (e: PointerEvent) => {
    setIsResizingConstraint(false, constraintType);
    setIsResizingWidget(false);
    document.removeEventListener("pointermove", drag);
    document.removeEventListener("pointerup", endDrag);
    if (previousSetHeight.current) {
      onResizeEnded(constraintType, previousSetHeight.current);
    }
  };

  const startDrag = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dragInitialValues.current = {
      dragYStartPosition: e.clientY,
      height: heightValueGU,
      minSizePx: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    };
    previousSetHeight.current = null;
    setIsResizingConstraint(true, constraintType);
    setIsResizingWidget(true);
    document.addEventListener("pointermove", drag);
    document.addEventListener("pointerup", endDrag);
  };

  return {
    startDrag,
  };
};

export default useWidgetHeightConstraintResize;
