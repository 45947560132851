import React from "react";
import FocusableComponent from "./FocusableComponent";
import SelectionAndFocusWrapper from "./SelectionAndFocusWrapper";
import type { WidgetProps } from "legacy/widgets";

type SelectableAndFocusableComponentProps = {
  widget: WidgetProps;
  children?: React.ReactNode | React.ReactChildren;
  deselectOnSecondClick?: boolean;
  disabled?: boolean;
  requireDoubleClick?: boolean;
  focusedRectInset?: number;
  selectedRectInset?: number;
  parentRectInset?: number;
};

const SelectableAndFocusableComponent = ({
  widget,
  children,
  deselectOnSecondClick,
  disabled,
  requireDoubleClick,
  focusedRectInset,
  selectedRectInset,
  parentRectInset,
}: SelectableAndFocusableComponentProps) => {
  return (
    <FocusableComponent
      {...widget}
      disabled={disabled}
      deselectOnSecondClick={deselectOnSecondClick}
      requireDoubleClick={requireDoubleClick}
    >
      <SelectionAndFocusWrapper
        disabled={disabled}
        widgetType={widget.type}
        widgetId={widget.widgetId}
        widgetName={widget.widgetName}
        hasInvalidProps={false}
        focusedRectInset={focusedRectInset}
        selectedRectInset={selectedRectInset}
        parentRectInset={parentRectInset}
      />
      {children}
    </FocusableComponent>
  );
};

SelectableAndFocusableComponent.displayName = "SelectableAndFocusableComponent";

export default SelectableAndFocusableComponent;
