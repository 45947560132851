import { Dimension } from "@superblocksteam/shared";
import { Context, createContext } from "react";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";

/*
  This context will provide the function which will help the draglayer and resizablecomponents trigger
  an update of the main container's rows
*/
export interface UpdateTemporaryResizeValue {
  (
    widgetId: string,
    size: { width: number; height: number },
    position: { x: number; y: number },
  ): void;
  (): void;
}
export type DropTargetContextType = {
  updateDropTargetRows?: (widgetId: string, row: number) => number;
  persistDropTargetRows?: (widgetId: string, row: number) => void;
  updateTemporaryResizeValue?: UpdateTemporaryResizeValue;
  temporaryResizeValue?:
    | {
        widgetId: string;
        size: { width: number; height: number };
        position: { x: number; y: number };
      }
    | undefined;
  occupiedSpaces?: OccupiedSpace[];
};
export const DropTargetContext: Context<DropTargetContextType> = createContext(
  {},
);

export const calculateDropTargetRows = (
  widgetId: string,
  widgetBottomRow: number,
  defaultRows: number,
  occupiedSpacesByChildren?: OccupiedSpace[],
  canDropTargetExtend?: boolean,
  extraRows?: number,
) => {
  /* Max bottom row including the existing widgets as well as the widget we just dropped */
  let minBottomRow = widgetBottomRow;
  if (occupiedSpacesByChildren) {
    minBottomRow = occupiedSpacesByChildren.reduce((prev, next) => {
      if (next.id !== widgetId) {
        return next.bottom > prev ? next.bottom : prev;
      }
      return prev;
    }, widgetBottomRow);
  }

  if (!canDropTargetExtend) {
    return Math.ceil(Math.max(minBottomRow, defaultRows));
  }

  const extraRowToAdd =
    extraRows !== undefined && !Number.isNaN(extraRows)
      ? extraRows
      : GridDefaults.CANVAS_EXTENSION_OFFSET;

  return Math.ceil(Math.max(minBottomRow + extraRowToAdd, defaultRows));
};

export const paddingToGU = (padding?: Dimension<"px">) => {
  return Dimension.toGridUnit(
    padding ?? Dimension.gridUnit(0),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw().value;
};
