import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { type IconWidgetProps } from "./IconWidget";

const config: PropertyPaneCategoryConfig<IconWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "icon",
      label: "Icon",
      helpText: "Select an icon",
      controlType: "ICON_SELECTOR",
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: true,
      canExpandEditor: true,
      customJSControl: "CODE_EDITOR_ICON_SELECTOR",
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      propertyName: "color",
      label: "Color",
      helpText: "Changes the color of the icon",
      controlType: "COLOR_PICKER",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      themeValue: "colors.neutral700",
    },
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({
      widthSupportsFitContent: true,
      heightSupportsFitContent: true,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onClick",
      "Triggers an action when a user clicks the image",
    ),
  ]),
];

export default config;
