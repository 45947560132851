import { RouteDef } from "@superblocksteam/shared";
import { Result } from "utils/result";
import {
  buildURL,
  NavigateToRouteOpts,
  RouteToUrlError,
  validateAndBuildRouteInputs,
} from "./shared";

// E.g: localhost:3000/books/5
export function buildLocalUrlFromRoute(
  route: RouteDef,
  navigateToRoute: NavigateToRouteOpts,
): Result<URL, RouteToUrlError> {
  const result = validateAndBuildRouteInputs(route, navigateToRoute);
  if (!result.ok) {
    return result;
  }

  const url = buildURL({
    targetPath: result.value.path,
    params: result.value.queryParams,
  });

  return {
    ok: true,
    value: url,
  };
}
