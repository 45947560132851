import * as ScrollArea from "@radix-ui/react-scroll-area";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Layers } from "legacy/constants/Layers";
import { useStickySectionOnParentScroll } from "legacy/hooks/useStickySectionOnParentScroll";

const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
  height: 100%;
  overflow: hidden;

  --z-index: ${Layers.scrollbars};
  --scrollbar-size: 10px;
  --mauve-10: #c1c1c1;
  --black-a6: rgba(0, 0, 0, 0.05);
  --black-a7: rgba(0, 0, 0, 0.1);

  .ScrollAreaViewport {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    & > div:only-child {
      // there is a div inside ScrollAreaViewport that has width 100% and display: table
      // make it height 100%, so in slideout a section with fill viewport will actually fill the rest of screen height
      height: 100%;
    }
  }

  .ScrollAreaScrollbar {
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 2px;
    background: var(--black-a6);
    transition: background 160ms ease-out;
    z-index: var(--z-index);

    &[data-orientation="vertical"] {
    }
    &[data-orientation="horizontal"] {
    }
  }
  .ScrollAreaCorner {
    background: var(--black-a7);
    z-index: var(--z-index);
  }
  .ScrollAreaScrollbar:hover,
  .ScrollAreaCorner:hover {
    background: var(--black-a7);
  }

  .ScrollAreaScrollbar[data-orientation="vertical"] {
    width: var(--scrollbar-size);
    padding-right: 3px;
  }

  .ScrollAreaScrollbar[data-orientation="horizontal"] {
    flex-direction: column;
    padding-bottom: 5px;
    height: calc(var(--scrollbar-size) + 2px);
  }

  .ScrollAreaThumb {
    flex: 1;
    background: var(--mauve-10);
    border-radius: var(--scrollbar-size);
    position: relative;
    z-index: var(--z-index);
  }
  /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
  .ScrollAreaThumb::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 20px;
    min-height: 20px;
  }
`;
export const ScrollContainer = (props: {
  scroll: boolean;
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  role?: string;
  innerStyle?: React.CSSProperties;
  scrollAreaId?: string;
}) => {
  useStickySectionOnParentScroll(props.scrollAreaId);

  if (!props.scroll) {
    return <>{props.children}</>;
  }
  return (
    <ScrollAreaRoot
      style={props.style}
      className={props.className}
      role={props.role}
    >
      <ScrollArea.Viewport
        className="ScrollAreaViewport"
        style={props.innerStyle}
        id={props.scrollAreaId}
        data-superblocks={props.scrollAreaId}
      >
        {props.children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="vertical"
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="horizontal"
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner className="ScrollAreaCorner" />
    </ScrollAreaRoot>
  );
};
