import { RegisteredComponents } from "@superblocksteam/shared";
import { WidgetTypesArr } from "legacy/constants/WidgetConstants";
import { type CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { getParentWidgets } from "legacy/utils/WidgetPropsUtils";
import { customComponentType } from "legacy/widgets/CustomComponentTypeUtil";
import WidgetFactory from "legacy/widgets/Factory";

/**
 * Returns a restricted list of widget types that are allowed under the ancestry of the given widgetId.
 * This currently only relevant due to grid widgets.
 *
 * @param params.widgetId - The id of the widget to check the ancestry for.
 * @param params.registeredComponents - For custom components
 * @param params.canvasWidgets - Map containing all widgets
 * @returns {string[]} - The list of allowed child types.
 */
export const getAllowedChildTypesInWidgetAncestry = (params: {
  widgetId: string;
  registeredComponents?: RegisteredComponents;
  canvasWidgets: CanvasWidgetsReduxState;
}) => {
  const { registeredComponents, canvasWidgets, widgetId } = params;

  let allowedChildTypes = [
    ...WidgetTypesArr,
    ...Object.values(registeredComponents ?? {}).map((component) =>
      customComponentType(component.id),
    ),
  ];

  const parents = getParentWidgets(widgetId, canvasWidgets);
  parents.forEach((widget) => {
    const parentChild = WidgetFactory.getWidgetParentChildSetup()[widget.type];
    if (parentChild?.allowedChildTypes) {
      allowedChildTypes = allowedChildTypes.filter((type) =>
        parentChild.allowedChildTypes.includes(type),
      );
    }
  });

  return allowedChildTypes;
};
