import { Dimension, ThemeMode } from "@superblocksteam/shared";
import React from "react";
import styled from "styled-components";
import spacingSVG from "assets/images/canvas/canvasSpacing.svg";
import spacingDarkSVG from "assets/images/canvas/canvasSpacingDark.svg";
import {
  CanvasAlignment,
  CanvasDistribution,
  CanvasLayout,
} from "legacy/constants/WidgetConstants";
import { getSpacingIsFocused } from "legacy/selectors/propertyPaneSelectors";
import {
  getFocusedWidget,
  getIsWidgetSelected,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";
import type { AppState } from "store/types";

const SpacingOverlayDiv = styled.div`
  pointer-events: none;
  box-sizing: border-box;
  background-repeat: repeat;
  transition: opacity 0.1s;

  &[data-dark-theme="true"] {
    background: url(${spacingDarkSVG});
  }
  &[data-dark-theme="false"] {
    background: url(${spacingSVG});
  }
`;

type SpacingOverlayItemProps = {
  layout: CanvasLayout;
  spacing?: Dimension<"px">;
  parentId?: string;
  alignment?: CanvasAlignment;
  distribution?: CanvasDistribution;
  isLeadingOrTrailingSpacer?: boolean;
  parentHasFillParentChild: boolean;
};

const INNER_SPACER_ALIGNMENTS = [
  CanvasAlignment.SPACE_AROUND,
  CanvasAlignment.SPACE_BETWEEN,
];
const INNER_SPACE_DISTRIBUTIONS = [
  CanvasDistribution.SPACE_AROUND,
  CanvasDistribution.SPACE_BETWEEN,
];

export const SpacingOverlayItem: React.FC<SpacingOverlayItemProps> = ({
  layout,
  spacing,
  parentId,
  alignment,
  distribution,
  isLeadingOrTrailingSpacer,
  parentHasFillParentChild,
}) => {
  const theme = useAppSelector(selectGeneratedTheme);
  const widgetSelected = useAppSelector((state: AppState) =>
    getIsWidgetSelected(state, parentId || ""),
  );
  const propertyIsHovered = useAppSelector(getSpacingIsFocused);
  const focusedWidget = useAppSelector(getFocusedWidget);
  const isFocused = focusedWidget?.widgetId === parentId;

  const isDropTarget = useAppSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.currentDropTarget === parentId,
  );

  const show = widgetSelected || isDropTarget;

  const isVStack = layout === CanvasLayout.VSTACK;
  const spacingPx = spacing?.value || 0;
  const themeMode = theme.mode;
  const showInnerSpacer = isVStack
    ? INNER_SPACE_DISTRIBUTIONS.includes(
        distribution ?? CanvasDistribution.STRETCH,
      )
    : INNER_SPACER_ALIGNMENTS.includes(alignment ?? CanvasAlignment.STRETCH);

  if (isLeadingOrTrailingSpacer) {
    // only render when using space-around
    if (
      (isVStack && distribution === CanvasDistribution.SPACE_AROUND) ||
      (!isVStack && alignment === CanvasAlignment.SPACE_AROUND)
    ) {
      return (
        <SpacingOverlayDiv
          style={{
            visibility: show ? "visible" : "hidden",
            alignSelf: !isVStack ? "stretch" : undefined,
            flexGrow: parentHasFillParentChild ? 0 : 1,

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          data-dark-theme={themeMode === ThemeMode.DARK ? "true" : "false"}
        />
      );
    } else {
      return null;
    }
  }

  if (showInnerSpacer) {
    return (
      <SpacingOverlayDiv
        style={{
          visibility: show ? "visible" : "hidden",
          alignSelf: !isVStack ? "stretch" : undefined,
          flexGrow: parentHasFillParentChild ? 0 : 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: propertyIsHovered ? 1 : 0.4,
        }}
        data-dark-theme={themeMode === ThemeMode.DARK ? "true" : "false"}
      >
        <div
          style={{
            height: isVStack ? `${spacingPx}px` : "100%",
            width: isVStack ? "100%" : `${spacingPx}px`,
            background:
              isFocused || isDropTarget
                ? "rgba(255, 72, 72, 0.3)"
                : "transparent",
          }}
        />
      </SpacingOverlayDiv>
    );
  }
  return (
    <SpacingOverlayDiv
      style={{
        visibility: show ? "visible" : "hidden",
        height: isVStack ? `${spacingPx}px` : undefined,
        width: isVStack ? "100%" : `${spacingPx}px`,
        alignSelf: !isVStack ? "stretch" : undefined,
        opacity: propertyIsHovered ? 1 : 0.4,
      }}
      data-dark-theme={themeMode === ThemeMode.DARK ? "true" : "false"}
    />
  );
};
