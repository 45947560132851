import { useCallback } from "react";
import { useStore } from "react-redux";
import { runAfterWidgetOperationsAreDone } from "legacy/hooks/dragResizeHooks";
import { useSectionSizingContextSelector } from "legacy/widgets/SectionWidget/SectionSizingContextSelectors";
import { AppState } from "store/types";

// Clear the column canvas extension rows
// We want to wait for the operation to complete before clearing the extension rows
// to avoid flickering
export const useClearSectionSizingContext = () => {
  const store = useStore<AppState>();
  const clearColumnCanvasExtensionRows = useSectionSizingContextSelector(
    (context) => context.setColumnCanvasExtensionRows,
  );
  const clearSectionSizingContext = useCallback(
    () =>
      runAfterWidgetOperationsAreDone(store, () => {
        clearColumnCanvasExtensionRows(null);
      }),
    [store, clearColumnCanvasExtensionRows],
  );

  return clearSectionSizingContext;
};
