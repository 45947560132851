import {
  getDynamicBindings,
  combineDynamicBindings,
} from "@superblocksteam/shared";
import { get, isString, set } from "lodash";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { WidgetAddChild, WidgetDelete } from "legacy/actions/pageActions";
import { deleteWidgets } from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { isPathADynamicTrigger } from "legacy/utils/DynamicBindingUtils";
import { getWidgetChildren } from "legacy/utils/WidgetPropsUtils";
import { fastClone } from "utils/clone";
import type {
  WidgetActionHook,
  CanvasWidgetsReduxState,
  WidgetActionResponse,
} from "../Factory";

export const actionHooks: WidgetActionHook = function (params: {
  widgetId: string;
  widgets: Readonly<CanvasWidgetsReduxState>;
  action: ReduxAction<
    WidgetAddChild | WidgetDelete | UpdateWidgetPropertiesPayload
  >;
}) {
  // Check for the actions we are interested in
  const { widgetId, widgets, action } = params;
  if (
    action.type !== ReduxActionTypes.WIDGET_CREATE &&
    action.type !== deleteWidgets.type &&
    action.type !== updateWidgetProperties.type
  ) {
    return;
  }
  if (widgets[widgetId].type !== WidgetTypes.GRID_WIDGET) {
    return;
  }
  const draft = fastClone(widgets) as CanvasWidgetsReduxState;
  const updateList: WidgetActionResponse = [];
  const widget = draft[widgetId] as FlattenedWidgetProps & Record<string, any>; //GridWidgetProps;
  const children = getWidgetChildren(widget.widgetId, widgets);
  switch (action.type) {
    case ReduxActionTypes.WIDGET_CREATE: {
      const payload = action.payload as WidgetAddChild;
      if (payload.type !== WidgetTypes.GRID_WIDGET) {
        break;
      }
      const gridBindings = { ...get(widget, "gridBindings", {}) };
      const dynamicBindingPathList = widget.dynamicBindingPathList ?? [];
      for (const child of children) {
        const copy = fastClone(child);
        const keys = Object.keys(copy);

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i] as keyof typeof copy;
          const value = copy[key];

          // Rewrite the copy attached to gridBindings, not the original
          if (isString(value) && value.indexOf("currentCell") > -1) {
            const { jsSnippets, stringSegments } = getDynamicBindings(value);

            const js = combineDynamicBindings(jsSnippets, stringSegments);

            (copy as Record<keyof typeof copy, unknown>)[key] =
              `{{${widget.widgetName}.gridData.map((currentCell) => ${js})}}`;

            dynamicBindingPathList.push({
              key: `gridBindings.${copy.widgetName}.${key}`,
            });
          }
        }
        gridBindings[child.widgetName] = copy;
      }
      widget.gridBindings = gridBindings;
      widget.dynamicBindingPathList = dynamicBindingPathList;
      updateList.push({ widgetId, widget });

      break;
    }
    case deleteWidgets.type: {
      if (deleteWidgets.match(action)) {
        const gridBindings = { ...get(widget, "gridBindings", {}) };
        let dynamicBindingPathList = widget.dynamicBindingPathList ?? [];
        let isDeletingChild = false;
        for (const child of children) {
          if (
            action.payload?.widgetIds &&
            (action.payload?.widgetIds as string[])?.length > 0 &&
            child.widgetId === action.payload?.widgetIds?.[0]
          ) {
            isDeletingChild = true;
            delete gridBindings[child.widgetName];
            dynamicBindingPathList = dynamicBindingPathList.filter(
              (path) => !path.key.includes(child.widgetName),
            );
          }
        }
        if (!isDeletingChild) {
          // This can happen if there are multiple grids, we should only update the parent grid
          break;
        }
        widget.gridBindings = gridBindings;
        widget.dynamicBindingPathList = dynamicBindingPathList;
        updateList.push({ widgetId, widget });
      }
      break;
    }
    case updateWidgetProperties.type: {
      if (!updateWidgetProperties.match(action)) break;
      const { updates } = action.payload;
      for (const child of children) {
        if (
          child.widgetId ===
          (action.payload as UpdateWidgetPropertiesPayload).widgetId
        ) {
          let dynamicBindingPathList = [
            ...(widget.dynamicBindingPathList ?? []),
          ];
          Object.entries(updates).forEach(([propertyPath, propertyValue]) => {
            if (isPathADynamicTrigger(child, propertyPath)) {
              // Do not evaluate dynamic triggers
              return;
            }

            const { jsSnippets, stringSegments } = getDynamicBindings(
              propertyValue as string,
            );

            const js = combineDynamicBindings(jsSnippets, stringSegments);

            let value = `{{${widget.widgetName}.gridData.map((currentCell, currentIndex) => {
              return (function(){
                try {
                  return  ${js};
                } catch (err) {
                  console.error("Error executing currentCell js in Grid component ${widget.widgetName}", err);
                  return "";
                }
              })();
            })}}`;

            const path = `gridBindings.${child.widgetName}.${propertyPath}`;

            dynamicBindingPathList = dynamicBindingPathList.filter(
              ({ key }) => key !== path,
            );
            if (js) {
              dynamicBindingPathList.push({ key: path });
            } else {
              value = propertyValue as string;
            }

            set(widget, path, value);
          });
          widget.dynamicBindingPathList = dynamicBindingPathList;
          updateList.push({ widgetId, widget });
        }
      }

      break;
    }
    default:
      break;
  }
  return updateList;
};
