import { Dimension } from "@superblocksteam/shared";
import React, { useState, useEffect, useCallback } from "react";
import { createContext } from "use-context-selector";
import { resizeSectionWidgetColumns } from "legacy/actions/widgetActions";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { getSectionColsForParentType } from "legacy/utils/WidgetPropsUtils";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import { useDeferredDispatch } from "./hooks/useDeferredDispatch";
import type { WidgetLayoutProps } from "../shared";
import type { SectionComponentProps } from "./SectionComponent";

export type ExtensionInfo = {
  rows: number;
  dropTargetId: string;
};

export type SectionSizingContextProps = {
  isColumnResizing?: boolean;
  columnWidthsPx: number[];
  onDrag: (index: number, deltaX: number) => void;
  onDragEnd: () => void;
  onDragStart: () => void;
  columnCanvasExtensionRowsInfo: ExtensionInfo | null;
  setColumnCanvasExtensionRows: (info: ExtensionInfo | null) => void;
};

export const SectionSizingContext = createContext<SectionSizingContextProps>(
  new Proxy<SectionSizingContextProps>({} as any, {
    get() {
      throw new Error(
        "Cannot use SectionSizingContext outside of a provider, which is in SectionComponent",
      );
    },
  }),
);

export const SectionSizingProvider = (
  sectionProps: Omit<SectionComponentProps, "children"> & {
    children: React.ReactNode;
    columns: WidgetLayoutProps[];
  },
) => {
  const debouncedDispatch = useDeferredDispatch({ deep: true });

  const parentWidget = useAppSelector((state: AppState) => {
    return getFlattenedCanvasWidget(state, sectionProps.parentId || "");
  });
  const sectionColumns = getSectionColsForParentType(parentWidget.type);
  const gridColumnsPerSectionColumn =
    (sectionProps.gridColumns || 0) / sectionColumns;

  const [isDragging, setIsDragging] = useState(false);
  const [visualWidths, setVisualWidths] = useState<number[]>([]);

  // this is the active extension of a column canvas's rows during a drag or resize of a component
  // inside that column.
  // The value is not persisted to the DSL until the drag interaction is complete, but we need
  // the value to extend the section height during the interaction
  // a null value signifies that there is no extension currently needed
  const [columnCanvasExtensionRows, setColumnCanvasExtensionRows] =
    useState<ExtensionInfo | null>(null);

  // Update the visual widths when the columns change in redux
  // this is blocked by isDragging so that we don't update the visual widths while dragging
  useEffect(() => {
    if (!isDragging) {
      const widths = (sectionProps?.columns ?? []).map((column) => {
        return Dimension.toPx(column.width, column.parentColumnSpace).value;
      });
      setVisualWidths(widths);
    }
  }, [
    gridColumnsPerSectionColumn,
    isDragging,
    sectionProps?.columns,
    sectionProps.gridColumns,
    sectionProps.parentColumnSpace,
    sectionColumns,
  ]);

  const setColumnWidths = useCallback(
    (columnWidths: number[]) => {
      // This is the width of each column in section columns out of sectionColumns which is usually 12
      const columnRatios = columnWidths.map((width) => {
        const ratio = Math.round(
          width / sectionProps.parentColumnSpace / gridColumnsPerSectionColumn,
        );
        return ratio;
      });

      // If any of the columns are less than or equal to 0, we reject the resize
      if (columnRatios.some((ratio) => ratio <= 0)) {
        return;
      }
      // If the sum of the columns is not equal to the number of section columns, we reject the resize
      const totalSize = columnRatios.reduce((sum, ratio) => sum + ratio, 0);
      if (totalSize !== sectionColumns) {
        return;
      }

      debouncedDispatch?.(
        resizeSectionWidgetColumns(sectionProps.widgetId, columnRatios),
      );

      const pixelWidths = columnRatios.map((width) => {
        return (
          width * sectionProps.parentColumnSpace * gridColumnsPerSectionColumn
        );
      });
      setVisualWidths(pixelWidths);
    },
    [
      debouncedDispatch,
      gridColumnsPerSectionColumn,
      sectionProps.parentColumnSpace,
      sectionProps.widgetId,
      sectionColumns,
    ],
  );

  const onDrag = useCallback(
    (index: number, deltaX: number) => {
      setIsDragging(true);
      const newWidths = [...visualWidths];
      newWidths[index] -= deltaX;
      newWidths[index + 1] += deltaX;
      setColumnWidths(newWidths);
    },
    [setColumnWidths, visualWidths],
  );

  const onDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  return (
    <SectionSizingContext.Provider
      value={{
        columnWidthsPx: visualWidths,
        onDrag,
        onDragEnd,
        onDragStart,
        isColumnResizing: isDragging,
        columnCanvasExtensionRowsInfo: columnCanvasExtensionRows,
        setColumnCanvasExtensionRows,
      }}
    >
      {sectionProps.children}
    </SectionSizingContext.Provider>
  );
};
