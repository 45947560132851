import { Dimension } from "@superblocksteam/shared";
import { get } from "lodash";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { WidgetProps } from "./BaseWidget";

export const updateHeightOnAddOrRemove = ({
  props,
  propertyPath,
  propertyValue,
}: {
  props: WidgetProps & { isVertical?: boolean };
  propertyPath: string;
  propertyValue: string | undefined;
}) => {
  const height = props.height;
  if (height.mode !== "px" && height.mode !== "gridUnit") return;
  if (props.isVertical === false) return;

  const oldLabel = get(props, propertyPath) as string | undefined;
  const newLabel = propertyValue as string | undefined;

  const wasDeleted = !!oldLabel && !newLabel;
  const wasAdded = !oldLabel && !!newLabel;

  const pxHeight = Dimension.toPx(height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT);
  if (wasDeleted && pxHeight.value > GridDefaults.DEFAULT_GRID_ROW_HEIGHT * 4) {
    return [
      {
        propertyPath: "height",
        propertyValue: Dimension.minus(
          pxHeight,
          Dimension.px(GridDefaults.DEFAULT_GRID_ROW_HEIGHT * 2),
        ).asFirst(),
      },
    ];
  }

  if (wasAdded) {
    return [
      {
        propertyPath: "height",
        propertyValue: Dimension.add(
          pxHeight,
          Dimension.px(GridDefaults.DEFAULT_GRID_ROW_HEIGHT * 2),
        ).asFirst(),
      },
    ];
  }

  return [];
};
