import { Dimension, Padding } from "@superblocksteam/shared";
import React, { ReactNode, CSSProperties, useMemo, useContext } from "react";
import styled from "styled-components";
import { Layers } from "legacy/constants/Layers";
import { CanvasLayout, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import MarginOverlay from "legacy/pages/Editor/CanvasArenas/MarginOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { selectIsResizing } from "legacy/selectors/dndSelectors";
import {
  getIsWidgetFocused,
  getIsWidgetSelected,
} from "legacy/selectors/sagaSelectors";
import { generateClassName } from "legacy/utils/generators";
import { type WidgetPropsRuntime } from "legacy/widgets/BaseWidget";
import { isStackLayout } from "legacy/widgets/StackLayout/utils";
import { DropTargetContext } from "legacy/widgets/base/DropTargetUtils";
import { getFlexStyles } from "legacy/widgets/base/sizing";
import {
  getApplicableMaxWidth,
  getApplicableMinWidth,
} from "legacy/widgets/base/sizing/canvasWidthUtil";
import {
  useClearFillParent,
  useUpdateFillParentHeight,
  useUpdateFillParentWidth,
} from "legacy/widgets/base/sizing/dynamicLayoutHooks";
import { useAppSelector } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import { mergeRefs } from "../../../../utils/react/mergeRefs";

const PositionedWidget = styled.div`
  &:hover {
    z-index: 1;
  }
`;

const PositionedWidgetInternal = styled.div`
  position: absolute;
  inset: ${WIDGET_PADDING}px;
`;

const FillParentInternal = ({
  children,
  widgetId,
  height,
  width,
  paddingY,
}: {
  children: ReactNode;
  widgetId: string;
  height: boolean;
  width: boolean;
  paddingY?: number;
}) => {
  const ref = useUpdateFillParentHeight(widgetId, paddingY ?? 0, height);
  const ref2 = useUpdateFillParentWidth(widgetId, width);

  return (
    <PositionedWidgetInternal ref={mergeRefs(ref, ref2)}>
      {children}
    </PositionedWidgetInternal>
  );
};

const PositionedContainer = (props: {
  widgetProps: WidgetPropsRuntime;
  children: React.ReactNode;
  appMode: APP_MODE;
}) => {
  const { widgetProps } = props;
  const { widgetId, type: widgetType } = widgetProps;

  const isSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, widgetId),
  );
  const isFocused = useAppSelector((state) =>
    getIsWidgetFocused(state, widgetId),
  );

  const { temporaryResizeValue } = useContext(DropTargetContext);
  const isResizing = useAppSelector(selectIsResizing);
  const overrideSizing =
    isResizing &&
    temporaryResizeValue &&
    temporaryResizeValue?.widgetId === widgetProps.widgetId;

  // Important: This is used by property pane to reference the element
  // be careful changing or removing this
  const containerClassName = useMemo(() => {
    return (
      generateClassName(widgetId) +
      " positioned-widget " +
      `t--widget-${widgetType.split("_").join("").toLowerCase()}`
    );
  }, [widgetType, widgetId]);

  const isFillParentHeight = widgetProps.height.mode === "fillParent";
  const isFillParentWidth = widgetProps.width.mode === "fillParent";
  const isInStackLayout = isStackLayout(widgetProps.parentLayout);

  const containerStyle: CSSProperties = useMemo(() => {
    const { componentHeight, componentWidth } =
      getComponentDimensions(widgetProps);

    let baseStyles: CSSProperties = {
      height: `${componentHeight}px`,
      width: `${componentWidth}px`,
      padding: WIDGET_PADDING + "px",
      zIndex: isFocused
        ? Layers.focusedWidget
        : isSelected
          ? Layers.selectedWidget
          : Layers.positionedWidget,
    };

    const minWidth = getApplicableMinWidth(widgetProps);
    const maxWidth = getApplicableMaxWidth(widgetProps);

    if (isInStackLayout) {
      baseStyles = {
        ...baseStyles,
        position: "relative",
        ...getFlexStyles({
          height: widgetProps.height,
          heightPx: componentHeight,
          minHeight: widgetProps.minHeight,
          maxHeight: widgetProps.maxHeight,
          minWidth,
          maxWidth,
          width: widgetProps.width,
          widthPx: componentWidth,
          parentColumnSpace: widgetProps.parentColumnSpace,
          parentDirection:
            widgetProps.parentLayout === CanvasLayout.VSTACK ? "column" : "row",
          margin: !overrideSizing ? widgetProps.margin : undefined,
        }),
      };
      if (widgetProps.parentLayout === CanvasLayout.VSTACK) {
        baseStyles.height = undefined;
      } else {
        baseStyles.width = undefined;
      }
    } else {
      const { parentRowSpace, parentColumnSpace, padding, top, left } =
        widgetProps;
      const yPositionPx =
        top.value * parentRowSpace +
        Dimension.toPx(padding?.top ?? Dimension.px(0), parentRowSpace).value;
      const xPositionPx =
        left.value * parentColumnSpace +
        Dimension.toPx(padding?.left ?? Dimension.px(0), parentColumnSpace)
          .value;

      const paddingLeft = Dimension.toPx(
        padding?.left ?? Dimension.px(0),
        parentColumnSpace,
      ).value;
      const paddingTop = Dimension.toPx(
        padding?.top ?? Dimension.px(0),
        parentRowSpace,
      ).value;

      baseStyles = {
        ...baseStyles,
        position: "absolute",
        left: `${xPositionPx - paddingLeft}px`,
        top: `${yPositionPx - paddingTop}px`,
      };
    }

    return baseStyles;
  }, [isFocused, isInStackLayout, isSelected, overrideSizing, widgetProps]);

  useClearFillParent(widgetId, isFillParentHeight, "height");
  useClearFillParent(widgetId, isFillParentWidth, "width");

  return (
    <PositionedWidget
      className={containerClassName}
      style={containerStyle}
      id={`widget-${widgetId}`}
    >
      {props.appMode === APP_MODE.EDIT &&
        isInStackLayout &&
        !overrideSizing && (
          <MarginOverlay
            widgetId={widgetId}
            parentId={widgetProps.parentId}
            margin={props.widgetProps.margin}
          />
        )}
      {(isFillParentHeight || isFillParentWidth) && (
        <FillParentInternal
          widgetId={widgetId}
          width={isFillParentWidth}
          height={isFillParentHeight}
          paddingY={
            widgetProps.padding ? Padding.y(widgetProps.padding).value : 0
          }
        >
          {props.children}
        </FillParentInternal>
      )}
      {!isFillParentHeight && !isFillParentWidth && (
        <PositionedWidgetInternal>{props.children}</PositionedWidgetInternal>
      )}
    </PositionedWidget>
  );
};

PositionedContainer.padding = WIDGET_PADDING;

export default PositionedContainer;
