import { useMemo } from "react";
import { useStore } from "react-redux";
import { Store } from "redux";
import { CanvasLayout } from "legacy/constants/WidgetConstants";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import { computeAvailableSpace } from "legacy/widgets/StackLayout/stackScrollAvoidance";
import { AppState } from "store/types";
import { DragTransformer } from "../Resizable";
import { getWidgetMinWidth } from "../sizing/canvasWidthUtil";

class StickyDragTransformer implements DragTransformer {
  static BREAKTHROUGH_THRESHOLD_PX = 80;
  static EPSILON = 0.1;
  static MARGIN_ADJUSTMENT = 2; // I'm not actually sure where in the code this offset corresponds to

  initialSize?: number;
  hasBrokenThrough = false;
  store: Store;

  widgetId: string;
  parentId: string;

  constructor(store: Store, widgetId: string, parentId: string) {
    this.store = store;
    this.widgetId = widgetId;
    this.parentId = parentId;
  }

  private getInitialWidgetSize(state: any): number {
    if (this.initialSize === undefined) {
      const widget = getFlattenedCanvasWidget(state, this.widgetId);
      if (widget) {
        this.initialSize = getWidgetMinWidth(widget);
      }
    }
    return this.initialSize ?? 0;
  }

  transform(size: { width?: number; height?: number; x?: number; y?: number }) {
    const state = this.store.getState();
    const parentWidget = getFlattenedCanvasWidget(state, this.parentId);
    if (
      !size.width ||
      parentWidget?.layout !== CanvasLayout.HSTACK ||
      this.hasBrokenThrough
    ) {
      return size;
    }

    const initialWidgetSize = this.getInitialWidgetSize(state);
    if (!initialWidgetSize) {
      return size;
    }

    const widthChange = size.width - initialWidgetSize;

    if (widthChange < 0) {
      // we don't care if it shrinks down
      return size;
    }

    // availableSpace.px is the amount that we can grow the widget by before an overflow occurs.
    const availableSpace = computeAvailableSpace({
      parentWidget,
      isCreate: false,
      selectedWidgetIds: [],
      state,
    });

    if (availableSpace.px === 0) {
      // then we're already scrolling
      return size;
    }
    const resultingAvailableSpacePx = availableSpace.px - widthChange;
    if (resultingAvailableSpacePx > StickyDragTransformer.EPSILON) {
      return size;
    } else if (
      -1 * resultingAvailableSpacePx >
      StickyDragTransformer.BREAKTHROUGH_THRESHOLD_PX
    ) {
      // user has indicated they want overflow
      this.hasBrokenThrough = true;
      return size;
    } else {
      // want to just use width directly if there are small imprecisions
      const newWidth =
        initialWidgetSize +
        availableSpace.px -
        StickyDragTransformer.MARGIN_ADJUSTMENT;

      // we want to check if rounding the resulting width will bump up the size and therefore introduce overflow
      const asRounded =
        Math.round(newWidth / availableSpace.prospectiveColumnSpace) *
        availableSpace.prospectiveColumnSpace;
      const wouldCauseOverflow =
        asRounded - initialWidgetSize > availableSpace.px;
      const adjustment = wouldCauseOverflow
        ? availableSpace.prospectiveColumnSpace / 2
        : 0;
      const width = newWidth - adjustment;

      const x =
        size.x === 0 // x = 0 when dragging right handle
          ? 0
          : 0 - availableSpace.px + adjustment;

      return {
        ...size,
        width,
        x,
      };
    }
  }

  reset() {
    this.initialSize = undefined;
    this.hasBrokenThrough = false;
  }
}

export const useStickyDrag = (params: {
  widgetId: string;
  parentId: string;
}) => {
  const { widgetId, parentId } = params;
  const store = useStore<AppState>(); // we don't want to update the callback ref every time the flattened layout changes

  // in practice this should not recompute
  return useMemo(() => {
    return new StickyDragTransformer(store, widgetId, parentId);
  }, [store, widgetId, parentId]);
};
