window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb: IdleRequestCallback, _options?: IdleRequestOptions) {
    const start = Date.now();
    return setTimeout(() => {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 0);
  };

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id: number) {
    clearTimeout(id);
  };

export {};
