import { Classes, Menu as BlueprintMenu } from "@blueprintjs/core";
import { Dimension } from "@superblocksteam/shared/src/types";
import React, { useCallback } from "react";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { createErrorUINotification } from "store/slices/notifications/actions";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { TypographyProps } from "../typographyHooks";
import { MenuItem } from "./MenuItem";
import { ComponentMenuItem } from "./types";
import type { RunWidgetEventHandlers } from "../BaseWidget";

const MenuStyles = styleAsClass`
    && .${Classes.MENU}, &&.${Classes.MENU} { // Both itself and descendants 
      padding: 8px;
      min-width: 40px;
    }
    && .${Classes.MENU_ITEM} {
      border-radius: 4px;
      font-size: 12px;
      padding: 8px 10px;
    }

    && .menu-item-text {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &&&[data-theme-mode="LIGHT"] .${Classes.MENU_ITEM} {
      color: ${colors.GREY_700};
    }
    &&&[data-theme-mode="DARK"] .${Classes.MENU_ITEM} {
      color: ${colors.WHITE};
    }

    &&&&&[data-theme-mode="LIGHT"] .${Classes.MENU_ITEM}:hover {
      background-color: #F3F5F7;
      color: ${colors.GREY_700};
    }

    &&&&&[data-theme-mode="DARK"] .${Classes.MENU_ITEM}:hover {
      background-color: #31384A;
      color: ${colors.WHITE};
    }

    && .${Classes.MENU_SUBMENU} .${Classes.POPOVER} {
      padding: 0;
      margin: 20px; // space between menu and submenus. Blueprint handles the automatic flipping according to placement
    }

    && .${Classes.MENU_SUBMENU_ICON} {
      // Hack to customize the submenu's caret icon.
      // See how we use labelElement on each MenuItem
      display: none;
    }

    && .${Classes.MENU_ITEM} > * {
      // Hack to customize the submenu's caret icon.
      // See how we use labelElement on each MenuItem
      margin-right: 0;
    }

    && .${Classes.MENU_ITEM_LABEL} svg path {
      stroke: ${colors.GREY_700};
    }

    &&&&&[data-menu-width-mode="fillParent"] {
      // Make sure ellipsis work when width matches button parent (mode = fillParent)
      width: 0;
      min-width: 100%;
    }
  }
`;

export interface MenuListProps {
  items: ComponentMenuItem[];
  textProps?: TypographyProps;
  runEventHandlers: (payload: RunWidgetEventHandlers) => void;
  width: Dimension;
  minWidth: number | undefined;
}

export function MenuList(props: MenuListProps) {
  const theme = useAppSelector(selectGeneratedTheme);
  const appMode = useAppSelector(getAppMode);
  const dispatch = useAppDispatch();
  const handleMissingRouteParams = useCallback(() => {
    dispatch(
      createErrorUINotification({
        message:
          "Missing route parameters detected. Please pass values for all route parameters for this menu item",
        isUISystemInitiated: true,
      }),
    );
  }, [dispatch]);

  const children = props.items.map((item, i) => (
    <MenuItem
      item={item}
      key={i}
      index={i}
      appMode={appMode}
      textProps={props.textProps}
      handleMissingRouteParamsOnEditMode={handleMissingRouteParams}
      runEventHandlers={props.runEventHandlers}
    />
  ));

  const style: Record<string, unknown> = {};
  if (props.width.mode === "px") {
    style.width = `${props.width.value}px`;
  }
  if (props.minWidth) {
    style.minWidth = `${props.minWidth}px`;
  }

  return (
    <BlueprintMenu
      className={MenuStyles}
      data-theme-mode={theme.mode}
      data-menu-width-mode={props.width.mode}
      style={style}
    >
      {children}
    </BlueprintMenu>
  );
}
