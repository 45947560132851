import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  CircleF,
  InfoWindowF,
  MarkerClustererF,
  StandaloneSearchBox,
  MarkerProps as GoogleMapMarkerProps,
} from "@react-google-maps/api";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styled from "styled-components";
import { AutolinkedText } from "components/ui/AutolinkedText";
import { CLASS_NAMES } from "legacy/themes/classnames";
import PickMyLocation from "./PickMyLocation";
import type {
  CircleProps,
  MarkerProps,
} from "legacy/widgets/MapWidget/MapWidget";

interface MapComponentProps {
  apiKey: string;
  widgetId: string;
  isDisabled?: boolean;
  isVisible?: boolean;
  enableSearch: boolean;
  zoomLevel: number;
  enablePickLocation: boolean;
  allowZoom: boolean;
  center: {
    lat: number;
    long: number;
  };
  markers?: Array<MarkerProps>;
  selectedMarker?: {
    lat: number;
    long: number;
    title?: string;
    description?: string;
  };
  enableCreateMarker: boolean;
  updateCenter: (lat: number, long: number) => void;
  updateMarker: (lat: number, long: number, index: number) => void;
  saveMarker?: (lat: number, long: number) => void;
  selectMarker: (
    lat: number,
    long: number,
    title: string,
    description: string,
  ) => void;
  enableDrag: (e: any) => void;
  unselectMarker: () => void;
}

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 240px;
  height: 32px;
  margin-top: 27px;
  outline: none;
  text-overflow: ellipses;
`;
type PickMyLocationProps = {
  allowZoom: boolean;
};

const PickMyLocationWrapper = styled.div<PickMyLocationProps>`
  position: absolute;
  bottom: ${(props) => (props.allowZoom ? 110 : 20)}px;
  right: -90px;
  width: 140px;
`;

const MyMapComponent = (props: MapComponentProps & { zoom: number }) => {
  const [mapCenter, setMapCenter] = React.useState<
    | {
        lat: number;
        lng: number;
        title?: string;
        description?: string;
      }
    | undefined
  >({
    ...props.center,
    lng: props.center.long,
  });
  const searchBox = useRef<google.maps.places.SearchBox>();
  const onPlacesChanged = () => {
    const node = searchBox.current;
    if (node) {
      const places = node.getPlaces();
      if (
        places &&
        places.length &&
        places[0].geometry &&
        places[0].geometry.location
      ) {
        const location = places[0].geometry.location;
        const lat = location.lat();
        const long = location.lng();
        setMapCenter({ lat, lng: long });
        props.updateCenter(lat, long);
        props.unselectMarker();
      }
    }
  };
  useEffect(() => {
    if (!props.selectedMarker) {
      setMapCenter({
        ...props.center,
        lng: props.center.long,
      });
    }
  }, [props.center, props.selectedMarker]);
  const [openInfoWindowMakerIndex, setOpenInfoWindowMarkerIndex] =
    useState<number>();
  const onInfoWindowClose = useCallback(
    () => setOpenInfoWindowMarkerIndex(undefined),
    [setOpenInfoWindowMarkerIndex],
  );

  const circles = useMemo(() => {
    const hasCircle = (marker: MarkerProps): marker is CircleProps =>
      !!marker.radius;
    return props.markers?.filter(hasCircle);
  }, [props.markers]);

  const clusteredMarkers = useMemo(() => {
    return props.markers?.filter((marker) => !!marker.cluster);
  }, [props.markers]);

  const nonClusterMarkers = useMemo(() => {
    return props.markers?.filter((marker) => !marker.cluster);
  }, [props.markers]);

  const renderMarker = useCallback(
    (
      marker: MarkerProps,
      index: number,
      clusterer?: GoogleMapMarkerProps["clusterer"],
    ) => {
      return (
        <MarkerF
          key={index}
          title={marker.title}
          position={{ lat: marker.lat, lng: marker.long }}
          clusterer={clusterer}
          icon={
            marker.icon && {
              url: marker.icon,
              scaledSize: new google.maps.Size(20, 20),
            }
          }
          clickable
          draggable={
            props.selectedMarker &&
            props.selectedMarker.lat === marker.lat &&
            props.selectedMarker.long === marker.long
          }
          onClick={() => {
            if (!isEmpty(marker.description)) {
              setOpenInfoWindowMarkerIndex(index);
            }
            props.selectMarker(
              marker.lat,
              marker.long,
              marker.title ?? "",
              marker.description ?? "",
            );
          }}
          onDragEnd={(de) => {
            if (de.latLng) {
              props.updateMarker(de.latLng.lat(), de.latLng.lng(), index);
            }
          }}
        >
          {openInfoWindowMakerIndex === index &&
            !isEmpty(marker.description) && (
              <InfoWindowF onCloseClick={onInfoWindowClose}>
                <AutolinkedText text={marker.description} />
              </InfoWindowF>
            )}
        </MarkerF>
      );
    },
    [onInfoWindowClose, openInfoWindowMakerIndex, props],
  );

  return (
    <GoogleMap
      options={{
        zoomControl: props.allowZoom,
        fullscreenControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        rotateControl: false,
        streetViewControl: false,
      }}
      zoom={props.zoom}
      center={mapCenter}
      onClick={(e) => {
        if (props.enableCreateMarker && e.latLng) {
          props.saveMarker?.(e.latLng?.lat(), e.latLng?.lng());
        }
      }}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
    >
      {
        /* Always off */ props.enableSearch && (
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={(ref) => {
              searchBox.current = ref;
            }}
          >
            <StyledInput
              className={CLASS_NAMES.INPUT}
              type="text"
              placeholder="Enter location to search"
            />
          </StandaloneSearchBox>
        )
      }
      {circles?.map((circle: CircleProps, index: number) => {
        return (
          <CircleF
            key={index}
            center={{ lat: circle.lat, lng: circle.long }}
            radius={circle.radius}
            options={{
              fillColor: circle.fillColor,
              strokeColor: circle.fillColor,
            }}
          />
        );
      })}
      {clusteredMarkers?.length ? (
        <MarkerClustererF averageCenter enableRetinaIcons gridSize={60}>
          {(clusterer) =>
            clusteredMarkers?.map((marker, i) =>
              renderMarker(marker, i, clusterer),
            ) as any
          }
        </MarkerClustererF>
      ) : null}
      {nonClusterMarkers?.map((marker, index) => renderMarker(marker, index))}
      {props.enablePickLocation && (
        <PickMyLocationWrapper
          title="Pick My Location"
          allowZoom={props.allowZoom}
        >
          <PickMyLocation updateCenter={props.updateCenter} />
        </PickMyLocationWrapper>
      )}
    </GoogleMap>
  );
};

const LIBRARIES = ["geometry" as const, "drawing" as const, "places" as const];

const MapComponent = (props: MapComponentProps) => {
  const zoom = Math.floor(props.zoomLevel / 5);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: props.apiKey,
    libraries: LIBRARIES,
  });

  return (
    <MapWrapper
      onMouseLeave={props.enableDrag}
      className={CLASS_NAMES.DEFAULT_CONTAINER}
    >
      {isLoaded && <MyMapComponent {...props} zoom={zoom} />}
    </MapWrapper>
  );
};

export default MapComponent;
