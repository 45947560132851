import { Tooltip } from "antd";
import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const CheckboxWrapper = styleAsClass`
  display: flex;
  gap: 8px;
  align-items: center;
  div:nth-child(2) {
    flex: 1;
    width: calc(100% - 24px);
  }
`;
const getCheckboxBackgroundImage = (
  color: string,
  isPartial: boolean = false,
) => {
  if (isPartial) {
    return `
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M5 8H11' stroke='%23${color.replace(
        "#",
        "",
      )}' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")
    `;
  }
  return `
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.8536 5.64645C12.0488 5.84171 12.0488 6.15829 11.8536 6.35355L7.35355 10.8536C7.15829 11.0488 6.84171 11.0488 6.64645 10.8536L4.14645 8.35355C3.95118 8.15829 3.95118 7.84171 4.14645 7.64645C4.34171 7.45118 4.65829 7.45118 4.85355 7.64645L7 9.79289L11.1464 5.64645C11.3417 5.45118 11.6583 5.45118 11.8536 5.64645Z' fill='%23${color.replace(
      "#",
      "",
    )}'/%3e%3c/svg%3e")
  `;
};

const centeredAbsoluteStyle = `
  display: block;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CheckboxCss = styleAsClass`
  display: block;
  position: relative;
  height: 14px;
  width: 14px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid ${colors.GREY_200};
    border-radius: min(4px, 4px);
    background-color: ${colors.WHITE};
    border-color: ${colors.GREY_200};
    box-shadow: none;
    color:${colors.WHITE};
  }

  span:hover {
    background-color: ${colors.GREY_50};
    border-color: ${colors.GREY_300};
    box-shadow: none;
    color: ${colors.WHITE};
  }

  input:checked:not(:disabled) + span, 
  input.partial-checked:not(:disabled) + span {
    background-color: ${colors.ACCENT_BLUE_500};
    border-color: ${colors.ACCENT_BLUE_500};
    box-shadow: none;
    color: ${colors.WHITE};
  }

  input:checked:not(:disabled) + span:hover,
  input.partial-checked:not(:disabled) + span:hover {
    background-color: ${colors.ACCENT_BLUE_600};
    border-color: ${colors.ACCENT_BLUE_600};
    box-shadow: none;
    color: ${colors.WHITE};
  }

  input:disabled + span {
    background-color: ${colors.GREY_100};
    border-color: ${colors.GREY_100};
    box-shadow: none;
    color: ${colors.GREY_400};
    cursor: not-allowed;
  }

  span:after {
    content: "";
    position: absolute;
  }

  input:checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(colors.WHITE)};
  }

  input.partial-checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(colors.WHITE, true)};
  }

  input:disabled:checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(colors.GREY_400)};
  }

  input:disabled.partial-checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(colors.GREY_400, true)};
  }
`;

const TwoToneDisabled = styleAsClass`
  input:disabled + span {
    background-color: ${colors.ACCENT_BLUE_500_25};
    border-color: ${colors.ACCENT_BLUE_500};
    box-shadow: none;
    color: ${colors.ACCENT_BLUE_500};
    cursor: not-allowed;
  }

  input:disabled:checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(colors.ACCENT_BLUE_500)};
  }

  input:disabled.partial-checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(
      colors.ACCENT_BLUE_500,
      true,
    )};
  }

  input:checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(colors.ACCENT_BLUE_500)};
  }

  input.partial-checked + span:after {
    ${centeredAbsoluteStyle}
    background-image: ${getCheckboxBackgroundImage(
      colors.ACCENT_BLUE_500,
      true,
    )};
  }
`;

export const Checkbox = ({
  checked,
  partialChecked,
  children,
  style,
  className,
  dataTest,
  onClick,
  disabled,
  disableWithTwoTone,
  tooltipTitle,
}: {
  checked: boolean;
  partialChecked?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  dataTest?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  disableWithTwoTone?: boolean;
  tooltipTitle?: string;
}) => {
  return (
    <div>
      <Tooltip title={tooltipTitle}>
        <div
          className={`${CheckboxWrapper} ${className ?? ""}`}
          style={style}
          data-test={dataTest}
          onClick={disabled ? undefined : onClick}
        >
          <div
            className={`${CheckboxCss}${
              disableWithTwoTone ? ` ${TwoToneDisabled}` : ""
            }`}
          >
            {/* the input does not display, we handle checked status by onClick, using readonly to suppress console warnings */}
            <input
              type="checkbox"
              checked={checked}
              readOnly
              disabled={disabled}
              className={partialChecked ? "partial-checked" : undefined}
            />
            <span></span>
          </div>
          {children && <div>{children}</div>}
        </div>
      </Tooltip>
    </div>
  );
};
