export class MouseCoord {
  x: number | null = null;
  y: number | null = null;

  constructor() {
    window.addEventListener("mousemove", this.moveMouse);
  }

  moveMouse = (e: MouseEvent) => {
    this.x = e.clientX;
    this.y = e.clientY;
  };
}

const mouseCoord: MouseCoord = new MouseCoord();

export const getMouseCoord = () => {
  return mouseCoord;
};
