import { Instance } from "@popperjs/core";
import { WidgetTypes } from "@superblocksteam/shared";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLowestAncestorWithScrollContainer } from "legacy/selectors/sagaSelectors";
import { AppState } from "store/types";

export const useIsPopperOverflow = ({
  widgetId,
  popperInstanceRef,
  targetNode,
}: {
  widgetId: string;
  popperInstanceRef: React.MutableRefObject<Instance | null>;
  targetNode: HTMLElement | null;
}) => {
  const [isPopperOverflow, setIsPopperOverflow] = useState(true);
  const lowestAncestorWithScrollContainer = useSelector((state: AppState) =>
    getLowestAncestorWithScrollContainer(state, widgetId),
  );
  useEffect(() => {
    if (!lowestAncestorWithScrollContainer) {
      setIsPopperOverflow(false);
      return;
    }
    const isScrollableColumn =
      lowestAncestorWithScrollContainer.type === WidgetTypes.CANVAS_WIDGET;

    const scrollContainer = document
      .getElementById(
        isScrollableColumn
          ? lowestAncestorWithScrollContainer.widgetId
          : `widget-${lowestAncestorWithScrollContainer.widgetId}`,
      )
      ?.querySelector(".ScrollAreaViewport");

    if (!scrollContainer) {
      setIsPopperOverflow(false);
      return;
    }

    const handleScroll = () => {
      const scrollContainerRect = scrollContainer.getBoundingClientRect();
      const popperRect =
        popperInstanceRef.current?.state.elements.popper.getBoundingClientRect();

      // if we scroll a component with popper namepill and it overflows its parent container, to prevent the namepill showing on top of an adjacent container, we want to hide the namepill
      if (
        scrollContainerRect &&
        popperRect &&
        (popperRect.right < scrollContainerRect.left ||
          popperRect.bottom < scrollContainerRect.top ||
          popperRect.left > scrollContainerRect.right ||
          popperRect.top > scrollContainerRect.bottom)
      ) {
        setIsPopperOverflow(true);
      } else {
        setIsPopperOverflow(false);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 50, {
      leading: true,
    });

    scrollContainer.addEventListener("scroll", debouncedHandleScroll);

    // call it once to initalize isPopperOverflow value on load when no scroll happens
    // add timeout because the popper is not rendered immediately due to child -> parent rendering sequence
    const timeoutId = setTimeout(handleScroll, 200);
    return () => {
      scrollContainer.removeEventListener("scroll", debouncedHandleScroll);
      clearTimeout(timeoutId);
    };
  }, [lowestAncestorWithScrollContainer, popperInstanceRef]);

  return isPopperOverflow;
};
