import React, {
  RefObject,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import DynamicSVG from "components/ui/DynamicSVG";
import { getSingleWidgetProps } from "legacy/selectors/editorSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { generateClassName, getCanvasClassName } from "legacy/utils/generators";
import {
  generateBorderStyleObject,
  generateBorderRadiusStyleObject,
} from "legacy/widgets/base/generateBorderStyle";
import { useAppSelector } from "store/helpers";
import { CanvasWidgetProps } from "../CanvasWidget";
import { ComponentBorder } from "../Shared/ComponentBorder";
import { ScrollContainer } from "../Shared/ScrollContainer";
import { TabsComponentProps } from "./types";
import { computeTabHeaderHeightPx } from "./utils";

const TabsContainerWrapper = styled.div<{
  ref: RefObject<HTMLDivElement>;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ChildrenWrapper = styled.div<{ shouldShowTabs: boolean }>`
  position: relative;

  /* 
    Removes 1px padding from Container elements to support full-width children
    does not remove padding from Canvas elements
    -1 margin means +2 width/height
    
    We then add an extra 2px to height to account for the 1px border border scrolling when padding is zero
    This is admittedly a hack - Mark
  */
  width: calc(100% + 2px);
  margin: -1px;
`;

const TabsContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: hidden;
  padding: 0 14px;

  && {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

type TabProps = {
  selected?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const SelectedTabIndicatorDiv = styled.div`
  height: 2px;
  width: 100%;
  position: absolute;
  pointer-events: none;
  bottom: 1px;
  left: 0;
  background-color: var(--tabs-widget-selected-tab-indicator-bg-color);
`;

const SelectedTabIndicator = (
  <SelectedTabIndicatorDiv className="tabs-widget-selected-tab-indicator" />
);

const TabLabelWrapper = styled.div<TabProps>`
  white-space: nowrap;
  cursor: pointer;
  position: relative;
`;

const TabLabelContent = styled.div<{ fontSize: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: calc(min(${({ fontSize }) => fontSize} / 4, 4px));

  svg {
    width: ${({ fontSize }) => fontSize};
    height: ${({ fontSize }) => fontSize};
  }
`;

type TabLabelProps = {
  widgetId: string;
  label: string;
  tabIconPosition?: "LEFT" | "RIGHT";
  tabIcon?: string;
  tabTextStyle?: React.CSSProperties;
};

const TabLabel = (props: TabLabelProps) => {
  const tabCanvasWidget = useAppSelector((state) =>
    getSingleWidgetProps(state, props.widgetId),
  ) as unknown as CanvasWidgetProps | undefined;

  const fontSize: string = String(props.tabTextStyle?.fontSize || "14px");
  const iconPosition = tabCanvasWidget?.tabIconPosition ?? "LEFT";

  return (
    <TabLabelContent fontSize={fontSize}>
      {iconPosition === "LEFT" && tabCanvasWidget?.tabIcon && (
        <DynamicSVG
          iconName={tabCanvasWidget.tabIcon ?? ""}
          sizeWithUnits={fontSize}
        />
      )}
      {props.label}
      {iconPosition === "RIGHT" && tabCanvasWidget?.tabIcon && (
        <DynamicSVG
          iconName={tabCanvasWidget.tabIcon ?? ""}
          sizeWithUnits={fontSize}
        />
      )}
    </TabLabelContent>
  );
};

const TabsComponent = (props: TabsComponentProps) => {
  const tabs = props.tabs || [];
  const { onTabChange } = props;
  const tabContainerRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!props.shouldScrollContents) {
      tabContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [props.shouldScrollContents]);

  const tabClickHandler = useCallback(
    (widgetId: string) => {
      return (event: React.MouseEvent<HTMLDivElement>) => {
        onTabChange(widgetId);
        event.stopPropagation();
      };
    },
    [onTabChange],
  );

  const generatedTheme = useSelector(selectGeneratedTheme);
  const useBorderOutline =
    props.shouldUseBorderOutline?.(generatedTheme) ?? false;

  const { tabsHeight, childrenWrapperStyle } = useMemo(() => {
    const tabsHeight = computeTabHeaderHeightPx(
      props.headerLineHeightPx,
      props.shouldShowTabs,
    );
    const childrenWrapperStyle = {
      height: `calc(
    100% + 4px -
      ${props.shouldShowTabs ? tabsHeight : 0}px
  )`,
    };
    return {
      tabsHeight,
      childrenWrapperStyle,
    };
  }, [props.headerLineHeightPx, props.shouldShowTabs]);

  const [wrapperClassNames, wrapperBorderStyle, overlayBorderStyle] =
    useMemo(() => {
      const wrapperClassNames =
        useBorderOutline && !props.border
          ? [
              CLASS_NAMES.DEFAULT_CONTAINER,
              CLASS_NAMES.CONTAINER_BORDER_OUTLINE,
            ].join(" ")
          : CLASS_NAMES.DEFAULT_CONTAINER;

      const borderStyle = generateBorderStyleObject({
        border: props.border,
      });
      const borderRadiusStyle = generateBorderRadiusStyleObject({
        borderRadius: props.borderRadius,
      });

      let wrapperBorderStyle: React.CSSProperties = {
        ...borderStyle,
        ...borderRadiusStyle,
      };
      const overlayBorderStyle: React.CSSProperties = {
        ...borderStyle,
        ...borderRadiusStyle,
      };

      // just rely on the ComponentBorder if we should be using outline border
      if (props.border) {
        wrapperBorderStyle = {
          border: "none",
          outline: "none",
          ...borderRadiusStyle,
        };
      } else if (useBorderOutline) {
        wrapperBorderStyle = { border: "none", ...borderRadiusStyle };
      } else if (!useBorderOutline) {
        wrapperBorderStyle = { outline: "none", ...borderRadiusStyle };
      }

      return [wrapperClassNames, wrapperBorderStyle, overlayBorderStyle];
    }, [useBorderOutline, props.border, props.borderRadius]);

  const [activeTabTextStyle, tabTextStyle] = useMemo(() => {
    const activeStyle = {
      ...props.headerProps.style,
      height: `${tabsHeight}px`,
    };

    return [
      activeStyle,
      props.headerProps.style?.color
        ? {
            ...activeStyle,
            color: tinycolor
              .mix(
                props.headerProps.style.color,
                generatedTheme.colors.neutral,
                40,
              )
              .toRgbString(),
          }
        : activeStyle,
    ];
  }, [props.headerProps.style, tabsHeight, generatedTheme]);

  const headerClassName = getTextCssClassFromVariant(
    props.headerProps.textStyleVariant,
  );

  return (
    <TabsContainerWrapper
      ref={tabContainerRef}
      style={wrapperBorderStyle}
      className={wrapperClassNames}
    >
      {props.border && (
        <ComponentBorder
          className={wrapperClassNames}
          borderStyle={overlayBorderStyle}
        />
      )}
      {props.shouldShowTabs ? (
        <TabsContainer
          role="tablist"
          className={CLASS_NAMES.TAB}
          style={{
            height: `${tabsHeight}px`,
            flex: `0 0 ${tabsHeight}px`,
          }}
        >
          {tabs &&
            tabs.map((tab, index) => (
              <TabLabelWrapper
                data-test={`tab-${tab.label}`}
                role="tab"
                onClick={tabClickHandler(tab.widgetId)}
                selected={props.selectedTabWidgetId === tab.widgetId}
                aria-selected={props.selectedTabWidgetId === tab.widgetId}
                key={index}
                id={props.selectedTabWidgetId}
                tabIndex={props.selectedTabWidgetId === tab.widgetId ? 0 : -1}
                style={{
                  ...(props.selectedTabWidgetId === tab.widgetId
                    ? activeTabTextStyle
                    : tabTextStyle),
                  height: `${tabsHeight}px`,
                }}
                className={`tab-item ${headerClassName ?? ""} ${
                  props.selectedTabWidgetId === tab.widgetId
                    ? CLASS_NAMES.ACTIVE_MODIFIER
                    : ""
                }`}
              >
                <TabLabel
                  widgetId={tab.widgetId}
                  label={tab.label}
                  tabTextStyle={tabTextStyle}
                />
                {props.selectedTabWidgetId === tab.widgetId &&
                  SelectedTabIndicator}
              </TabLabelWrapper>
            ))}
        </TabsContainer>
      ) : undefined}
      <ChildrenWrapper
        shouldShowTabs={props.shouldShowTabs}
        style={childrenWrapperStyle}
      >
        <ScrollContainer
          scroll={props.shouldScrollContents ?? false}
          className={`${
            props.shouldScrollContents ? getCanvasClassName() : ""
          } ${generateClassName(props.widgetId)} ${CLASS_NAMES.STYLED_SCROLLBAR}
      `}
          role="tabpanel"
          aria-labelledby={props.selectedTabWidgetId}
          innerStyle={{ height: "calc(100% + 2px)" }}
        >
          {props.children}
        </ScrollContainer>
      </ChildrenWrapper>
    </TabsContainerWrapper>
  );
};

export default TabsComponent;
