import { Instance } from "@popperjs/core";
import { Dimension } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Popper from "components/ui/Popper";
import { useRectChange } from "hooks/ui/useElementRect";
import { Layers } from "legacy/constants/Layers";
import {
  WidgetTypes,
  WidgetType,
  GridDefaults,
} from "legacy/constants/WidgetConstants";
import { useSelectWidget } from "legacy/hooks/dragResizeHooks";
import { useIsPopperOverflow } from "legacy/hooks/useIsPopperOverflow";
import { useMountingNode } from "legacy/hooks/useMountingNode";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { PropertyPaneReduxState } from "legacy/reducers/uiReducers/propertyPaneReducer";
import { getResponsiveCanvasUnscaledWidth } from "legacy/selectors/applicationSelectors";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getFlattenedCanvasWidget } from "legacy/selectors/editorSelectors";
import {
  selectDisplayableParents,
  DisplayableParent,
} from "legacy/selectors/propertyPaneSelectors";
import {
  getFocusedWidget,
  getIsWidgetSelected,
  getLowestAncestorWithScrollContainer,
  getLowestAncestorWithType,
  selectWidgetDisplayName,
} from "legacy/selectors/sagaSelectors";

import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import { childStackItemElementId } from "legacy/widgets/StackLayout/utils";
import { FlattenedWidgetLayoutProps } from "legacy/widgets/shared";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import { styleAsClass } from "styles/styleAsClass";
import { getWidgetDefaultPadding } from "../sizing";
import WidgetName, { Activities, WidgetNamePosition } from "./WidgetName";
import type { WidgetProps } from "legacy/widgets";

const PopperModifiers = [
  {
    name: "flip",
    options: {
      placement: "top",
      fallbackPlacements: ["bottom-end"],
      padding: 5,
    },
  },
  {
    name: "offset",
    options: {
      offset: [0, 0],
    },
  },
];

const toRowPx = (
  d: Dimension<"px" | "fitContent" | "gridUnit" | "fillParent">,
): number => {
  return Dimension.toPx(d, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value;
};

const isSlideoutOrModal = (type: WidgetType) => {
  return (
    [WidgetTypes.SLIDEOUT_WIDGET, WidgetTypes.MODAL_WIDGET] as WidgetType[]
  ).includes(type);
};

const DistTopMod = {
  CENTER: 0.5,
  TOP: 0,
  STRETCH: 0,
  BOTTOM: 1,
  SPACE_BETWEEN: 0,
  SPACE_AROUND: 0,
};

const getNamePosition = (
  props: WidgetNameComponentProps,
  parentWidget: FlattenedWidgetLayoutProps,
  theme: GeneratedTheme,
): WidgetNamePosition => {
  const isModal = props.type === WidgetTypes.MODAL_WIDGET;

  const parentPaddingTop =
    parentWidget.padding?.top ??
    getWidgetDefaultPadding(theme, parentWidget)?.top ??
    Dimension.px(0);
  const parentPaddingBottom =
    parentWidget.padding?.bottom ??
    getWidgetDefaultPadding(theme, parentWidget)?.bottom ??
    Dimension.px(0);

  const isSection = props.type === WidgetTypes.SECTION_WIDGET;
  const inVStack = parentWidget.layout === "VSTACK" || isSection;
  const inHStack = parentWidget.layout === "HSTACK";
  const inGrid =
    parentWidget.layout === "FIXED_GRID" || parentWidget.layout === undefined;

  const paddingTop = toRowPx(parentPaddingTop);
  const paddingBottom = toRowPx(parentPaddingBottom);
  const parentHeight = toRowPx(parentWidget.height);
  const childHeight = toRowPx(props.height);
  const hStackTopSpace =
    (parentHeight + paddingTop - childHeight) *
    DistTopMod[parentWidget.distribution ?? "TOP"];
  const hStackBottomSpace =
    (parentHeight + paddingBottom - childHeight) *
    (1 - DistTopMod[parentWidget.distribution ?? "TOP"]);

  const widgetIsFirstInStack =
    inVStack && parentWidget?.children?.[0] === props.widgetId;
  const moreThanOneChild = (parentWidget?.children?.length ?? 0) > 1;

  const namePillHasTopRoom =
    (inHStack && hStackTopSpace >= NAME_NESTING_GAP_PX) ||
    (inVStack && !widgetIsFirstInStack && moreThanOneChild) ||
    (inGrid &&
      toRowPx(props.top) + toRowPx(parentPaddingTop) >= NAME_NESTING_GAP_PX);

  const namePillHasBottomRoom =
    (inHStack && hStackBottomSpace >= NAME_NESTING_GAP_PX) ||
    (inVStack && widgetIsFirstInStack && moreThanOneChild) ||
    (inGrid &&
      toRowPx(props.top) + toRowPx(props.height) <
        toRowPx(parentWidget.height));

  let position: WidgetNamePosition;
  if (props.widgetNamePosition) {
    position = props.widgetNamePosition;
  } else if (isModal) {
    position = "top";
  } else if (namePillHasTopRoom) {
    position = "top";
  } else if (namePillHasBottomRoom) {
    position = "bottom";
  } else {
    position = "inset";
  }
  return position;
};

const PositionStyle = styleAsClass`
  position: absolute;
  padding-bottom: 6px;
  width: 100%;
  display: flex;
  pointer-events: none;
`;

const PositionStyleForPopper = styleAsClass`
  display: flex;
  pointer-events: none;
`;

// This is: height of widget name pill + gap between widget name pill and widget
// todo: make this something that is shown in one place using selectors such that each
// component doesn't need to be wrapped in it, allowing us to build
// "breadcrumbs" more easily
const NAME_NESTING_GAP_PX = 24;
export const NAME_BELOW_GAP = 4;
// if parent canvas width is smaller than this value, render name pill as popper.
const MIN_PARENT_WIDTH_TO_TRIEGGER_POPPER = 100;
// just a rough estimate of the average character width in px
const AVG_CHAR_WIDTH = 8;

const ControlGroup = styled.div<{
  $showNameOnLeft: boolean;
  $padName?: boolean;
}>`
  position: relative;
  display: flex;
  margin-right: ${(props) => (props.$showNameOnLeft ? "auto" : "0")};
  margin-left: ${(props) => (props.$showNameOnLeft ? "0" : "auto")};
  padding-left: ${(props) => (props.$padName ? "10px" : "0")};
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  & > span {
    height: 100%;
  }
`;

type WidgetNameComponentProps = WidgetProps & {
  showNameOverride?: boolean;
  hideNameOverride?: boolean;
  showNameFocusedOverride?: boolean;
  hasInvalidProps: boolean;
  errorMessage?: string;
  layer?: number;
  widgetNamePosition?: WidgetNamePosition;
  contentStyle?: React.CSSProperties;
  initialShownBreadcrumbs?: number;
  isSectionColumnWidget?: boolean;
  disableHoverInteraction?: boolean;
  parentId?: string;
  widgetType: WidgetType;
};

const WidgetNameComponent = (props: WidgetNameComponentProps) => {
  const widgetWidthPx = Dimension.toPx(
    props.width,
    props.parentColumnSpace || 0,
  ).value;

  // if explicitly set to not show name, return null
  if (props.hideNameOverride) {
    return null;
  }

  const shouldAlwaysShowName = (
    [
      WidgetTypes.SECTION_WIDGET,
      WidgetTypes.SLIDEOUT_WIDGET,
      WidgetTypes.MODAL_WIDGET,
      WidgetTypes.PAGE_WIDGET,
    ] as WidgetType[]
  ).includes(props.type);

  if (
    !props.detachFromLayout ||
    shouldAlwaysShowName ||
    props.isSectionColumnWidget
  ) {
    return (
      <WidgetNameComponentInternal {...props} widgetWidthPx={widgetWidthPx} />
    );
  }
  return null;
};

const PopperNameComponent = ({
  children,
  zIndex,
  type,
  isSectionColumnWidget,
  widgetId,
}: {
  children: JSX.Element;
  zIndex: number;
  type: WidgetType;
  isSectionColumnWidget?: boolean;
  widgetId: string;
}) => {
  const popperInstanceRef = useRef<Instance | null>(null);

  const onCreate = useCallback((instance: Instance) => {
    popperInstanceRef.current = instance;
  }, []);

  const [targetNode, setTargetNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    // Delay setting the targetNode to ensure children have rendered
    const timeoutId = setTimeout(() => {
      const target =
        type !== WidgetTypes.SKELETON_WIDGET
          ? document.getElementById(
              type === WidgetTypes.SECTION_WIDGET || isSectionColumnWidget
                ? widgetId
                : childStackItemElementId(widgetId),
            )
          : null;
      setTargetNode(target);
    }, 100); // Adjust delay as needed to ensure children have rendered
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isSectionColumnWidget, type, widgetId]);

  const width = useAppSelector(getResponsiveCanvasUnscaledWidth);

  useEffect(() => {
    // refresh popper position after resize
    popperInstanceRef.current?.update();
    // TODO: even with a timeout, the popper doesn't always update correctly due to lag in actual canvas resizing
    const timeoutId = setTimeout(() => {
      popperInstanceRef.current?.update();
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [width]);

  // listen for size changes in the parent widget
  // and update the popper position accordingly
  useRectChange(targetNode, () => {
    popperInstanceRef.current?.update();
  });

  const mountingNode = useMountingNode(widgetId);

  const ancestorSection = useAppSelector((state: AppState) =>
    getLowestAncestorWithType(state, widgetId, WidgetTypes.SECTION_WIDGET),
  );

  if (ancestorSection?.position === "STICKY") {
    zIndex += Layers.drawer - Layers.stickySections;
  }

  const isPopperOverflow = useIsPopperOverflow({
    widgetId,
    popperInstanceRef,
    targetNode,
  });

  return (
    <Popper
      zIndex={zIndex}
      onCreate={onCreate}
      isOpen={true}
      targetNode={targetNode ?? undefined}
      placement={"top-end"}
      modifiers={PopperModifiers}
      useDefaultModifiers={false}
      mountingNode={mountingNode ?? document.body}
    >
      {/* offset of popper is 0, use transparent padding for the gap */}
      <div
        style={{
          paddingTop: NAME_BELOW_GAP,
          paddingBottom: NAME_BELOW_GAP,
          background: "transparent",
          visibility: isPopperOverflow ? "hidden" : "visible",
        }}
      >
        {children}
      </div>
    </Popper>
  );
};

const WidgetNameComponentInternal = (
  props: WidgetNameComponentProps & {
    showNameOnLeft?: boolean;
    padName?: boolean;
    widgetWidthPx: number;
  },
) => {
  const selectWidget = useSelectWidget(props);
  const parentWidget = useAppSelector((state: AppState) =>
    getFlattenedCanvasWidget(state, props.parentId || ""),
  );

  const theme = useAppSelector(selectGeneratedTheme);
  const propertyPaneState: PropertyPaneReduxState = useAppSelector(
    (state: AppState) => state.legacy.ui.propertyPane,
  );
  const widgetIsSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, props.widgetId),
  );

  const focusedWidget = useAppSelector(getFocusedWidget);
  const isFocused = focusedWidget?.widgetId === props.widgetId;
  const isResizing = useAppSelector(selectIsResizing);
  const isDragging = useAppSelector(selectIsDragging);
  const widgetName = useAppSelector((state: AppState) =>
    selectWidgetDisplayName(state, props.widgetId, props.widgetName),
  );

  const displayableParents: DisplayableParent[] = useAppSelector(
    (state: AppState) =>
      selectDisplayableParents(state, ItemKinds.WIDGET, props.widgetId),
  );

  const isShownInPropertyPane =
    propertyPaneState.item?.kind === ItemKinds.WIDGET &&
    propertyPaneState.item?.id === props.widgetId;

  const handleWidgetNameSelect = useCallback(
    (e: React.MouseEvent) => {
      if (
        (!propertyPaneState.isVisible && isShownInPropertyPane) ||
        !isShownInPropertyPane
      ) {
        AnalyticsUtil.logEvent("PROPERTY_PANE_OPEN_CLICK", {
          widgetType: props.type,
          widgetId: props.widgetId,
        });
        selectWidget && selectWidget(e);
      } else {
        AnalyticsUtil.logEvent("PROPERTY_PANE_CLOSE_CLICK", {
          widgetType: props.type,
          widgetId: props.widgetId,
        });
      }

      e.preventDefault();
      e.stopPropagation();
    },
    [
      isShownInPropertyPane,
      propertyPaneState.isVisible,
      props.type,
      props.widgetId,
      selectWidget,
    ],
  );

  const isColumn =
    props.type === WidgetTypes.CANVAS_WIDGET &&
    parentWidget?.type === WidgetTypes.SECTION_WIDGET;

  const showWidgetName =
    (props.showNameOverride ||
      props.hasInvalidProps ||
      ((isFocused || widgetIsSelected) && !isDragging && !isResizing)) &&
    // if there is another widget that is focused, do not show current widget name, if current widget is section/column/page
    !(
      !isFocused &&
      focusedWidget &&
      focusedWidget.type !== WidgetTypes.SECTION_WIDGET &&
      (props.type === WidgetTypes.SECTION_WIDGET ||
        props.type === WidgetTypes.PAGE_WIDGET ||
        isColumn)
    );

  let currentActivity = Activities.NONE;
  if (isFocused) currentActivity = Activities.HOVERING;
  if (props.showNameFocusedOverride) currentActivity = Activities.HOVERING;
  if (widgetIsSelected) currentActivity = Activities.SELECTED;
  if (propertyPaneState.isVisible && isShownInPropertyPane)
    currentActivity = Activities.ACTIVE;

  // when working with the page widget, the parent widget is undefined so just use the page widget props
  const position = getNamePosition(props, parentWidget ?? props, theme);

  let topOffset: number | string;
  if (position === "bottom") {
    topOffset = `calc(100% + ${NAME_BELOW_GAP}px)`;
  } else if (position === "top") {
    topOffset = -NAME_NESTING_GAP_PX;
  } else {
    topOffset = 4;
  }

  let zIndex = Layers.widgetName;

  if (
    isFocused ||
    props.type === WidgetTypes.SECTION_WIDGET ||
    props.isSectionColumnWidget
  ) {
    zIndex = zIndex + 1;
  }

  // parent container/form/tabs
  const parentContainerWidget = useSelector((state: AppState) =>
    getLowestAncestorWithScrollContainer(state, props.widgetId),
  );

  const parentContainerWidgetEl = parentContainerWidget
    ? document.getElementById(`widget-${parentContainerWidget.widgetId}`)
    : undefined;

  const parentContainerStyleWidth: number = parseFloat(
    parentContainerWidgetEl?.style?.width ?? "",
  );
  const shouldUsePopper =
    (props.type !== WidgetTypes.SKELETON_WIDGET &&
      props.type !== WidgetTypes.PAGE_WIDGET &&
      props.type !== WidgetTypes.SECTION_WIDGET &&
      props.type !== WidgetTypes.SLIDEOUT_WIDGET &&
      !props.isSectionColumnWidget &&
      position === "inset") ||
    parentContainerStyleWidth <
      Math.max(
        MIN_PARENT_WIDTH_TO_TRIEGGER_POPPER,
        AVG_CHAR_WIDTH * widgetName.length,
      );

  const handleOnMouseDown = useCallback(
    (event: React.MouseEvent) => {
      // pass the event to the draggable element
      const newEvent = new MouseEvent("mousedown", {
        screenX: event.screenX,
        screenY: event.screenY,
        clientX: event.clientX,
        clientY: event.clientY,
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      });
      // this id function return the element with t--draggable-<widget> identifier
      document
        .getElementById(childStackItemElementId(props.widgetId))
        ?.dispatchEvent(newEvent);
    },
    [props.widgetId],
  );

  const nameComponent = useMemo(
    () => (
      <div
        className={`t--widget-name-position ${
          shouldUsePopper ? PositionStyleForPopper : PositionStyle
        }`}
        data-maintain-widget-focus="true"
        style={
          shouldUsePopper
            ? { zIndex }
            : {
                top: topOffset,
                left: position === "inset" ? "auto" : "0",
                right: position === "inset" ? "4px" : "auto",
                width: position === "inset" ? "min-content" : "100%",
                zIndex,
              }
        }
        onMouseDown={shouldUsePopper ? handleOnMouseDown : undefined}
      >
        <Tooltip title={props.errorMessage}>
          <ControlGroup $showNameOnLeft={isSlideoutOrModal(props.type)}>
            <WidgetName
              widgetId={props.widgetId}
              displayableParents={displayableParents}
              widgetWidthPx={props.widgetWidthPx}
              onSelect={handleWidgetNameSelect}
              isSelected={widgetIsSelected}
              activity={currentActivity}
              name={widgetName}
              initialShownBreadcrumbs={props.initialShownBreadcrumbs}
              hasInvalidProps={props.hasInvalidProps}
              errorMessage={props.errorMessage ?? ""}
              widgetIsHidden={!props.isVisible}
              hasPointerEvents={true}
              style={props.contentStyle}
              disableHoverInteraction={props.disableHoverInteraction}
              position={position}
              widgetType={props.type as WidgetTypes}
            />
          </ControlGroup>
        </Tooltip>
      </div>
    ),
    [
      currentActivity,
      displayableParents,
      handleOnMouseDown,
      handleWidgetNameSelect,
      position,
      props.contentStyle,
      props.disableHoverInteraction,
      props.errorMessage,
      props.hasInvalidProps,
      props.initialShownBreadcrumbs,
      props.isVisible,
      props.type,
      props.widgetId,
      props.widgetWidthPx,
      shouldUsePopper,
      topOffset,
      widgetIsSelected,
      widgetName,
      zIndex,
    ],
  );

  return showWidgetName ? (
    shouldUsePopper ? (
      <PopperNameComponent
        zIndex={zIndex}
        type={props.type}
        isSectionColumnWidget={props.isSectionColumnWidget}
        widgetId={props.widgetId}
      >
        {nameComponent}
      </PopperNameComponent>
    ) : (
      nameComponent
    )
  ) : null;
};

export default WidgetNameComponent;
