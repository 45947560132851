import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { AppState } from "store/types";
import { getFlattenedCanvasWidget } from "./editorSelectors";

export const shouldScrollIntoEmptySpace = (
  state: AppState,
  widgetId: string,
) => {
  const widget = getFlattenedCanvasWidget(state, widgetId);
  const parentWidget =
    widget && getFlattenedCanvasWidget(state, widget.parentId || "");
  const parentIsSection = parentWidget?.type === WidgetTypes.SECTION_WIDGET;
  if (!parentIsSection) return false;

  const sectionParent = getFlattenedCanvasWidget(state, parentWidget.parentId);
  if (!sectionParent || !sectionParent.children) return false;

  const sectionChildren = sectionParent.children.filter(
    (id) =>
      state.legacy.entities.canvasWidgets[id]?.type ===
      WidgetTypes.SECTION_WIDGET,
  );

  const isLastSection =
    sectionChildren[sectionChildren.length - 1] === parentWidget.widgetId;

  if (!isLastSection) return false;

  const stack = parentWidget.isStacked ?? false;

  if (!stack) {
    // When the section is not stacked, we should scroll into empty space for all columns
    return true;
  } else {
    // When the section is stacked, we should scroll into empty space only for the last column
    const columns = parentWidget.children || [];
    return columns[columns.length - 1] === widgetId;
  }
};
